/* istanbul ignore file */

import React, { createContext, useState, useContext } from 'react';

import { node, object } from 'prop-types';

import { Toaster as toaster } from '@kandji-inc/bumblebee';

import { authorityService } from '../services/authority';

import { i18n } from 'i18n';
import { useIs } from '.';

const AuthorityContext = createContext({
  authority: '',
  authorityItem: {},
  authorityItemLibraryItemList: [],
  authorityList: [],
  authorityListLibraryItemList: [],
  authorityListLibraryItemMap: {},
  authorityLimit: 0,

  setAuthority: () => {},
  setAuthorityItem: () => {},
  setAuthorityItemLibraryItemList: () => {},
  setAuthorityList: () => {},
  setAuthorityListLibraryItemList: () => {},
  setAuthorityListLibraryItemMap: () => {},
  setAuthorityLimit: () => {},

  clearAuthority: () => {},
  clearAuthorityItem: () => {},
  clearAuthorityItemLibraryItemList: () => {},
  clearAuthorityList: () => {},
  clearAuthorityListLibraryItemList: () => {},
  clearAuthorityListLibraryItemMap: () => {},

  addAuthorityItemBulk: () => {},
  fetchAuthorityItem: () => {},
  fetchAuthorityItemLibraryItemList: () => {},
  fetchAuthorityList: () => {},
  fetchAuthorityListLibraryItemList: () => {},
  fetchAuthorityLimit: () => {},
  updateAuthorityItem: () => {},
  deleteAuthorityItem: () => {},
});

const AuthorityProvider = ({ children, value }) => {
  const { setIsLoading, setIsWorking, setHasChecked } = useIs();

  const [authority, setAuthority] = useState('');
  const [authorityItem, setAuthorityItem] = useState({});
  const [authorityLimit, setAuthorityLimit] = useState(0);
  const [authorityItemLibraryItemList, setAuthorityItemLibraryItemList] =
    useState([]);
  const [authorityList, setAuthorityList] = useState([]);
  const [authorityListLibraryItemList, setAuthorityListLibraryItemList] =
    useState([]);
  const [authorityListLibraryItemMap, setAuthorityListLibraryItemMap] =
    useState({});

  // console.log('Authority', authority); // delete
  // console.log('Authority Item', authorityItem); // delete
  // console.log('Authority Item Library Item List', authorityItemLibraryItemList); // delete
  // console.log('Authority List', authorityList); // delete
  // console.log('Authority List Library Item List', authorityListLibraryItemList); // delete
  // console.log('Authority List Library Item Map', authorityListLibraryItemMap); // delete

  const clearAuthority = () => setAuthority('');
  const clearAuthorityItem = () => setAuthorityItem({});
  const clearAuthorityItemLibraryItemList = () =>
    setAuthorityItemLibraryItemList([]);
  const clearAuthorityList = () => setAuthorityList([]);
  const clearAuthorityListLibraryItemList = () =>
    setAuthorityListLibraryItemList([]);
  const clearAuthorityListLibraryItemMap = () =>
    setAuthorityListLibraryItemMap({});

  const addAuthorityItemBulk = async (form) => {
    try {
      const { add } = form;
      // console.log('Form', form); // delete

      setIsWorking(true);

      const data = await authorityService.bulkCreateAuthorities(add);
      // console.log('Data', data); // delete

      setIsWorking(false);

      toaster('Server(s) added.');
    } catch (error) {
      console.error(error); // delete

      setIsWorking(false);

      toaster(i18n.error.generic());
    }
  };

  const fetchAuthorityItem = async () => {
    try {
      setIsLoading(true);

      const data = await authorityService.getAuthority(authority);

      setAuthorityItem(data);
      setIsLoading(false);
    } catch (error) {
      console.error(error); // delete

      setIsLoading(false);

      toaster(i18n.error.generic());
    }
  };

  const fetchAuthorityItemLibraryItemList = async () => {
    try {
      setIsLoading(true);
      setHasChecked(false);

      const data = await authorityService.listLibraryItems(authority);
      // console.log('Data', data); // delete

      setAuthorityItemLibraryItemList(data);
      setIsLoading(false);
      setHasChecked(true);
    } catch (error) {
      console.error(error); // delete

      setIsLoading(false);
      setHasChecked(false);

      toaster(i18n.error.generic());
    }
  };

  const fetchAuthorityList = async () => {
    try {
      setIsLoading(true);

      const data = await authorityService.listAuthorities();
      // console.log('Data', data); // delete

      setAuthorityList(data);
      setIsLoading(false);
    } catch (error) {
      console.error(error); // delete

      setIsLoading(false);

      toaster(i18n.error.generic());
    }
  };

  const fetchAuthorityLimit = async () => {
    try {
      setIsLoading(true);

      const limit = await authorityService.getAuthorityLimit();

      setAuthorityLimit(limit);
      setIsLoading(false);
    } catch (error) {
      console.error(error);

      setIsLoading(false);

      toaster(i18n.error.generic());
    }
  };

  const fetchAuthorityListLibraryItemList = async () => {
    try {
      setIsLoading(true);
      setHasChecked(false);

      const { list, map } = await authorityService.bulkListLibraryItems();

      setAuthorityListLibraryItemList(list);
      setAuthorityListLibraryItemMap(map);
      setIsLoading(false);
      setHasChecked(true);
    } catch (error) {
      console.error(error); // delete

      setIsLoading(false);
      setHasChecked(false);

      toaster(i18n.error.generic());
    }
  };

  const updateAuthorityItem = async (form) => {
    try {
      const { id, name, connector } = form;

      setIsWorking(true);

      await authorityService.updateAuthority(id, {
        name,
        connector,
      });

      setIsWorking(false);

      toaster('Server updated.');
    } catch (error) {
      console.error(error); // delete

      setIsWorking(false);

      toaster(i18n.error.generic());
    }
  };

  const deleteAuthorityItem = async (id) => {
    try {
      setIsWorking(true);

      const authorityName = authorityItem.name;
      await authorityService.deleteAuthority(id);

      setIsWorking(false);

      toaster(`Server ${authorityName} has been deleted.`);
    } catch (error) {
      console.error(error); // delete

      setIsWorking(false);

      toaster(i18n.error.generic());
    }
  };

  return (
    <AuthorityContext.Provider
      value={{
        authority,
        authorityItem,
        authorityItemLibraryItemList,
        authorityList,
        authorityListLibraryItemList,
        authorityListLibraryItemMap,
        authorityLimit,

        setAuthority,
        setAuthorityItem,
        setAuthorityItemLibraryItemList,
        setAuthorityList,
        setAuthorityListLibraryItemList,
        setAuthorityListLibraryItemMap,
        setAuthorityLimit,

        clearAuthority,
        clearAuthorityItem,
        clearAuthorityItemLibraryItemList,
        clearAuthorityList,
        clearAuthorityListLibraryItemList,
        clearAuthorityListLibraryItemMap,

        addAuthorityItemBulk,
        fetchAuthorityItem,
        fetchAuthorityItemLibraryItemList,
        fetchAuthorityList,
        fetchAuthorityListLibraryItemList,
        fetchAuthorityLimit,
        updateAuthorityItem,
        deleteAuthorityItem,

        ...value,
      }}
    >
      {children}
    </AuthorityContext.Provider>
  );
};

AuthorityProvider.propTypes = {
  children: node.isRequired,
  value: object,
};

AuthorityProvider.defaultProps = {
  value: {},
};

const useAuthority = () => useContext(AuthorityContext);

export default useAuthority;
export { AuthorityContext, AuthorityProvider };
