/* istanbul ignore file */
import { TextInput, Toaster as toaster } from '@kandji-inc/bumblebee';
import {
  Badge,
  Button,
  DropdownMenu,
  Flex,
  Heading,
  Icon,
  Text,
  styled,
} from '@kandji-inc/nectar-ui';
import useAccount, { usePermissions } from 'contexts/account';
import { InterfaceContext } from 'contexts/interface';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { bindActionCreators } from 'redux';
import {
  getBlueprints as ReduxGetBlueprints,
  setBlueprintMessage,
} from 'src/app/_actions/blueprint';
import { UserRoles, blueprintTypes } from 'src/app/common/constants';
import useScrollToTop from 'src/hooks/useScrollToTop';
import Loader from 'src/theme/components/atoms/Loader';

import { blueprintService } from '../../library-items/data-service/blueprint/blueprint-service';
import { paths } from '../common';
import BlueprintListItem from './blueprint-list-item';
import './blueprints-list.css';
import { i18n } from 'i18n';
import { FlowBlueprintListItem } from './flow-blueprint-list-item';
import BlueprintListModals, { modalTypes } from './modals';

const defaultFilter = {
  term: '',
};
const defaultIsModal = {
  type: modalTypes.NONE,
  blueprint: null,
  templates: null,
};

const BlueprintsListSection = (props) => {
  const {
    headerText,
    headerIcon,
    blueprints,
    filter,
    gridStyles,
    renderBlueprintCard,
    noBlueprintsPlaceholder = null,
  } = props;

  const [loadSlice, setLoadSlice] = useState(1);

  const BlueprintInfiniteScroll = styled(InfiniteScroll, {
    display: 'grid',
    gridGap: 'var(--space-5)',
    ...gridStyles,
  });

  return useMemo(() => {
    // If there are no blueprints and a placeholder is not provided, exit
    if (!blueprints?.length && !noBlueprintsPlaceholder) {
      return null;
    }

    const filtered = blueprints.filter(
      ({ name }) =>
        !filter.term || name.toLowerCase().includes(filter.term.toLowerCase()),
    );

    const splitBy = 45;
    const blueprintsGroup = Math.ceil(blueprints.length / splitBy);

    return (
      <Flex flow="column">
        {headerText && (
          <Heading size="2">
            <Flex alignItems="center" gap="sm" pb5>
              <Icon name={headerIcon} />
              {headerText}
            </Flex>
          </Heading>
        )}

        {blueprints.length > 0 ? (
          <BlueprintInfiniteScroll
            pageStart={loadSlice}
            loadMore={(next) => setLoadSlice(next)}
            hasMore={loadSlice < blueprintsGroup}
            loader={<div key={0} />}
          >
            {filtered
              .slice(0, loadSlice * splitBy)
              .map((blueprint) => renderBlueprintCard(blueprint))}
          </BlueprintInfiniteScroll>
        ) : (
          noBlueprintsPlaceholder
        )}
      </Flex>
    );
  }, [blueprints, filter, loadSlice]);
};

BlueprintsListSection.propTypes = {
  headerText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  headerIcon: PropTypes.string,
  blueprints: PropTypes.array,
  filter: PropTypes.object,
  gridStyles: PropTypes.object.isRequired,
  renderBlueprintCard: PropTypes.func.isRequired,
};

BlueprintsListSection.defaultProps = {
  headerText: null,
  headerIcon: null,
  blueprints: [],
  filter: null,
};

const BlueprintsList = (props) => {
  const { blueprints, getBlueprints } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const interfaceContext = useContext(InterfaceContext);
  const [filter, setFilter] = useState({ ...defaultFilter });
  const [isModal, setIsModal] = useState({ ...defaultIsModal });
  const [isLoading, setIsLoading] = useState(true);
  const permissions = usePermissions();
  const { userRole } = useAccount();
  const isUserHelpDesk = userRole === UserRoles.helpdesk;

  useEffect(() => {
    setIsLoading(true);
    getBlueprints()
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, []);

  useScrollToTop({ isLoading: true });

  const formBlueprints = [];
  const flowBlueprints = [];

  // Separate Flow Blueprints from traditional Form Blueprints
  blueprints.forEach((bp) => {
    if (bp.type === 'flow') {
      flowBlueprints.push(bp);
    } else {
      formBlueprints.push(bp);
    }
  });

  const handleOpenFormModal = () =>
    blueprintService.getTemplates().then((r) =>
      setIsModal({
        type: modalTypes.NEW,
        templates: r.data?.results || [],
      }),
    );

  const handleOpenAssignmentMapModal = () =>
    blueprintService.getTemplates().then((r) =>
      setIsModal({
        type: modalTypes.CREATE_MAP,
        templates: r.data?.results || [],
      }),
    );

  const handleAddFlow = () =>
    !isUserHelpDesk && history.push('/blueprints/maps/add');

  const onEdit = (e, blueprint) => {
    e?.stopPropagation();
    if (!isUserHelpDesk) {
      if (blueprint.type === 'flow') {
        setIsModal({ type: modalTypes.EDIT_ASSIGNMENT_MAP, blueprint });
      } else {
        setIsModal({ type: modalTypes.EDIT, blueprint });
      }
    }
  };

  const onDelete = (e, blueprint) => {
    e?.stopPropagation();
    if (blueprint.type !== 'flow' || !isUserHelpDesk) {
      setIsModal({ type: modalTypes.DELETE, blueprint });
    }
  };

  const onDuplicate = (e, blueprint) => {
    e?.stopPropagation();
    if (blueprint.type !== 'flow' || !isUserHelpDesk) {
      setIsModal({ type: modalTypes.DUPLICATE, blueprint });
    }
  };

  const FlowBlueprintList = (
    <BlueprintsListSection
      headerText={
        <Flex gap="sm">
          {i18n.kandji.AssignmentMaps()}
          <Badge color="blue" css={{ alignSelf: 'center' }}>
            <Flex display="column" alignItems="center">
              <Icon name="sparkles" size="sm" />
              <Text
                css={{
                  paddingLeft: '$1',
                  display: 'inline',
                  fontWeight: '$medium',
                  lineHeight: '2',
                }}
              >
                {i18n.t('New!', { _context: 'newly added feature' })}
              </Text>
            </Flex>
          </Badge>
        </Flex>
      }
      headerIcon="sitemap"
      blueprints={flowBlueprints}
      filter={filter}
      gridStyles={{
        gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))',
        gridAutoRows: '125px',
      }}
      renderBlueprintCard={(blueprint) => (
        <FlowBlueprintListItem
          {...blueprint}
          key={blueprint.id}
          onEdit={(e) => onEdit(e, blueprint)}
          onDelete={(e) => onDelete(e, blueprint)}
          onDuplicate={(e) => onDuplicate(e, blueprint)}
        />
      )}
      noBlueprintsPlaceholder={
        <Flex wFull flow="column" alignItems="center" gap="lg">
          <Flex flow="column" gap="sm" alignItems="center">
            <Heading size="2">
              {i18n.t('Introducing a new way to organize your fleet.')}
            </Heading>
            <Text>
              {i18n.t(
                'Assignment Maps allow you unlimited flexibility, inheritance, and grouping.',
              )}
            </Text>
          </Flex>
          <Flex flow="row" gap="sm">
            <Button
              variant="primary"
              icon={{
                name: 'fa-plus-minus-small',
                position: 'left',
              }}
              onClick={handleOpenAssignmentMapModal}
              disabled={isUserHelpDesk}
            >
              {i18n.t('Create new')}
            </Button>
            <a
              href="https://support.kandji.io/support/solutions/articles/72000627625"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Button>{i18n.t('Learn more')}</Button>
            </a>
          </Flex>
        </Flex>
      }
    />
  );

  const FormBlueprintsList = (
    <BlueprintsListSection
      /* Note: when the Flow Blueprint feature is available and
      there is an empty Flow Blueprint state, this header should appear,
      but until then the 'Form Blueprints' should be hidden. */
      headerText={i18n.kandji.ClassicBlueprints()}
      headerIcon="memo-pad"
      blueprints={formBlueprints}
      filter={filter}
      gridStyles={{
        gridTemplateColumns: 'repeat(auto-fill, minmax(256px, 1fr))',
        gridAutoRows: '240px',
      }}
      renderBlueprintCard={(blueprint) => (
        <BlueprintListItem
          key={blueprint.id}
          blueprint={blueprint}
          onDelete={(e) => onDelete(e, blueprint)}
          onDuplicate={(e) => onDuplicate(e, blueprint)}
          onEdit={(e) => onEdit(e, blueprint)}
        />
      )}
      noBlueprintsPlaceholder={<Text>{i18n.t('No Classic Blueprints')}</Text>}
    />
  );

  if (isLoading) {
    return <Loader type={Loader.types.LINE} />;
  }

  return (
    <div className="bl-blueprints">
      <div
        className="bl-blueprints-list__nav b-flex-btw b-mb3"
        style={{
          top: `${interfaceContext.bannerTopOffset}px`,
        }}
      >
        <h1 className="b-h1 b-mr">{i18n.kandji.Blueprints()}</h1>
        <div className="b-flex b-flex-vc">
          <TextInput
            placeholder={i18n.action.Search(i18n.kandji.Blueprints())}
            wrapperClassName="bl-blueprints__search b-mr"
            onChange={(e) => {
              const term = e.target.value;
              setFilter((prev) => ({ ...prev, term }));
            }}
            value={filter.term}
            style={{ padding: '10px', minHeight: '36px' }}
          />
          {permissions.canManageBlueprints && (
            <>
              <DropdownMenu
                options={[
                  {
                    label: i18n.action.New(i18n.kandji.AssignmentMap()),
                    icon: 'sitemap',
                    onClick: handleOpenAssignmentMapModal,
                    id: 'create-new-assignment-map',
                  },
                  {
                    label: i18n.action.New(i18n.kandji.ClassicBlueprint()),
                    icon: 'memo-pad',
                    onClick: handleOpenFormModal,
                  },
                ]}
                withArrow={false}
                contentProps={{ align: 'end' }}
                css={{ zIndex: '10' }}
              >
                <Button
                  className="pendo-new-blueprint"
                  variant="primary"
                  icon={{
                    name: 'circle-plus',
                    position: 'left',
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  {i18n.action.New(i18n.kandji.Blueprint())}
                  <Flex ml1>
                    <Icon name="angle-down" size="sm" />
                  </Flex>
                </Button>
              </DropdownMenu>
            </>
          )}
        </div>
      </div>

      <Flex flow="column" css={{ gap: 'var(--space-7)' }}>
        {FlowBlueprintList}
        {FormBlueprintsList}
      </Flex>

      <BlueprintListModals
        type={isModal.type}
        blueprint={isModal.blueprint}
        templates={isModal.templates}
        onClose={() => {
          setIsModal({ ...defaultIsModal });
        }}
        onCreate={({ name = '', description, icon, color }, source) =>
          blueprintService
            .create({ name: name.trim(), description, icon, color, source })
            .then(async (r) => {
              toaster(i18n.t('Successfully created Blueprint.'));
              if (r?.data?.response_messages) {
                dispatch(
                  setBlueprintMessage(r?.data?.id, r.data.response_messages),
                );
              }
              await getBlueprints();
              if (r?.data?.id) {
                history.push(paths.library(r.data.id));
              }
            })
            .catch((errorResponse) => {
              if (
                errorResponse?.response?.data &&
                Array.isArray(errorResponse.response.data)
              ) {
                const errorMessages = errorResponse.response.data.join(', ');
                toaster(errorMessages);
              } else {
                toaster(i18n.t('Failed to create Blueprint.'));
              }

              return Promise.reject();
            })
        }
        onEdit={(name, description, icon, color) =>
          blueprintService
            .patch(isModal?.blueprint?.id, {
              name: name.trim(),
              description,
              icon,
              color,
            })
            .then(async () => {
              await getBlueprints();
              toaster(i18n.t('Successfully updated Blueprint.'));
            })
            .catch((errorResponse) => {
              if (errorResponse?.response?.data) {
                const errorMessages = Object.keys(errorResponse.response.data)
                  .map((key) => errorResponse.response.data[key].join(', '))
                  .join(', ');
                toaster(errorMessages);
              } else {
                toaster(i18n.t('Failed to update Blueprint.'));
              }
              return Promise.reject(errorResponse);
            })
        }
        onDelete={() =>
          blueprintService
            .delete(isModal.blueprint.id)
            .then(() => toaster(i18n.t('Successfully deleted Blueprint.')))
            .then(getBlueprints)
            .catch((e) => {
              toaster(i18n.t('Failed to delete Blueprint.'));
              return Promise.reject(e);
            })
        }
        onDuplicate={({ name, description, icon, color }) =>
          blueprintService
            .create({
              name: name.trim(),
              description,
              icon,
              color,
              source: {
                id: isModal?.blueprint?.id,
                type: 'blueprint',
              },
            })
            .then(async () => {
              await getBlueprints();
              toaster(i18n.t('Successfully duplicated Blueprint.'));
            })
            .catch((errorResponse) => {
              if (
                errorResponse?.response?.data &&
                Array.isArray(errorResponse.response.data)
              ) {
                const errorMessages = errorResponse.response.data.join(', ');
                toaster(errorMessages);
              } else {
                toaster(i18n.t('Failed to duplicate Blueprint.'));
              }

              return Promise.reject(errorResponse);
            })
        }
        isFlowBlueprint={isModal.blueprint?.type === blueprintTypes.flow}
      />
    </div>
  );
};

BlueprintsList.propTypes = {
  blueprints: PropTypes.array, // from Redux connect
  getBlueprints: PropTypes.func, // from Redux connect
};

BlueprintsList.defaultProps = {
  blueprints: [],
  getBlueprints: () => {},
};

const mapStateToProps = (state) => ({
  blueprints: state.data.blueprints,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getBlueprints: ReduxGetBlueprints }, dispatch);

// @todo Remove the redux connection here once we migrate all the Library Items to Bumblebee (Epic LIT-954)
export default connect(mapStateToProps, mapDispatchToProps)(BlueprintsList);
export { BlueprintsList };
