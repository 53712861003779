import {
  Button,
  sidePanel as SidePanel,
  Toaster as toaster,
} from '@kandji-inc/bumblebee';
import { Flex, TextField, styled } from '@kandji-inc/nectar-ui';
import type { ChangeEvent } from 'react';
import React, { useEffect, useState } from 'react';

import { i18n } from 'i18n';
import SelectDropdown from 'src/features/integrations/components/select-dropdown';
import useRefreshChannels from '../../generic/communication-integrations/new-integration/hooks/use-refresh-channels';
import useListIntegrations from '../../hooks/use-list-integrations';
import useListNotifications from '../../hooks/use-list-notifications';
import PanelSectionTitle from '../../ms-teams/ms-teams-details/ms-teams-panel/components/panel-section-title';
import {
  PanelButtonContainer,
  PanelSection,
  PanelTitle,
} from '../../ms-teams/ms-teams-details/ms-teams-panel/styled-components';
import slackSmallIcon from '../assets/small-icon.svg';
import useCreateNotification from '../hooks/use-create-notification';
import useGetChannels from '../hooks/use-get-channels';
import useUpdateNotification from '../hooks/use-update-notification';
import EventTriggers from './event-triggers';
import SendTestNotification from './send-test-notification';
import type { Channel, ChannelsList, Data } from './types';
import {
  convertActionsToArrayForApi,
  transformActionsFromApi,
  transformChannelsForApiCall,
  transformChannelsFromApi,
  transformChannelsToOptions,
} from './utils/transformation-helpers';

type Props = {
  isOpen: boolean;
  data: Data;
  handleToggleSidePanel: () => void;
  handleDeleteNotification: ({
    id,
    name,
  }: {
    id?: number;
    name?: string;
  }) => void;
};

type FormValues = {
  channels: Channel[];
  name: string;
  actions: Record<string, boolean>;
};

const StyledSidePanel = styled(SidePanel, { zIndex: 2000 });

const PanelForm = styled('form', {
  overflowY: 'scroll',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  '& h4': {
    fontSize: '16px',
  },
});

const PanelSectionContainer = styled('div', {
  padding: '$6',
  display: 'grid',
  gap: '48px',
});

const INITIAL_STATE: FormValues = {
  channels: [],
  name: '',
  actions: {},
};
const SlackSidePanel = (props: Props) => {
  const { isOpen, data, handleToggleSidePanel, handleDeleteNotification } =
    props;
  const [slackChannels, setSlackChannels] = useState<ChannelsList>();
  const [isSaving, setIsSaving] = useState(false);
  const [formValues, setFormValues] = useState<FormValues>(INITIAL_STATE);

  const { mutateAsync: updateNotificationMutation } = useUpdateNotification();
  const { mutateAsync: createNotificationMutation } = useCreateNotification();

  const { mutate } = useRefreshChannels();

  const { data: channelData, isLoading: isLoadingChannels } = useGetChannels(
    data?.integrationId,
  );

  const { refetch: refetchIntegrations, data: integrationData } =
    useListIntegrations({
      integrationType: 'slack',
    });
  const { refetch: refetchNotifications } = useListNotifications({
    integrationType: 'slack',
  });

  const fetchData = async () => {
    refetchIntegrations();
    refetchNotifications();
  };

  const channels = channelData?.data?.channels;

  const isEditing = !!data?.existingNotificationData;

  const clearFormValues = () => {
    setFormValues({
      actions: {},
      name: '',
      channels: [],
    });
  };

  const handleCloseSidePanel = () => {
    clearFormValues();
    handleToggleSidePanel();
  };

  const handleOnChange = (value: unknown, name: string) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const handleToastMessage = (isEditingSlack: boolean) => {
    const text = `${formValues.name} has been ${
      isEditingSlack ? 'updated' : 'added'
    }.`;

    toaster(text);
  };

  const handleSubmit = async (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsSaving(true);

    const notificationData = {
      integration_id: data.integrationId,
      name: formValues.name,
      channels: transformChannelsForApiCall(formValues.channels),
      actions: convertActionsToArrayForApi(formValues.actions),
    };

    try {
      if (isEditing) {
        const slackWorkspaceId = data?.existingNotificationData?.id;
        if (slackWorkspaceId) {
          await updateNotificationMutation({
            slackWorkspaceId,
            notificationData,
          });
        }
      } else {
        await createNotificationMutation({ notificationData });
      }

      handleToastMessage(isEditing);
      await fetchData();
      handleCloseSidePanel();
    } catch (e) {
      console.log(e);
      toaster(i18n.error.generic());
    } finally {
      setIsSaving(false);
    }
  };

  const hasEventTriggerSelected = Object.values(formValues.actions).some(
    Boolean,
  );

  const isSubmitDisabled =
    !formValues.channels.length || !formValues.name || !hasEventTriggerSelected;

  useEffect(() => {
    if (data?.existingNotificationData && isOpen) {
      const {
        actions: existingActions,
        channels: existingChannels,
        name: existingName,
      } = data.existingNotificationData;

      setFormValues({
        actions: transformActionsFromApi(existingActions),
        channels: transformChannelsFromApi(existingChannels),
        name: existingName,
      });
    }
  }, [data?.existingNotificationData, isOpen]);

  useEffect(() => {
    if (channels) {
      setSlackChannels(transformChannelsToOptions(channels));
    }
  }, [channels]);

  useEffect(() => {
    if (!isOpen) {
      clearFormValues();
    }
  }, [isOpen]);

  useEffect(() => {
    if (integrationData) {
      const integrationIds: number[] = integrationData?.data?.results?.map(
        (integration: any) => integration.id,
      );
      integrationIds.forEach((integrationId) => {
        mutate(integrationId);
      });
    }
  }, [integrationData, mutate]);

  return (
    <StyledSidePanel isVisible={isOpen}>
      <PanelForm onSubmit={handleSubmit}>
        <PanelTitle>
          <h2 className="b-h2">
            {isEditing ? 'Edit' : 'Add'} event notification
          </h2>
          <img src={slackSmallIcon} alt="slack-small-icon" />
        </PanelTitle>

        <PanelSectionContainer>
          <PanelSection>
            <PanelSectionTitle title="General" />

            <div>
              <Flex gap="sm" flow="column">
                <label htmlFor="slack-channel-select" className="b-txt-light">
                  Select a channel or multiple channels
                </label>

                <SelectDropdown
                  name="channels"
                  inputId="slack-channel-select"
                  placeholder="Search or select from list"
                  noOptionsMessage="No results found"
                  options={slackChannels || []}
                  isLoading={isLoadingChannels}
                  isMulti
                  hideSelectedOptions={false}
                  closeMenuOnSelect={false}
                  onChange={(e: Channel[]) => handleOnChange(e, 'channels')}
                  value={formValues.channels}
                />
              </Flex>

              <Flex gap="sm" flow="column">
                <label htmlFor="name" className="b-txt-light">
                  Event notification name
                </label>

                <TextField
                  id="name"
                  name="name"
                  onChange={(e) => handleOnChange(e.target.value, 'name')}
                  value={formValues.name}
                  placeholder="Enter a name"
                />
              </Flex>
            </div>
          </PanelSection>
          <PanelSection>
            <PanelSectionTitle
              title="Event triggers"
              description="Specify which events should trigger a notification."
            />

            <EventTriggers
              value={formValues.actions}
              onChange={handleOnChange}
              isOpen={isOpen}
            />
          </PanelSection>

          <PanelSection>
            <PanelSectionTitle
              title="Test notification"
              description="Send a test notification before adding this event notification. We will provide a sample message to the channels specified above."
            />

            <SendTestNotification
              isOpen={isOpen}
              channels={formValues.channels}
              integrationId={data.integrationId}
            />
          </PanelSection>
        </PanelSectionContainer>
        <PanelButtonContainer>
          {isEditing && (
            <Button
              kind="link"
              theme="error"
              onClick={() =>
                handleDeleteNotification({
                  id: data.existingNotificationData?.id,
                  name: data.existingNotificationData?.name,
                })
              }
            >
              Delete
            </Button>
          )}
          <div>
            <Button onClick={handleCloseSidePanel} kind="outline">
              Cancel
            </Button>
            <Button
              type="submit"
              icon={isSaving ? 'arrow-rotate-right' : undefined}
              isProgress={isSaving}
              disabled={isSubmitDisabled}
            >
              Save
            </Button>
          </div>
        </PanelButtonContainer>
      </PanelForm>
    </StyledSidePanel>
  );
};

export default SlackSidePanel;
