import { Toaster as toaster } from '@kandji-inc/bumblebee';
import {
  Box,
  Button,
  Dialog,
  Flex,
  Icon,
  Text,
  TextField,
} from '@kandji-inc/nectar-ui';
import React, { useState } from 'react';

import { i18n } from 'i18n';
import { setAutoAdminPassword } from 'src/app/components/computer/api';

const ComputerSetAutoAdminPassword = (props) => {
  const { info, onHide } = props;
  const { computerId } = info;

  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [verifyPassword, setVerifyPassword] = useState();

  const handleOnPasswordChange = (e) => setPassword(e.target.value);
  const toggleShowPassword = () => setShowPassword((prev) => !prev);
  const handleOnVerifyPasswordChange = (e) => setVerifyPassword(e.target.value);

  const onSetPassword = () => {
    setIsLoading(true);
    setAutoAdminPassword(computerId, password)
      .then(() => {
        onHide();
        setIsLoading(false);
        toaster(i18n.t('Command initiated'));
      })
      .catch(() => {
        onHide();
        setIsLoading(false);
        toaster(i18n.error.generic());
      });
  };

  const content = (
    <Flex flow="column" gap="lg">
      <Text>
        Update the password for the Auto Admin account created during Automated
        Device Enrollment.
      </Text>
      <Flex flow="row" gap="sm" alignItems="end">
        <TextField
          // aria-label is needed to retrieve this textbox in tests
          aria-label="Password"
          label="Password"
          style={{ width: 'var(--sizes-15)' }}
          type={showPassword ? 'text' : 'password'}
          onChange={handleOnPasswordChange}
        />
        <Box pb2>
          <Icon
            name={showPassword ? 'eye' : 'eye-slash'}
            color="var(--colors-neutral50)"
            onClick={toggleShowPassword}
          />
        </Box>
      </Flex>
      <TextField
        // aria-label is needed to retrieve this textbox in tests
        aria-label="Verify Password"
        label="Verify Password"
        style={{ width: 'var(--sizes-15)' }}
        type="password"
        onChange={handleOnVerifyPasswordChange}
      />
    </Flex>
  );

  const footer = (
    <Flex gap="md" justifyContent="end" wrap="wrap">
      <Button variant="subtle" onClick={onHide}>
        Cancel
      </Button>
      <Button
        variant="primary"
        loading={isLoading}
        disabled={!password || password !== verifyPassword}
        onClick={onSetPassword}
      >
        Set Auto Admin Password
      </Button>
    </Flex>
  );

  return (
    <Dialog
      isOpen
      closeOnEscape
      closeOnOutsideClick
      onOpenChange={onHide}
      title="Set Auto Admin Password"
      content={content}
      footer={footer}
      css={{ zIndex: 2000 }}
    />
  );
};

export default ComputerSetAutoAdminPassword;
