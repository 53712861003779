import { Button } from '@kandji-inc/bumblebee';
import { fetchCompanyState } from 'app/_actions/account';
import { updateCompanyReq } from 'app/_actions/company';
import { setModal, setSnackbar } from 'app/_actions/ui';
import { colors } from 'app/common/constants';
import { i18n } from 'i18n';
import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import history from '../../../router/history';
import { moment } from '../../common/helpers';
import { InfoBanner, WarningBanner } from '../../good/banners';
import TokenExpirationIndicator from '../../interface/indicators/TokenExpirationIndicator';
import { CardHeaderTitle } from '../../library/common/LibrarySetting.styles';
import Block from '../elements/Block';
import Description from '../elements/Description';
import IntegrationField from '../elements/IntegrationField';
import Title from '../elements/Title';
import { reclaimAppsAndBooks } from './api';

const WhiteCard = styled('div')`
  background: white;
  border-radius: 4px;
  position: ${(props) => (props.sticky ? 'sticky' : 'relative')};
  bottom: ${(props) => (props.sticky ? '0' : 'after')};
  z-index: ${(props) => (props.sticky ? 2 : 'auto')};
  padding: 15px 30px ${(props) => (props.button ? '60px' : '26px')};
  margin-bottom: 20px;
`;

const Indicator = styled.div`
  margin-left: 20px;
  padding: 6px 15px;
  height: 24px;

  background: rgba(82, 197, 72, 0.2);
  border-radius: 3px;

  font-family: var(--font-family-primary);
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;

  color: #37ac2c;
`;

const Danger = styled(Indicator)`
  background: rgba(238, 52, 52, 0.2);
  color: #ee3434;
`;

const LoginWrapper = styled(Block.GreyBlock)`
  margin-top: 25px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  white-space: nowrap;
  padding: 31px 30px;
  > * {
    margin-right: 20px;
  }
`;

const CertInfoBlock = styled('div')`
  display: grid;
  grid-template-columns: max-content auto;
  grid-gap: 20px;
  grid-template-areas: 'title value';
  margin-top: 15px;
`;

const CertInfoTitleWrapper = styled('div')`
  grid-area: title;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const CertInfoTitle = styled('span')`
  font-family: var(--font-family-primary);
  font-size: 14px;
  line-height: 25px;

  color: #1a1d25;
`;

const CertInfoValueWrapper = styled(CertInfoTitleWrapper)`
  grid-area: value;
`;

const CertInfoValue = styled(CertInfoTitle)`
  font-weight: 500;
`;

const Buttons = styled('div')`
  position: absolute;
  display: flex;
  flex-direction: row;
  right: 30px;
`;

const VPPIntegrationDataCard = ({
  hasControl,
  hostname,
  locationName,
  token_expiration,
}) => (
  <WhiteCard>
    <CertInfoBlock>
      <CertInfoTitleWrapper>
        <CertInfoTitle>
          {hasControl ? 'Location Token Name:' : 'Current Manager:'}
        </CertInfoTitle>
        <CertInfoTitle>Token Expiration Date:</CertInfoTitle>
      </CertInfoTitleWrapper>
      <CertInfoValueWrapper>
        <CertInfoValue>{hasControl ? locationName : hostname}</CertInfoValue>
        <CertInfoValue>
          {`${moment(token_expiration).format('LL')} - ${moment(
            token_expiration,
          ).fromNow()}`}
        </CertInfoValue>
      </CertInfoValueWrapper>
    </CertInfoBlock>
    {!hasControl && (
      <WarningBanner
        text={
          hostname
            ? `Kandji no longer manages your Apps and Books. They are currently being managed by ${hostname}.`
            : 'Kandji no longer manages your Apps and Books.'
        }
        margin="15px 0 0"
      />
    )}
  </WhiteCard>
);

const VPPIntegrationOptionsCard = () => {
  const [editMode, setEditMode] = useState(false);
  const companyId = useSelector((state) => state.account.company.id);
  const dispatch = useDispatch();
  const isVppAutoUpdateAppsFromState = useSelector(
    (state) => state.account.company.is_vpp_auto_update_apps,
  );
  const [isVppAutoUpdateApps, setIsVppAutoUpdateApps] = useState(
    isVppAutoUpdateAppsFromState,
  );
  return (
    <WhiteCard button={editMode}>
      <CardHeaderTitle>
        Options
        {!editMode && (
          <Button
            icon="pencil"
            color={colors.white}
            onClick={() => setEditMode(true)}
            size="small"
            kind="link"
            theme="dark"
          >
            Edit
          </Button>
        )}
      </CardHeaderTitle>
      <IntegrationField
        type="checkbox"
        value={isVppAutoUpdateApps}
        disabled={!editMode}
        label="Automatically update apps"
        description={`Kandji will automatically update managed App Store apps when a new version is detected. You may
                      choose to exclude individual apps by navigating to an app in the Library and enabling the option 
                      titled "Don't automatically update this app".`}
        onChange={() => setIsVppAutoUpdateApps(!isVppAutoUpdateApps)}
      />
      {editMode && (
        <Buttons>
          <>
            <Button
              onClick={() => {
                setEditMode(false);
                setIsVppAutoUpdateApps(isVppAutoUpdateAppsFromState);
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={isVppAutoUpdateAppsFromState === isVppAutoUpdateApps}
              style={{ marginLeft: '10px' }}
              onClick={() => {
                dispatch(
                  updateCompanyReq(companyId, {
                    is_vpp_auto_update_apps: isVppAutoUpdateApps,
                  }),
                );
                setEditMode(false);
              }}
            >
              Save
            </Button>
          </>
        </Buttons>
      )}
    </WhiteCard>
  );
};

const VPPIntegration = (props) => {
  const { hasMDM, vppInfo } = props;
  const dispatch = useDispatch();
  return (
    <LoginWrapper>
      <div className="d-flex flex-column w-100">
        <div className="d-flex align-items-center" style={{ marginBottom: 10 }}>
          <Title.SubTitle>Apps and Books</Title.SubTitle>
          {vppInfo.is_configured &&
            (vppInfo.has_control ? (
              <TokenExpirationIndicator daysLeft={vppInfo.days_left} />
            ) : (
              <Danger>warning</Danger>
            ))}
          <Button
            className="pendo-configure-apps-books"
            style={{ marginLeft: 'auto' }}
            kind={vppInfo.is_configured ? 'outline' : 'filled'}
            theme="dark"
            disabled={!hasMDM}
            onClick={() => history.push('/my-company/apps-and-books-token')}
          >
            {vppInfo.is_configured ? 'Renew Token' : 'Configure'}
          </Button>
          {vppInfo.is_configured && !vppInfo.has_control && (
            <Button
              style={{ marginLeft: '15px' }}
              theme="dark"
              kind="outline"
              onClick={() =>
                reclaimAppsAndBooks()
                  .then(() => fetchCompanyState())
                  .catch(() => dispatch(setSnackbar(i18n.error.generic())))
              }
            >
              Reclaim
            </Button>
          )}
        </div>
        <Description.SubDescription
          style={{ whiteSpace: 'normal', marginBottom: 20 }}
        >
          Apps and Books (formerly VPP) allows you to distribute App Store apps,
          books, and custom apps to your organization’s devices.
        </Description.SubDescription>
        {!hasMDM && <InfoBanner text="APNs is required for Apps and Books" />}
        {vppInfo.is_configured && (
          <>
            <VPPIntegrationDataCard
              hasControl={vppInfo.has_control}
              hostname={vppInfo.current_manager}
              locationName={vppInfo.location_name || vppInfo.current_manager}
              token_expiration={vppInfo.token_expiration}
            />
            <VPPIntegrationOptionsCard />
            {vppInfo.is_configured && (
              <Button
                style={{ marginLeft: 'auto' }}
                kind="link"
                theme="dark"
                onClick={() => dispatch(setModal('DISCONNECT_APPS_AND_BOOKS'))}
              >
                Disconnect Apps and Books
              </Button>
            )}
          </>
        )}
      </div>
    </LoginWrapper>
  );
};

const mapStateToProps = (state) => ({
  hasMDM: state.account.company.is_mdm,
  vppInfo: state.account.company.vpp_info,
});

export default connect(mapStateToProps)(VPPIntegration);
