import { Button } from '@kandji-inc/bumblebee';
import { colors } from 'app/common/constants';
import {
  bundleValidator,
  devIDValidator,
  fullPathValidator,
} from 'app/common/validators';
import classNames from 'classnames';
import { i18n } from 'i18n';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Row } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import styled from 'styled-components';
import { createAppBlacklistRules as callCreateAppBlacklistRules } from '../../../_actions/computer';
import { setSnackbar as callSetSnackbar } from '../../../_actions/ui';
import AwesomeSelect from '../AwesomeSelect';
import { H2 } from '../Typography';
import ToggleButton from '../buttons/ToggleButton';
import PrettyInput from '../inputs/PrettyInput';
import { StyledModalWrapper } from './StyledModalWrapper';
import {
  ButtonsWrapper,
  CancelButtonWrapper,
  CloseModalIcon,
} from './elements';

const BodyStyled = styled.section`
  min-height: 500px;
  width: 600px;
  padding: 30px 53px 30px 51px;
`;

const ModalSubtitle = styled.div`
  display: flex;
  flex-flow: wrap row;
  font-weight: 500;
  margin: 20px 0 32px 0;
`;

const ModalSubtitleText = styled.section`
  margin-right: 10px;
`;

const FieldsWrapper = styled.section`
  display: flex;
  flex: 1;
  margin-left: 15px;
`;

const PrettyInputWrapper = styled.section`
  display: flex;
  flex: 1 1 55%;
  flex-direction: column;
`;

const renderField = ({ input, label, meta: { touched, error }, disabled }) => (
  <PrettyInputWrapper>
    <PrettyInput {...input} label={label} disabled={disabled} />
    {touched && error && <span className="params-error-message">{error}</span>}
  </PrettyInputWrapper>
);

const renderSwitch = ({ input, disabled }) => (
  <ToggleButton
    onToggle={() => input.onChange(!input.value)}
    checked={input.value}
    disabled={disabled}
  />
);

const WrapperSelect = styled.div`
  display: flex;
  flex-grow: 2;
  flex-shrink: 2;
  flex-basis: 30%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-width: 120px;
  max-width: 450px;
  margin: 0 0 25px 15px;
  border: 1px solid ${colors['grey-250']};
  padding: 10px 15px;
  border-radius: 5px;
  background-color: ${colors['grey-80']};
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SelectLabel = styled.label`
  margin-bottom: 0;
  color: ${colors['grey-400']};
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.2em;
`;

export const renderSelect = ({
  input,
  label,
  meta: { touched, error },
  options,
  disabled,
}) => (
  <WrapperSelect className={classNames({ 'params-error': touched && error })}>
    {label && <SelectLabel>{label}</SelectLabel>}
    <AwesomeSelect
      options={options}
      value={input.value}
      onChange={(event) => input.onChange(event.value)}
      disabled={disabled}
      filterSelected
      hasLabel={!!label}
      variant="parameter"
      label={label}
    />
    {touched && error && <span className="params-error-message">{error}</span>}
  </WrapperSelect>
);

const typeOptions = [
  {
    value: 'exact',
    label: 'Exact',
  },
  {
    value: 'contains',
    label: 'Contains',
  },
  {
    value: 'regex',
    label: 'Regex',
  },
];

let AppBlacklist = (props) => {
  const {
    onHide,
    computer,
    blueprints,
    createAppBlacklistRules,
    handleSubmit,
    setSnackbar,
    submitting,
    invalid,
    formValues,
  } = props;
  const [isLoading, setLoading] = useState(false);
  const [blueprint, setBlueprint] = useState(computer.blueprint_id);

  const formatFormData = (form) => {
    const data = {};
    if (
      !isEmpty(get(form, 'block_process_name.value')) &&
      get(form, 'block_process_name.is_enabled')
    ) {
      data.by_process = [
        {
          process_name: form.block_process_name.value,
          type: form.block_process_name.type,
        },
      ];
    }
    if (
      !isEmpty(get(form, 'block_application_path.value')) &&
      get(form, 'block_application_path.is_enabled')
    ) {
      data.by_path = [
        {
          full_path_to_app: form.block_application_path.value,
          type: form.block_application_path.type,
        },
      ];
    }
    if (
      !isEmpty(get(form, 'block_bundle_id.value')) &&
      get(form, 'block_bundle_id.is_enabled')
    ) {
      data.by_bundle_id = [
        {
          bundle_identifier: form.block_bundle_id.value,
          type: form.block_bundle_id.type,
        },
      ];
    }
    if (
      !isEmpty(get(form, 'block_using_developer_id.value')) &&
      get(form, 'block_using_developer_id.is_enabled')
    ) {
      data.by_developer_id = [
        {
          developer_id: form.block_using_developer_id.value,
        },
      ];
    }
    return data;
  };

  const submit = (form) => {
    setLoading(true);
    createAppBlacklistRules(blueprint, formatFormData(form))
      .then(() => onHide())
      .catch((err) =>
        setSnackbar(
          err.response
            ? JSON.stringify(err.response.data)
            : i18n.error.generic(),
        ),
      )
      .finally(() => setLoading(false));
  };

  return (
    <StyledModalWrapper {...props}>
      <CloseModalIcon className="far fa-times" onClick={onHide} />
      <BodyStyled>
        <H2>New App Restriction Rule</H2>
        <ModalSubtitle>
          <ModalSubtitleText>
            Create app restriction rule for{' '}
          </ModalSubtitleText>
          <AwesomeSelect
            options={blueprints.map((bp) => ({ value: bp.id, label: bp.name }))}
            underline
            lightBody
            value={blueprint}
            onChange={(v) => setBlueprint(v.value)}
          />
        </ModalSubtitle>
        <form onSubmit={handleSubmit(submit)}>
          <Row noGutters>
            <Field
              name="block_process_name.is_enabled"
              type="button"
              component={renderSwitch}
            />
            <FieldsWrapper>
              <Field
                name="block_process_name.value"
                type="text"
                label={
                  <span>
                    Block <b>Process Name:</b>
                  </span>
                }
                disabled={!formValues.block_process_name.is_enabled}
                component={renderField}
              />
              <Field
                name="block_process_name.type"
                type="select"
                label="Match type"
                disabled={!formValues.block_process_name.is_enabled}
                component={renderSelect}
                options={typeOptions}
              />
            </FieldsWrapper>
          </Row>
          <Row noGutters>
            <Field
              name="block_application_path.is_enabled"
              type="button"
              component={renderSwitch}
            />
            <FieldsWrapper>
              <Field
                name="block_application_path.value"
                type="text"
                label={
                  <span>
                    Block <b>Application Path:</b>
                  </span>
                }
                disabled={!formValues.block_application_path.is_enabled}
                component={renderField}
              />
              <Field
                name="block_application_path.type"
                type="select"
                label="Match type"
                disabled={!formValues.block_application_path.is_enabled}
                component={renderSelect}
                options={typeOptions}
              />
            </FieldsWrapper>
          </Row>
          <Row noGutters>
            <Field
              name="block_bundle_id.is_enabled"
              type="button"
              component={renderSwitch}
            />
            <FieldsWrapper>
              <Field
                name="block_bundle_id.value"
                type="text"
                label={
                  <span>
                    Block <b>Bundle ID:</b>
                  </span>
                }
                disabled={!formValues.block_bundle_id.is_enabled}
                component={renderField}
              />
              <Field
                name="block_bundle_id.type"
                type="select"
                label="Match type"
                disabled={!formValues.block_bundle_id.is_enabled}
                component={renderSelect}
                options={typeOptions}
              />
            </FieldsWrapper>
          </Row>
          <Row noGutters>
            <Field
              name="block_using_developer_id.is_enabled"
              type="button"
              component={renderSwitch}
            />
            <FieldsWrapper>
              <Field
                name="block_using_developer_id.value"
                type="text"
                label={
                  <span>
                    Block all applications using
                    <br />
                    <b>Developer ID:</b>
                  </span>
                }
                disabled={!formValues.block_using_developer_id.is_enabled}
                component={renderField}
              />
            </FieldsWrapper>
          </Row>
          <ButtonsWrapper>
            <CancelButtonWrapper>
              <Button type="button" onClick={onHide} kind="outline">
                Cancel
              </Button>
            </CancelButtonWrapper>
            <Button
              type="submit"
              disabled={submitting || invalid || isLoading}
              name={isLoading ? 'Creating' : 'Create'}
            >
              {isLoading ? 'Creating' : 'Create'}
            </Button>
          </ButtonsWrapper>
        </form>
      </BodyStyled>
    </StyledModalWrapper>
  );
};

const mapStateToProps = (state, ownProps) => {
  const regexDevId = /\(([A-Z0-9]+)\)/g;
  const developerId = get(
    regexDevId.exec(get(ownProps, 'info.signed_with[0]', '')),
    '[1]',
  );
  const initialValues = {
    block_process_name: {
      is_enabled: !!ownProps.info.process_name,
      value: ownProps.info.process_name,
      type: 'exact',
      label: 'Block Process Name',
    },
    block_application_path: {
      is_enabled: !!ownProps.info.path,
      value: ownProps.info.path,
      type: 'contains',
      label: 'Block Application Path',
    },
    block_bundle_id: {
      is_enabled: !!ownProps.info.bundle_identifier,
      value: ownProps.info.bundle_identifier,
      type: 'exact',
      label: 'Block Bundle ID',
    },
    block_using_developer_id: {
      is_enabled: false,
      value: developerId,
      label: 'Block all applications using Developer ID',
    },
  };
  return {
    initialValues,
    computer: state.computerRecord,
    blueprints: state.data.blueprints,
    formValues: getFormValues('appBlacklist')(state) || initialValues,
    currentForm: state.form.appBlacklist,
  };
};

/* istanbul ignore next */
const validate = (values) => {
  const errors = {};
  errors.block_using_developer_id = {
    value: get(values, 'block_using_developer_id.is_enabled')
      ? devIDValidator(get(values, 'block_using_developer_id.value'))
      : false,
  };
  errors.block_bundle_id = {
    value: get(values, 'block_bundle_id.is_enabled')
      ? bundleValidator(get(values, 'block_bundle_id.value'))
      : false,
  };
  errors.block_application_path = {
    value: get(values, 'block_application_path.is_enabled')
      ? fullPathValidator(get(values, 'block_application_path.value'))
      : false,
  };
  return errors;
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createAppBlacklistRules: callCreateAppBlacklistRules,
      setSnackbar: callSetSnackbar,
    },
    dispatch,
  );

AppBlacklist = reduxForm({
  form: 'appBlacklist',
  validate,
})(AppBlacklist);

AppBlacklist = connect(mapStateToProps, mapDispatchToProps)(AppBlacklist);

export default AppBlacklist;
