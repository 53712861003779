import { Toaster as toaster } from '@kandji-inc/bumblebee';
import { Button, Dialog, Flex, Text } from '@kandji-inc/nectar-ui';
import React, { useState } from 'react';

import { i18n } from 'i18n';
import { reinstallAgent } from 'src/app/components/computer/api';

const ComputerReinstallAgent = (props) => {
  const { info, onHide } = props;
  const { computerId } = info;

  const [isLoading, setIsLoading] = useState(false);

  const onReinstall = () => {
    setIsLoading(true);
    reinstallAgent(computerId)
      .then(() => {
        onHide();
        setIsLoading(false);
        toaster(i18n.t('Command initiated'));
      })
      .catch(() => {
        onHide();
        setIsLoading(false);
        toaster(i18n.error.generic());
      });
  };

  const content = (
    <Text>
      You should only attempt to re-install the Kandji agent if it no longer
      appears to be checking in. Please verify the device has a reliable
      internet connection, and attempt a restart before re-install. Are you sure
      you want to continue?
    </Text>
  );

  const footer = (
    <Flex gap="md" justifyContent="end" wrap="wrap">
      <Button variant="subtle" onClick={onHide}>
        Cancel
      </Button>
      <Button variant="primary" loading={isLoading} onClick={onReinstall}>
        Re-install Agent
      </Button>
    </Flex>
  );

  return (
    <Dialog
      isOpen
      closeOnEscape
      closeOnOutsideClick
      onOpenChange={onHide}
      title="Re-install Agent?"
      content={content}
      footer={footer}
      css={{ zIndex: 2000 }}
    />
  );
};

export default ComputerReinstallAgent;
