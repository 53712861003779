import { blueprintLibraryCategories } from 'features/blueprints/common';
import Passcode from 'features/library-items/items/Passcode';
import SystemExtensionV2 from 'features/library-items/items/SystemExtension';
import AirPrintV2 from 'features/library-items/items/air-print';
import AirplaySecurityV2 from 'features/library-items/items/airplay-security';
import AppLockV2 from 'features/library-items/items/app-lock';
import AppStoreV2 from 'features/library-items/items/app-store';
import AutoEnrollment from 'features/library-items/items/auto-enroll';
import CertificateV2 from 'features/library-items/items/certificate';
import ConferenceRoomDisplayV2 from 'features/library-items/items/conference-room-display';
import CustomProfileV2 from 'features/library-items/items/custom-profile';
import DeviceName from 'features/library-items/items/device-name/device-name';
import FileVaultV2 from 'features/library-items/items/file-vault';
import FirewallV2 from 'features/library-items/items/firewall';
import GatekeeperV2 from 'features/library-items/items/gatekeeper/gatekeeper';
import KernelExtensionV2 from 'features/library-items/items/kernel-extension';
import LoginBackgroundItems from 'features/library-items/items/login-background-items';
import LoginWindow from 'features/library-items/items/login-window';
import ManagedDataFlow from 'features/library-items/items/managed-data-flow';
import MediaAccessV2 from 'features/library-items/items/media-access';
import RecoveryPassword from 'features/library-items/items/recovery-password';
import ScreenSaver from 'features/library-items/items/screen-saver';
import SingleSignOnExtension from 'features/library-items/items/single-sign-on-extension/single-sign-on-extension';
import SoftwareUpdatesV2 from 'features/library-items/items/software-update';
import SshProfile from 'features/library-items/items/ssh-profile/ssh-profile';
import SystemPreferencesV2 from 'features/library-items/items/system-preferences';
import Wallpaper from 'features/library-items/items/wallpaper/wallpaper';
import WifiV2 from 'features/library-items/items/wifi';
/* istanbul ignore file */
import React from 'react';
import FeatureFlags from 'src/config/feature-flags';
import featureFlags from 'src/config/feature-flags';
import SafariExtensions from 'src/features/library-items/items/SafariExtensions';
import EnergySaver from 'src/features/library-items/items/energy-saver';
import Ethernet from 'src/features/library-items/items/ethernet';
import HomeScreenLayout from 'src/features/library-items/items/home-screen-layout';
import PrivacyV2 from 'src/features/library-items/items/privacy';
import Restrictions from 'src/features/library-items/items/restrictions';
import SetupAssistant from 'src/features/library-items/items/setup-assistant';
import VPN from 'src/features/library-items/items/vpn';
import { SCEPProfilePage } from 'src/old-library/SCEPProfilePage';
import DiskManagement from '../../../items/disk-management';
import {
  apiTypes,
  canAddLibraryTypes,
  categories,
  deviceTypes,
  osRequirements,
  publishers,
} from '../../common';
import svgs from '../icons';
import LibraryItemConfig from './item-config.class';

const profileApps = {
  'Airplay Security': new LibraryItemConfig({
    name: 'AirPlay Security',
    description:
      'AirPlay Security allows you to control whether or not devices must be on the same network as Apple TV to initiate an AirPlay connection, as well as enforce AirPlay passcode requirements.',
    type: apiTypes.PROFILE,
    publisher: publishers.APPLE,
    devices: [deviceTypes.TV],
    osRequirements: [osRequirements.TV_11_0],
    category: categories.PROFILES,
    blueprintCategory: blueprintLibraryCategories.PROFILES,
    icon: svgs['AirPlay Security'],
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: 'com.kandji.profile.airplaysecurity',
    routeType: 'airplay-security',
    getUrl({ id }) {
      return `/library/airplay-security/${id}`;
    },
    getAddUrl: () => `/library/airplay-security/add`,
    component(props) {
      return <AirplaySecurityV2 {...props} />;
    },
    singleBlueprintAllowed: true,
  }),
  AirPrint: new LibraryItemConfig({
    name: 'AirPrint',
    description: `Configure AirPrint printers that will be available in a user’s Add Printer list when connected to the same local network. Note that AirPrint printers are not automatically added to a device.`,
    type: apiTypes.PROFILE,
    publisher: publishers.APPLE,
    devices: [deviceTypes.MAC, deviceTypes.IPHONE, deviceTypes.IPAD],
    osRequirements: [
      osRequirements.MAC_10_10,
      osRequirements.IOS_7_0,
      osRequirements.IPAD_13_0,
    ],
    category: categories.PROFILES,
    blueprintCategory: blueprintLibraryCategories.PROFILES,
    icon: svgs.Airprint,
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: 'com.kandji.profile.airprint',
    routeType: 'airprint',
    getUrl({ id, ...rest }) {
      return `/library/airprint/${id}${
        this.isOldPath(rest) ? '/settings' : ''
      }`;
    },
    getAddUrl: () => `/library/airprint/add`,
    component(props) {
      return <AirPrintV2 {...props} />;
    },
    singleBlueprintAllowed: false,
  }),
  'App Lock': new LibraryItemConfig({
    name: 'App Lock',
    description: `Lock devices to a specified app until the profile is removed. The home button is disabled, and the device returns to the app automatically upon wake or reboot.`,
    type: apiTypes.PROFILE,
    publisher: publishers.APPLE,
    devices: [deviceTypes.IPHONE, deviceTypes.IPAD, deviceTypes.TV],
    osRequirements: [
      osRequirements.IOS_6_0,
      osRequirements.IPAD_13_0,
      osRequirements.TV_10_2,
    ],
    category: categories.PROFILES,
    blueprintCategory: blueprintLibraryCategories.PROFILES,
    icon: svgs.AppLock,
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: 'com.kandji.profile.applock',
    routeType: 'app-lock',
    getUrl({ id }) {
      return `/library/app-lock/${id}${this.isOldPath() ? '/settings' : ''}`;
    },
    getAddUrl: () => `/library/app-lock/add`,
    component(props) {
      return <AppLockV2 {...props} />;
    },
    singleBlueprintAllowed: true,
  }),
  'App Store': new LibraryItemConfig({
    name: 'App Store',
    description: `The App Store profile allows you to configure options such as disabling app store update notifications and restricting the Mac App Store to only update apps.`,
    type: apiTypes.PROFILE,
    publisher: publishers.APPLE,
    devices: [deviceTypes.MAC],
    osRequirements: [osRequirements.MAC_10_7],
    category: categories.PROFILES,
    blueprintCategory: blueprintLibraryCategories.PROFILES,
    icon: svgs['App Store'],
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: 'com.kandji.profile.appstore',
    routeType: 'app-store',
    getUrl({ id }) {
      return `/library/${this.routeType}/${id}`;
    },
    getAddUrl: () => `/library/app-store/add`,
    component(props) {
      return <AppStoreV2 {...props} />;
    },
    singleBlueprintAllowed: true,
  }),
  Certificate: new LibraryItemConfig({
    name: 'Certificate',
    description: `Upload and distribute certificates to your organization’s devices.`,
    type: apiTypes.PROFILE,
    publisher: publishers.APPLE,
    devices: [
      deviceTypes.MAC,
      deviceTypes.IPHONE,
      deviceTypes.IPAD,
      deviceTypes.TV,
    ],
    osRequirements: [
      osRequirements.MAC_10_7,
      osRequirements.IOS_4_0,
      osRequirements.IPAD_13_0,
      osRequirements.TV_9_0,
    ],
    category: categories.PROFILES,
    blueprintCategory: blueprintLibraryCategories.PROFILES,
    icon: svgs['Certificate Icon'],
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: 'com.kandji.profile.certificate',
    routeType: 'certificate',
    getUrl({ id, ...rest }) {
      return `/library/certificate/${id}${
        this.isOldPath(rest) ? '/settings' : ''
      }`;
    },
    getAddUrl: () => `/library/certificate/add`,
    component(props) {
      return <CertificateV2 {...props} />;
    },
    singleBlueprintAllowed: false,
  }),
  'Conference Room Display': new LibraryItemConfig({
    name: 'Conference Room Display',
    description: `Conference Room Display mode locks Apple TV to its screen savers while displaying the AirPlay dialog box, which instructs end users on how to connect their devices. Enabling Conference Room Display mode will prevent use of the Apple TV other than for AirPlay.`,
    type: apiTypes.PROFILE,
    publisher: publishers.APPLE,
    devices: [deviceTypes.TV],
    osRequirements: [osRequirements.TV_10_2],
    category: categories.PROFILES,
    blueprintCategory: blueprintLibraryCategories.PROFILES,
    icon: svgs['Conference Room Display'],
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: 'com.kandji.profile.conferenceroomdisplay',
    routeType: 'conference-room-display',
    getUrl({ id }) {
      return `/library/conference-room-display/${id}`;
    },
    getAddUrl: () => `/library/conference-room-display/add`,
    component(props) {
      return <ConferenceRoomDisplayV2 {...props} />;
    },
    singleBlueprintAllowed: true,
  }),
  'Energy Saver': new LibraryItemConfig({
    name: 'Energy Saver',
    description: `Configure power-related settings for macOS devices.`,
    type: apiTypes.PROFILE,
    publisher: publishers.APPLE,
    devices: [deviceTypes.MAC],
    osRequirements: [osRequirements.MAC_10_7],
    category: categories.PROFILES,
    blueprintCategory: blueprintLibraryCategories.PROFILES,
    icon: svgs['Energy Saver'],
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: 'com.kandji.profile.energysaver',
    routeType: 'energy-saver',
    getUrl: ({ id }) => `/library/energy-saver/${id}`,
    getAddUrl: () => `/library/energy-saver/add`,
    component: (props) => <EnergySaver {...props} />,
    singleBlueprintAllowed: true,
  }),
  FileVault: new LibraryItemConfig({
    name: 'FileVault',
    description: `Manage FileVault enablement and configure options for FileVault recovery key escrow.`,
    type: apiTypes.PROFILE,
    publisher: publishers.APPLE,
    devices: [deviceTypes.MAC],
    osRequirements: [osRequirements.MAC_10_7],
    category: categories.PROFILES,
    blueprintCategory: blueprintLibraryCategories.PROFILES,
    icon: svgs['FileVault Icon'],
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: 'com.kandji.profile.filevault',
    routeType: 'filevault',
    getUrl({ id }) {
      return `/library/filevault/${id}`;
    },
    getAddUrl: () => `/library/filevault/add`,
    component(props) {
      return <FileVaultV2 {...props} />;
    },
    singleBlueprintAllowed: true,
  }),
  Firewall: new LibraryItemConfig({
    name: 'Firewall',
    description: `Firewall allows you to configure the macOS firewall status, network access rules for applications, and configure firewall logging options.`,
    type: apiTypes.PROFILE,
    publisher: publishers.APPLE,
    devices: [deviceTypes.MAC],
    osRequirements: [osRequirements.MAC_10_7],
    category: categories.PROFILES,
    blueprintCategory: blueprintLibraryCategories.PROFILES,
    icon: svgs['Firewall Icon'],
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: 'com.kandji.profile.firewall',
    routeType: 'firewall',
    getUrl({ id, ...rest }) {
      return `/library/firewall/${id}`;
    },
    getAddUrl: () => `/library/firewall/add`,
    component(props) {
      return <FirewallV2 {...props} />;
    },
    singleBlueprintAllowed: true,
  }),
  Gatekeeper: new LibraryItemConfig({
    name: 'Gatekeeper',
    description: `Gatekeeper is a technology that restricts quarantined applications from launching. It functions as a control to limit applications from unverified sources from running without authorization.`,
    type: apiTypes.PROFILE,
    publisher: publishers.APPLE,
    devices: [deviceTypes.MAC],
    osRequirements: [osRequirements.MAC_10_7],
    category: categories.PROFILES,
    blueprintCategory: blueprintLibraryCategories.PROFILES,
    icon: svgs.Gatekeeper,
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: 'com.kandji.profile.gatekeeper',
    routeType: 'gatekeeper',
    getUrl: ({ id }) => `/library/gatekeeper/${id}`,
    getAddUrl: () => `/library/gatekeeper/add`,
    component(props) {
      return <GatekeeperV2 {...props} />;
    },
    singleBlueprintAllowed: true,
  }),
  'Home Screen Layout': new LibraryItemConfig({
    name: 'Home Screen Layout',
    description: 'Manage app layout on iPad and iPhone.',
    type: apiTypes.PROFILE,
    publisher: publishers.APPLE,
    devices: [deviceTypes.IPHONE, deviceTypes.IPAD],
    osRequirements: [osRequirements.IOS_9_3, osRequirements.IPAD_13_0],
    category: categories.PROFILES,
    blueprintCategory: blueprintLibraryCategories.PROFILES,
    icon: svgs.HomeScreenLayout,
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: 'com.kandji.profile.homescreenlayout',
    routeType: 'home-screen-layout',
    /* istanbul ignore next */
    getUrl({ id, ...rest }) {
      return `/library/home-screen-layout/${id}`;
    },

    getAddUrl: /* istanbul ignore next */ () =>
      `/library/home-screen-layout/add`,
    /* istanbul ignore next */
    component(props) {
      return <HomeScreenLayout {...props} />;
    },

    singleBlueprintAllowed: true,
  }),
  'Kernel Extension': new LibraryItemConfig({
    name: 'Kernel Extension',
    description: `Specify kernel extensions that are always allowed to load on the computer.`,
    type: apiTypes.PROFILE,
    publisher: publishers.APPLE,
    devices: [deviceTypes.MAC],
    osRequirements: [osRequirements.MAC_10_13_2],
    category: categories.PROFILES,
    blueprintCategory: blueprintLibraryCategories.PROFILES,
    icon: svgs['Kernel extension'],
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: 'com.kandji.profile.kernel',
    routeType: 'kernel-extensions',
    getUrl({ id, ...rest }) {
      return `/library/kernel-extensions/${id}`;
    },
    getAddUrl: () => `/library/kernel-extensions/add`,
    component(props) {
      return <KernelExtensionV2 {...props} />;
    },
    singleBlueprintAllowed: false,
  }),
  'Login & Background Items': new LibraryItemConfig({
    name: 'Login & Background Items',
    description:
      "Prevent a user from disabling background items for apps. Background items can perform tasks when an app isn't open, such as checking for software updates and syncing data.",
    type: apiTypes.PROFILE,
    publisher: publishers.APPLE,
    devices: [deviceTypes.MAC],
    osRequirements: [osRequirements.MAC_13_0],
    category: categories.PROFILES,
    blueprintCategory: blueprintLibraryCategories.PROFILES,
    icon: svgs['Login Items'],
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: 'com.kandji.profile.loginandbackgrounditems',
    routeType: 'login-background-items',
    getUrl({ id, ...rest }) {
      return `/library/login-background-items/${id}`;
    },
    getAddUrl: () => `/library/login-background-items/add`,
    component(props) {
      return <LoginBackgroundItems {...props} />;
    },
    singleBlueprintAllowed: false,
  }),
  'Login Window': new LibraryItemConfig({
    name: 'Login Window',
    description: `The Login Window profile allows you to customize options for login window and lock screen, including the lock message, button availability, and user account visibility.`,
    type: apiTypes.PROFILE,
    publisher: publishers.APPLE,
    devices: [deviceTypes.MAC],
    osRequirements: [osRequirements.MAC_10_7],
    category: categories.PROFILES,
    blueprintCategory: blueprintLibraryCategories.PROFILES,
    icon: svgs['Login Window Icon'],
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: 'com.kandji.profile.loginwindow',
    routeType: 'login-window',
    getUrl({ id, ...rest }) {
      return `/library/login-window/${id}`;
    },
    getAddUrl: () => `/library/login-window/add`,
    component(props) {
      return <LoginWindow {...props} />;
    },
    singleBlueprintAllowed: true,
  }),
  'Media Access': new LibraryItemConfig({
    name: 'Media Access',
    description: `Manage mount control settings for each media type. Note that "Allow with authentication" and "Read-only with authentication" options only apply to Standard users.`,
    type: apiTypes.PROFILE,
    publisher: publishers.APPLE,
    devices: [deviceTypes.MAC],
    osRequirements: [osRequirements.MAC_10_7],
    category: categories.PROFILES,
    blueprintCategory: blueprintLibraryCategories.PROFILES,
    icon: svgs['Media Access'],
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: 'com.kandji.profile.mediaaccess',
    routeType: 'media-access',
    getUrl({ id }) {
      return `/library/media-access/${id}`;
    },
    getAddUrl: () => `/library/media-access/add`,
    component(props) {
      return <MediaAccessV2 {...props} />;
    },
    singleBlueprintAllowed: true,
  }),
  'Disk Management': new LibraryItemConfig({
    name: 'Disk Management',
    description: `Manage mount control settings for external and network volumes using Declarative Device Management in macOS 15 and later.`,
    type: apiTypes.PROFILE,
    publisher: publishers.APPLE,
    devices: [deviceTypes.MAC],
    osRequirements: [osRequirements.MAC_15_0],
    category: categories.PROFILES,
    blueprintCategory: blueprintLibraryCategories.PROFILES,
    icon: svgs['Disk management'],
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: 'com.kandji.profile.diskmanagement',
    routeType: 'disk-management',
    getUrl({ id }) {
      return `/library/disk-management/${id}`;
    },
    getAddUrl: () => `/library/disk-management/add`,
    component(props) {
      return <DiskManagement {...props} />;
    },
    singleBlueprintAllowed: true,
    isHidden: () => !FeatureFlags.getFlag('DC-722-disk-management-li'),
  }),
  'Safari Extensions': new LibraryItemConfig({
    name: 'Safari Extensions',
    description: `Configure settings for Safari Extensions in iOS and iPadOS 18 and later.`,
    type: apiTypes.PROFILE,
    publisher: publishers.APPLE,
    devices: [deviceTypes.IPHONE, deviceTypes.IPAD],
    osRequirements: [osRequirements.IOS_18_0, osRequirements.IPAD_18_0],
    category: categories.PROFILES,
    blueprintCategory: blueprintLibraryCategories.PROFILES,
    icon: svgs.SafariExtensions,
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: 'com.kandji.profile.safariextensions',
    routeType: 'safari-extensions',
    getUrl({ id }) {
      return `/library/safari-extensions/${id}`;
    },
    getAddUrl: () => `/library/safari-extensions/add`,
    component(props) {
      return <SafariExtensions {...props} />;
    },
    singleBlueprintAllowed: false,
    isHidden: () => !FeatureFlags.getFlag('dc-725-safari-extensions-li'),
  }),
  Passcode: new LibraryItemConfig({
    name: 'Passcode',
    description: `Enforce passcode requirements for your devices. Specify different passcode settings based on device type.`,
    type: apiTypes.PROFILE,
    publisher: publishers.APPLE,
    devices: [deviceTypes.MAC, deviceTypes.IPHONE, deviceTypes.IPAD],
    osRequirements: [
      osRequirements.MAC_10_7,
      osRequirements.IOS_4_0,
      osRequirements.IPAD_13_0,
    ],
    category: categories.PROFILES,
    blueprintCategory: blueprintLibraryCategories.PROFILES,
    icon: svgs['Passcode Icon'],
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: 'com.kandji.profile.passwordpolicy',
    routeType: 'passcode',
    getUrl: ({ id }) => `/library/passcode/${id}`,
    getAddUrl: () => `/library/passcode/add`,
    component: (props) => <Passcode {...props} />,
    singleBlueprintAllowed: true,
  }),
  Privacy: new LibraryItemConfig({
    name: 'Privacy',
    description: `Configure privacy settings for macOS apps and services.`,
    type: apiTypes.PROFILE,
    publisher: publishers.APPLE,
    devices: [deviceTypes.MAC],
    osRequirements: [osRequirements.MAC_10_14],
    category: categories.PROFILES,
    blueprintCategory: blueprintLibraryCategories.PROFILES,
    icon: svgs['Privacy Policy'],
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: 'com.kandji.profile.privacy',
    routeType: 'privacy',
    singleBlueprintAllowed: false,
    getUrl({ id }) {
      return `/library/privacy/${id}${this.isOldPath() ? '/settings' : ''}`;
    },
    getAddUrl: () => `/library/privacy/add`,
    isHidden: () => false,
    isOldPath: () => false,
    component: (props) => <PrivacyV2 {...props} />,
  }),
  Restrictions: new LibraryItemConfig({
    name: 'Restrictions',
    description: `Configure a variety of restrictions for your devices. Note that many of the options for iPhone, iPad, and Apple TV require device supervision.`,
    type: apiTypes.PROFILE,
    publisher: publishers.APPLE,
    devices: [
      deviceTypes.MAC,
      deviceTypes.IPHONE,
      deviceTypes.IPAD,
      deviceTypes.TV,
    ],
    osRequirements: [
      osRequirements.MAC_10_7,
      osRequirements.IOS_4_0,
      osRequirements.IPAD_13_0,
      osRequirements.TV_9_0,
    ],
    category: categories.PROFILES,
    blueprintCategory: blueprintLibraryCategories.PROFILES,
    icon: svgs.Restrictions,
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: 'com.kandji.profile.restrictions',
    routeType: 'restrictions',
    getUrl: ({ id }) => `/library/restrictions/${id}`,
    getAddUrl: () => `/library/restrictions/add`,
    component: (props) => <Restrictions {...props} />,
    isOldPath: () => false,
    singleBlueprintAllowed: false,
  }),
  SCEP: new LibraryItemConfig({
    name: 'SCEP',
    description: `The Simple Certificate Enrollment Protocol (SCEP) profile allows you to securely issue certificates to your Apple devices from a SCEP server and Certificate Authority. These certificates can be used for services such as 802.1x, VPN, and others.`,
    type: apiTypes.PROFILE,
    publisher: publishers.APPLE,
    devices: [
      deviceTypes.MAC,
      deviceTypes.IPHONE,
      deviceTypes.IPAD,
      deviceTypes.TV,
    ],
    osRequirements: [
      osRequirements.MAC_10_7,
      osRequirements.IOS_4_0,
      osRequirements.IPAD_13_0,
      osRequirements.TV_9_0,
    ],
    category: categories.PROFILES,
    blueprintCategory: blueprintLibraryCategories.PROFILES,
    icon: svgs['SCEP Icon'],
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: 'com.kandji.profile.scep',
    routeType: 'scep',
    getUrl: ({ id }) => `/library/scep/${id}/settings`,
    getAddUrl: () => `/library/scep/add`,
    component: (props) => <SCEPProfilePage {...props} />,
    isOldPath: () => true,
    singleBlueprintAllowed: false,
  }),
  'Screen Saver': new LibraryItemConfig({
    name: 'Screen Saver',
    description: `Screen Saver allows you to configure the start interval and Screen Saver module to display, for both user and login window.`,
    type: apiTypes.PROFILE,
    publisher: publishers.APPLE,
    devices: [deviceTypes.MAC],
    osRequirements: [osRequirements.MAC_10_7],
    category: categories.PROFILES,
    blueprintCategory: blueprintLibraryCategories.PROFILES,
    icon: svgs['Screen Saver'],
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: 'com.kandji.profile.screensaver',
    routeType: 'screen-saver',
    getUrl: ({ id }) => `/library/screen-saver/${id}`,
    getAddUrl: () => `/library/screen-saver/add`,
    component(props) {
      return <ScreenSaver {...props} />;
    },
    singleBlueprintAllowed: true,
  }),
  'Single Sign-On Extension': new LibraryItemConfig({
    name: 'Single Sign-On Extension',
    description: `Use the Enterprise Single Sign-On Extension profile to manage credential, redirect and the Kerberos SSO extensions on macOS Catalina, iPadOS 13, and iOS 13 and later.`,
    type: apiTypes.PROFILE,
    publisher: publishers.APPLE,
    devices: [deviceTypes.MAC, deviceTypes.IPHONE, deviceTypes.IPAD],
    osRequirements: [
      osRequirements.MAC_10_15,
      osRequirements.IOS_13_0,
      osRequirements.IPAD_13_0,
    ],
    category: categories.PROFILES,
    blueprintCategory: blueprintLibraryCategories.PROFILES,
    icon: svgs.ssoExtensionProfile,
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: 'com.kandji.profile.ssoextension',
    routeType: 'sso-extension',
    getUrl: ({ id }) => `/library/sso-extension/${id}`,
    getAddUrl: () => `/library/sso-extension/add`,
    component: (props) => <SingleSignOnExtension {...props} />,
    singleBlueprintAllowed: false,
  }),
  'Software Update': new LibraryItemConfig({
    name: 'Software Update',
    description: `Manage automatic update settings, deferral length, and beta access.`,
    type: apiTypes.PROFILE,
    publisher: publishers.APPLE,
    devices: [deviceTypes.MAC, deviceTypes.IPAD, deviceTypes.IPHONE],
    osRequirements: [
      osRequirements.MAC_10_7,
      osRequirements.IOS_18_0,
      osRequirements.IPAD_18_0,
    ],
    category: categories.PROFILES,
    blueprintCategory: blueprintLibraryCategories.PROFILES,
    icon: svgs['Software Update Icon'],
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: 'com.kandji.profile.softwareupdates',
    routeType: 'software-updates',
    getUrl({ id, ...rest }) {
      return `/library/software-updates/${id}${
        this.isOldPath(rest) ? '/settings' : ''
      }`;
    },
    getAddUrl: () => `/library/software-updates/add`,
    component(props) {
      return <SoftwareUpdatesV2 {...props} />;
    },
    singleBlueprintAllowed: true,
  }),
  SSH: new LibraryItemConfig({
    name: 'SSH',
    description: `Configure and manage both the SSH server and SSH client within macOS.`,
    type: apiTypes.SSH,
    publisher: publishers.APPLE,
    devices: [deviceTypes.MAC],
    osRequirements: [osRequirements.MAC_10_11],
    category: categories.PROFILES,
    icon: svgs.SSH,
    blueprintCategory: blueprintLibraryCategories.PROFILES,
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: 'com.kandji.profile.ssh',
    routeType: 'ssh',
    getUrl: ({ id }) => `/library/ssh/${id}`,
    getAddUrl: () => `/library/ssh/add`,
    component(props) {
      return <SshProfile {...props} />;
    },
    isOldPath: () => false,
    singleBlueprintAllowed: true,
  }),
  'System Extension': new LibraryItemConfig({
    name: 'System Extension',
    description: `Allow system extensions from specific developers and manage the user's ability to approve system extensions on their device.`,
    type: apiTypes.PROFILE,
    publisher: publishers.APPLE,
    devices: [deviceTypes.MAC],
    osRequirements: [osRequirements.MAC_10_15],
    category: categories.PROFILES,
    blueprintCategory: blueprintLibraryCategories.PROFILES,
    icon: svgs['System extension'],
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: 'com.kandji.profile.systemextension',
    routeType: 'system-extension',
    getUrl({ id, ...rest }) {
      return `/library/system-extension/${id}`;
    },
    getAddUrl: () => `/library/system-extension/add`,
    component(props) {
      return <SystemExtensionV2 {...props} />;
    },
    singleBlueprintAllowed: false,
  }),
  'System Preferences Panes': new LibraryItemConfig({
    name: 'System Preferences Panes',
    description: `System Preferences Panes disables access to select System Preferences to block users from viewing or modifying settings.`,
    type: apiTypes.PROFILE,
    publisher: publishers.APPLE,
    devices: [deviceTypes.MAC],
    osRequirements: [osRequirements.MAC_10_7],
    category: categories.PROFILES,
    blueprintCategory: blueprintLibraryCategories.PROFILES,
    icon: svgs['System Preference Panes'],
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: 'com.kandji.profile.systempreferences',
    routeType: 'system-preferences',
    getUrl({ id }) {
      return `/library/system-preferences/${id}`;
    },
    getAddUrl: () => `/library/system-preferences/add`,
    component(props) {
      return <SystemPreferencesV2 {...props} />;
    },
    singleBlueprintAllowed: true,
  }),
  VPN: new LibraryItemConfig({
    name: 'VPN',
    description: `Configure settings for one or more VPN connection.`,
    type: apiTypes.PROFILE,
    publisher: publishers.APPLE,
    devices: [deviceTypes.MAC, deviceTypes.IPHONE, deviceTypes.IPAD],
    osRequirements: [
      osRequirements.MAC_10_7,
      osRequirements.IOS_4_0,
      osRequirements.IPAD_13_0,
    ],
    category: categories.PROFILES,
    blueprintCategory: blueprintLibraryCategories.PROFILES,
    icon: svgs.VPN,
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: 'com.kandji.profile.vpn',
    routeType: 'vpn',
    getUrl({ id }) {
      return `/library/vpn/${id}`;
    },
    getAddUrl() {
      return `/library/vpn/add`;
    },
    component(props) {
      return <VPN {...props} />;
    },
    singleBlueprintAllowed: false,
  }),
  'Wi-Fi': new LibraryItemConfig({
    name: 'Wi-Fi',
    description: `Configure settings for managed Wi-Fi networks.`,
    type: apiTypes.PROFILE,
    publisher: publishers.APPLE,
    devices: [
      deviceTypes.MAC,
      deviceTypes.IPHONE,
      deviceTypes.IPAD,
      deviceTypes.TV,
    ],
    osRequirements: [
      osRequirements.MAC_10_7,
      osRequirements.IOS_4_0,
      osRequirements.IPAD_13_0,
      osRequirements.TV_9_0,
    ],
    category: categories.PROFILES,
    blueprintCategory: blueprintLibraryCategories.PROFILES,
    icon: svgs.Wifi,
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: 'com.kandji.profile.wifi',
    routeType: 'wifi',
    getUrl({ id }) {
      return `/library/wifi/${id}`;
    },
    getAddUrl: () => `/library/wifi/add`,
    component(props) {
      return <WifiV2 {...props} />;
    },
    singleBlueprintAllowed: false,
  }),
  Ethernet: new LibraryItemConfig({
    name: 'Ethernet',
    description: `Configure settings for 802.1X Ethernet.`,
    type: apiTypes.PROFILE,
    publisher: publishers.APPLE,
    devices: [deviceTypes.MAC],
    osRequirements: [osRequirements.MAC_10_13],
    category: categories.PROFILES,
    blueprintCategory: blueprintLibraryCategories.PROFILES,
    icon: svgs.ethernet,
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: 'com.kandji.profile.ethernet',
    routeType: 'ethernet',
    getUrl({ id }) {
      return `/library/ethernet/${id}`;
    },
    getAddUrl: () => `/library/ethernet/add`,
    component(props) {
      return <Ethernet {...props} />;
    },
    singleBlueprintAllowed: true,
  }),
  'Automated Device Enrollment': new LibraryItemConfig({
    name: 'Automated Device Enrollment',
    description: `Configure settings for Automated Device Enrollment devices, including which screens are skipped during Setup Assistant.`,
    type: apiTypes.DEP_PROFILE,
    publisher: publishers.APPLE,
    devices: [
      deviceTypes.MAC,
      deviceTypes.IPHONE,
      deviceTypes.IPAD,
      deviceTypes.TV,
    ],
    osRequirements: [
      osRequirements.MAC_10_9,
      osRequirements.IOS_7_0,
      osRequirements.IPAD_13_0,
      osRequirements.TV_10_2,
    ],
    category: categories.ENROLLMENT,
    blueprintCategory: blueprintLibraryCategories.ENROLLMENT,
    icon: svgs['Auto-enroll Configuration'],
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: 'com.kandji.profile.autoenroll',
    routeType: 'auto-enroll',
    getUrl: ({ id }) => `/library/auto-enroll/${id}`,
    getAddUrl: () => `/library/auto-enroll/add`,
    component: (props) => <AutoEnrollment {...props} />,
    isHidden: ({ account, environment }) => {
      const { inclusionLvl } = environment;
      return inclusionLvl(1) ? false : !account.currentCompany?.is_dep;
    },
    singleBlueprintAllowed: true,
  }),
  'Custom Profile': new LibraryItemConfig({
    name: 'Custom Profile',
    description: `Distribute a custom configuration profile using Kandji. Profiles can be exported from tools like Apple Configurator or Profile Manager and then uploaded as .mobileconfig files.`,
    type: apiTypes.PROFILE,
    publisher: publishers.KANDJI,
    devices: [
      deviceTypes.MAC,
      deviceTypes.IPHONE,
      deviceTypes.IPAD,
      deviceTypes.TV,
    ],
    osRequirements: [
      osRequirements.MAC_10_11,
      osRequirements.IOS_11_0,
      osRequirements.IPAD_11_0,
      osRequirements.TV_11_0,
    ],
    category: categories.GENERAL,
    blueprintCategory: blueprintLibraryCategories.PROFILES,
    icon: svgs['Custom Profile'],
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: 'com.kandji.profile.custom',
    routeType: 'custom-profile',
    getUrl({ id }) {
      return `/library/custom-profile/${id}`;
    },
    getAddUrl: () => `/library/custom-profile/add`,
    component(props) {
      return <CustomProfileV2 {...props} />;
    },
    singleBlueprintAllowed: false,
  }),
  'Recovery Password': new LibraryItemConfig({
    name: 'Recovery Password',
    description: `Configure and manage the recovery password on Mac computers with Apple silicon, or the firmware password on Mac computers with Intel.`,
    type: apiTypes.RECOVERY_PASSWORD,
    publisher: publishers.KANDJI,
    devices: [deviceTypes.MAC],
    osRequirements: [osRequirements.MAC_10_11],
    category: categories.PROFILES,
    identifier: '',
    blueprintCategory: blueprintLibraryCategories.PROFILES,
    icon: svgs['Recovery Password'],
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    routeType: 'recovery-password',
    getUrl: ({ id }) => `/library/recovery-password/${id}/settings`,
    getAddUrl: () => `/library/recovery-password/add`,
    component(props) {
      return <RecoveryPassword {...props} />;
    },
    isOldPath: () => true,
    singleBlueprintAllowed: true,
  }),
  'Managed Data Flow': new LibraryItemConfig({
    name: 'Managed Data Flow',
    description: `Manage the flow of data between managed or unmanaged sources and destinations.`,
    type: apiTypes.PROFILE,
    publisher: publishers.APPLE,
    devices: [deviceTypes.IPHONE, deviceTypes.IPAD],
    osRequirements: [osRequirements.IOS_7_0, osRequirements.IPAD_13_0],
    category: categories.PROFILES,
    blueprintCategory: blueprintLibraryCategories.PROFILES,
    icon: svgs['Managed Data Flow'],
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: 'com.kandji.profile.manageddataflow',
    routeType: 'managed-data-flow',
    getUrl: ({ id }) => `/library/managed-data-flow/${id}`,
    getAddUrl: () => `/library/managed-data-flow/add`,
    component(props) {
      return <ManagedDataFlow {...props} />;
    },
    singleBlueprintAllowed: true,
  }),
  'Device Name': new LibraryItemConfig({
    name: 'Device Name',
    description: `Configure device name for macOS, iOS, iPadOS, and tvOS.`,
    type: apiTypes.DEVICE_NAME,
    publisher: publishers.APPLE,
    devices: [
      deviceTypes.MAC,
      deviceTypes.IPHONE,
      deviceTypes.IPAD,
      deviceTypes.TV,
    ],
    osRequirements: [
      osRequirements.MAC_11_0,
      osRequirements.IOS_9_0,
      osRequirements.IPAD_9_0,
      osRequirements.TV_9_0,
    ],
    category: categories.PROFILES,
    blueprintCategory: blueprintLibraryCategories.PROFILES,
    icon: svgs['Device Name'],
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: '',
    routeType: 'device-name',
    getUrl: ({ id }) => `/library/device-name/${id}`,
    getAddUrl: () => `/library/device-name/add`,
    component(props) {
      return <DeviceName {...props} />;
    },
    singleBlueprintAllowed: false,
    singleAssignmentMappingAllowed: true,
  }),
  Wallpaper: new LibraryItemConfig({
    name: 'Wallpaper',
    description: `Upload a custom image for the lock screen or home screen wallpaper for iPhone or iPad.`,
    type: apiTypes.WALLPAPER,
    publisher: publishers.APPLE,
    devices: [deviceTypes.IPHONE, deviceTypes.IPAD],
    osRequirements: [osRequirements.IOS_8_0, osRequirements.IPAD_13_0],
    category: categories.PROFILES,
    blueprintCategory: blueprintLibraryCategories.PROFILES,
    icon: svgs.Wallpaper,
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: '',
    routeType: 'wallpaper',
    getUrl: ({ id }) => `/library/wallpaper/${id}`,
    getAddUrl: () => `/library/wallpaper/add`,
    component(props) {
      return <Wallpaper {...props} />;
    },
    singleBlueprintAllowed: true,
  }),
  'Setup Assistant': new LibraryItemConfig({
    name: 'Setup Assistant',
    description: `Configure settings for Setup Assistant in iOS and iPadOS 14 and later, and macOS 15 and later. Use this Library Item in combination with Automated Device Enrollment settings to ensure certain screens are not shown to end users following OS updates or upgrades.`,
    type: apiTypes.PROFILE,
    publisher: publishers.APPLE,
    devices: [deviceTypes.MAC, deviceTypes.IPHONE, deviceTypes.IPAD],
    osRequirements: [
      osRequirements.MAC_15_0,
      osRequirements.IOS_14_0,
      osRequirements.IPAD_14_0,
    ],
    category: categories.PROFILES,
    blueprintCategory: blueprintLibraryCategories.PROFILES,
    icon: svgs['Setup Assistant'],
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: 'com.kandji.profile.setupassistant',
    routeType: 'setup-assistant',
    getUrl: ({ id }) => `/library/setup-assistant/${id}`,
    getAddUrl: () => `/library/setup-assistant/add`,
    component: (props) => <SetupAssistant {...props} />,
    isHidden: () => !featureFlags.getFlag('DC-setup-assistant'),
    singleBlueprintAllowed: true,
  }),
};

export default profileApps;
