import { type SelectOptionMember, styled } from '@kandji-inc/nectar-ui';
import type {
  AllowedSelectValue,
  CSS,
  SelectOptionMemberList,
  SelectSize,
} from '@kandji-inc/nectar-ui';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import ViewMultiSelect from '../../../integrator/layouts/partials/view-multi-select';
import useGetUserGroupList from '../../hooks/use-get-user-group-list';

interface Props {
  field: string;
  defaultOptions: SelectOptionMemberList<AllowedSelectValue, 'value', 'label'>;
  size?: SelectSize;
  css?: CSS;
}

const StyledTypeaheadContainer = styled('div', {
  paddingLeft: '$5',
});

const SelectUserGroup = ({
  field,
  defaultOptions = [],
  size = 'md',
  css = {},
}: Props) => {
  const { watch, getValues } = useFormContext();
  const [searchOptions, setSearchOptions] = useState<
    SelectOptionMemberList<AllowedSelectValue, 'value', 'label'>
  >([]);
  const [selectedOptions, setSelectedOptions] = useState<
    SelectOptionMemberList<AllowedSelectValue, 'value', 'label'>
  >([]);
  const [availableOptions, setAvailableOptions] = useState<
    SelectOptionMemberList<AllowedSelectValue, 'value', 'label'>
  >([]);

  const [searchTerm, setSearchTerm] = useState('');

  watch([field]);
  const formInputs = getValues();

  useGetUserGroupList({ setUserGroupOptions: setSearchOptions, searchTerm });

  useEffect(() => {
    const isInSearch = (
      option: SelectOptionMember<AllowedSelectValue, 'value', 'label'>,
    ) => searchOptions.some((so) => so.value === option.value);

    const uniqueSelected = selectedOptions.filter((s) => !isInSearch(s));
    setAvailableOptions([...searchOptions, ...uniqueSelected]);
  }, [searchOptions, selectedOptions]);

  return (
    <StyledTypeaheadContainer css={css}>
      {formInputs[field] && (
        <ViewMultiSelect
          size={size}
          label={
            field === 'macOsDevices'
              ? 'macOS user group '
              : 'iOS/iPadOS user group'
          }
          defaultValue={defaultOptions}
          options={availableOptions}
          onChange={(currentSelections) =>
            setSelectedOptions(currentSelections.map((s) => s.originalOption))
          }
          handleSearch={setSearchTerm}
          debounceTime={500}
          name={`${field}_groups`} // macOsDevices_groups or iosDevices_groups
          validations={{ required: 'Please select a user group' }}
        />
      )}
    </StyledTypeaheadContainer>
  );
};

export default SelectUserGroup;
