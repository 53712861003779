import { links } from 'app/common/constants';
import { i18n } from 'i18n';
import { get } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import uuidv1 from 'uuid/v1';
import NoDataToDisplayHappy from '../../../assets/img/no_data_to_display_happy.png';
import ParameterTab from '../common/ParameterTab';

const alertSubsections = {
  Alerts: () => i18n.t('Alerts', { _context: 'view alerts from devices' }),
  MutedAlerts: () =>
    i18n.t('Muted Alerts', {
      _context: 'view alerts from devices that have been muted',
    }),
  ClearedAlerts: () =>
    i18n.t('Cleared Alerts', {
      _context: 'view alerts that have been cleared',
    }),
};

export const getAlertsStatusList = () => [
  {
    value: 'ERROR,WARNING,MUTE',
    label: i18n.t('All Alerts'),
    iconClass: 'fa oa-3c long-icon',
  },
  {
    value: 'WARNING,ERROR',
    label: i18n.t('Alerts'),
    iconClass: 'fas fa-exclamation-triangle text-warning',
  },
  {
    value: 'MUTE',
    label: i18n.t('Muted Alerts'),
    iconClass: 'fas fa-exclamation-triangle text-grey',
  },
];

class TabsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: get(props, 'match.params.tab', 'active'),
    };
  }

  UNSAFE_componentWillReceiveProps(next) {
    const { activeTab } = this.state;
    if (next.match.params.tab !== activeTab) {
      this.setState({ activeTab: next.match.params.tab });
    }
  }

  renderNav = () => {
    const { activeTab } = this.state;
    return (
      <div>
        <nav className="nav nav-secondary">
          <Link
            className={activeTab === 'active' ? 'nav-link active' : 'nav-link'}
            to={links.alerts.active}
            key={uuidv1()}
          >
            {alertSubsections.Alerts()}
          </Link>

          <Link
            className={
              activeTab === 'activeMuted' ? 'nav-link active' : 'nav-link'
            }
            to={links.alerts.activeMuted}
            key={uuidv1()}
          >
            {alertSubsections.MutedAlerts()}
          </Link>

          <Link
            className={activeTab === 'old' ? 'nav-link active' : 'nav-link'}
            to={links.alerts.old}
            key={uuidv1()}
          >
            {alertSubsections.ClearedAlerts()}
          </Link>
        </nav>
      </div>
    );
  };

  renderTab = () => {
    const { location, match, history } = this.props;
    const { activeTab } = this.state;
    const routerProps = () => ({
      location,
      match,
      history,
    });
    const alertsStatusList = getAlertsStatusList();
    switch (activeTab) {
      default:
      case 'active':
        return (
          <ParameterTab
            isAlertTab
            isNavigationTab
            view
            expand
            created
            paramNameColWidth="100%"
            recordActions={[]}
            hideBuildNumber
            filters={[]}
            searchPlaceholder={i18n.action.Search(i18n.kandji.Alerts())}
            alertsStatusList={alertsStatusList}
            sortName="started_at"
            startedAtName="Created"
            noEndedAt
            noPeriod
            noDataText={i18n.t('No Alerts')}
            noDataImg={NoDataToDisplayHappy}
            {...routerProps()}
          />
        );
      case 'activeMuted':
        return (
          <ParameterTab
            isAlertTab
            isNavigationTab
            view
            expand
            created
            paramNameColWidth="100%"
            recordActions={[]}
            hideBuildNumber
            filters={[]}
            searchPlaceholder={i18n.action.Search(i18n.kandji.Alerts())}
            alertsStatusList={alertsStatusList}
            sortName="started_at"
            startedAtName="Created"
            noEndedAt
            noPeriod
            noDataText={i18n.t('No Muted Alerts')}
            noDataImg={NoDataToDisplayHappy}
            {...routerProps()}
          />
        );

      case 'old':
        return (
          <ParameterTab
            isAlertTab
            isNavigationTab
            view
            expand
            recordActions={[]}
            paramNameColWidth="100%"
            hideBuildNumber
            filters={['status']}
            searchPlaceholder={i18n.action.Search(i18n.kandji.Alerts())}
            alertsStatusList={alertsStatusList}
            sortName="run"
            startedAtName="Created"
            endedAtName="Cleared"
            noPeriod
            noDataText={i18n.t('No Cleared Alerts')}
            noDataImg={NoDataToDisplayHappy}
            {...routerProps()}
          />
        );
    }
  };

  render() {
    return (
      <div className="computer-tabs" style={{ justifyContent: 'flex-start' }}>
        {this.renderNav()}
        {this.renderTab()}
      </div>
    );
  }
}

export default TabsContainer;
