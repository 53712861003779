/* istanbul ignore file */
import { t } from '@kandji-inc/nectar-i18n';

/** Translations for generic actions */
export const action = {
  Add: (itemType: string) =>
    t('Add {itemType}', {
      itemType,
      _context:
        'action to create a new item, or add an existing item to something else',
    }),
  Assign: (item: string) =>
    t('Assign {item}', {
      item,
      _context: 'action to assign an item to something else',
    }),
  Create: (itemType: string) =>
    t('Create {itemType}', {
      itemType,
      _context: 'action to create a new item',
    }),
  Delete: (itemOrType: string) =>
    t('Delete {item}', {
      item: itemOrType,
      _context: 'action to delete an item',
    }),
  Duplicate: (itemOrType: string) =>
    t('Duplicate {item}', {
      item: itemOrType,
      _context: 'action to duplicate an item',
    }),
  Edit: (itemOrTypeOrProperty: string) =>
    t('Edit {item}', {
      item: itemOrTypeOrProperty,
      _context: 'action to edit an item',
    }),
  Manage: (itemOrTypeOrProperty: string) =>
    t('Manage {item}', {
      item: itemOrTypeOrProperty,
      _context: 'action to manage (review, edit) an item',
    }),
  New: (itemType: string) =>
    t('New {itemType}', {
      itemType,
      _context: 'action to create a new item',
    }),
  Search: (itemTypePlural: string) =>
    t('Search {itemTypePlural}', {
      itemTypePlural,
      _context: 'action to search for an item of a specific type',
    }),
};
