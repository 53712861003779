import React, { useState } from 'react';

import { Toaster as toaster } from '@kandji-inc/bumblebee';
import { Button, Dialog, Flex, Text, TextField } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import { unlockUser } from '../../computer/api';

const ComputerUnlockUserAccount = (props) => {
  const { info, onHide } = props;
  const { computerId } = info;

  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState();

  const handleOnUsernameChange = (e) => setUsername(e.target.value);

  const onUnlockUser = () => {
    setIsLoading(true);
    unlockUser(computerId, username)
      .then(() => {
        onHide();
        setIsLoading(false);
        toaster(i18n.t('Command initiated'));
      })
      .catch(() => {
        onHide();
        setIsLoading(false);
        toaster(i18n.error.generic());
      });
  };

  const content = (
    <Flex flow="column" gap="lg">
      <Text>
        Send a command to unlock a user account that has been locked because of
        too many failed password attempts.
      </Text>
      <TextField
        label="Enter the short name (account name) of the user you would like to unlock:"
        onChange={handleOnUsernameChange}
      />
    </Flex>
  );

  const footer = (
    <Flex gap="md" justifyContent="end" wrap="wrap">
      <Button variant="subtle" onClick={onHide}>
        Cancel
      </Button>
      <Button
        variant="primary"
        loading={isLoading}
        disabled={!username}
        onClick={onUnlockUser}
      >
        Unlock user
      </Button>
    </Flex>
  );

  return (
    <Dialog
      isOpen
      closeOnEscape
      closeOnOutsideClick
      onOpenChange={onHide}
      title="Unlock user account"
      content={content}
      footer={footer}
      css={{ zIndex: 2000 }}
    />
  );
};

export default ComputerUnlockUserAccount;
