import { Button } from '@kandji-inc/bumblebee';
import { ResetPasswordForm } from 'app/components/settings/ResetPasswordForm';
import { i18n } from 'i18n';
import { parse as parseQueryString } from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import { bindActionCreators } from 'redux';
import {
  validateResetPasswordToken as callValidateResetPasswordToken,
  resetPassword,
} from '../app/_actions/app';
import { setSnackbar as callSetSnackbar } from '../app/_actions/ui';
import history from '../app/router/history';

export class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      errorMessage: 'Invalid token',
      isPasswordChanged: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { validateResetPasswordToken, location } = this.props;
    const { token } = parseQueryString(location.search);
    this.setState({ isLoading: true }, () => {
      validateResetPasswordToken(token)
        .then(() => this.setState({ isLoading: false }))
        .catch((error) => {
          if (error.response.status === 400) {
            this.setState({ errorMessage: 'Token is expired' });
          }
          this.setState({ isLoading: false });
        });
    });
  }

  handleSubmit(form) {
    const { location } = this.props;
    const { token } = parseQueryString(location.search);
    const { setSnackbar } = this.props;
    const formData = { ...form, token };
    resetPassword(formData)
      .then(() => {
        this.setState({ isPasswordChanged: true });
      })
      .catch(() => setSnackbar(i18n.error.generic()));
  }

  onCancelClick = () => {
    history.push('/signin');
  };

  render() {
    const { tokenIsValid } = this.props;
    const { isLoading, errorMessage, isPasswordChanged } = this.state;
    return (
      <Container>
        <div
          data-loading={isLoading}
          className="white-container d-flex flew-flow-column align-items-center justify-content-start reset-password-container"
        >
          {!isLoading && tokenIsValid && !isPasswordChanged && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div className="reset-password-title">Enter New Password</div>
              <ResetPasswordForm onSubmit={this.handleSubmit} />
              <Button onClick={this.onCancelClick} kind="outline">
                Cancel
              </Button>
            </div>
          )}
          {!isLoading && !tokenIsValid && !isPasswordChanged && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div className="reset-password-title">{errorMessage}</div>
              <Button onClick={this.onCancelClick}>Return to login page</Button>
            </div>
          )}
          {!isLoading && isPasswordChanged && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div className="reset-password-title">
                Password was reset successfully!
              </div>
              <Button onClick={this.onCancelClick}>Return to login page</Button>
            </div>
          )}
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  tokenIsValid: state.app.resetPasswordTokenValidated,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      validateResetPasswordToken: callValidateResetPasswordToken,
      setSnackbar: callSetSnackbar,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
