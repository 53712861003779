/* istanbul ignore file */
import { t } from '@kandji-inc/nectar-i18n';

/** Translations for common properties */
export const property = {
  Description: (itemOrType?: string) => {
    if (itemOrType) {
      return t('{item} description', {
        item: itemOrType,
        _context: 'a property to show the description of an item',
      });
    }
    return t('Description', {
      _context: 'a property to show the description of an item',
    });
  },
  Name: (itemOrType?: string) => {
    if (itemOrType) {
      return t('{item} name', {
        item: itemOrType,
        _context: 'a property to show the name of an item',
      });
    }
    return t('Name', { _context: 'a property to show the name of an item' });
  },
};
