/* istanbul ignore file */
import { t } from '@kandji-inc/nectar-i18n';

/** Kandji proper nouns */
export const kandji = {
  Alert: () => t('Alert'),
  Alerts: () => t('Alerts'),
  AssetTag: () => t('Asset Tag'),
  AssetTags: () => t('Asset Tags'),
  AssignmentMap: () => t('Assignment Map'),
  AssignmentMaps: () => t('Assignment Maps'),
  Blueprint: () => t('Blueprint'),
  Blueprints: () => t('Blueprints'),
  ClassicBlueprint: () => t('Classic Blueprint'),
  ClassicBlueprints: () => t('Classic Blueprints'),
  LibraryItem: () => t('Library Item'),
  LibraryItems: () => t('Library Items'),
  SelfService: () => t('Self Service'),
  User: () => t('User'),
  Users: () => t('Users'),
};
