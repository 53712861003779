import { Button } from '@kandji-inc/bumblebee';
import { i18n } from 'i18n';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchAccount as callFetchAccount } from '../../../_actions/account';
import { setSnackbar as callSetSnackbar } from '../../../_actions/ui';
import { disconnectAutoEnroll } from '../../integrations/Apple/api';
import { Text } from '../Typography';
import { ModalWrapper } from './ModalWrapper';
import { ButtonsWrapper, CancelButtonWrapper } from './elements';

export class DisconnectAutoEnroll extends ModalWrapper {
  constructor(props) {
    super(props);
    this.modalName = 'DisconnectAutoEnroll';
    this.DisconnectAutoEnroll = this.DisconnectAutoEnroll.bind(this);
  }

  DisconnectAutoEnroll() {
    this.setState({ isLoading: true });
    const { setSnackbar, fetchAccount } = this.props;
    disconnectAutoEnroll()
      .then(() => {
        this.setState({ isLoading: false });
        setSnackbar('Disconnect complete.');
        fetchAccount();
        this.onHide();
      })
      .catch(() => {
        this.setState({ isLoading: false });
        setSnackbar(i18n.error.generic());
        this.onHide();
      });
  }

  renderHeader = () => 'Disconnect Automated Device Enrollment';

  renderBody = () => (
    <>
      <Text>
        Kandji will no longer manage new devices assigned through your Apple
        Business Manager account.
      </Text>
    </>
  );

  renderFooter() {
    const { isLoading } = this.state;
    return (
      <ButtonsWrapper>
        <CancelButtonWrapper>
          <Button kind="link" theme="dark" onClick={this.onHide}>
            Cancel
          </Button>
        </CancelButtonWrapper>
        <Button
          theme="dark"
          onClick={this.DisconnectAutoEnroll}
          disabled={isLoading}
        >
          {isLoading ? 'Disconnecting...' : 'Disconnect'}
        </Button>
      </ButtonsWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSnackbar: callSetSnackbar,
      fetchAccount: callFetchAccount,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(DisconnectAutoEnroll);
