import { MultiSelect } from '@kandji-inc/nectar-ui';
import debounce from 'lodash/debounce';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import type { RegisterOptions } from 'react-hook-form';

import type {
  AllowedSelectValue,
  MultiSelectValue,
  SelectOptionMemberList,
  SelectSize,
} from '@kandji-inc/nectar-ui';

type Props = {
  name: string;
  options: SelectOptionMemberList<AllowedSelectValue, 'value', 'label'>;
  defaultValue?: MultiSelectValue<string>;
  onChange?: (args?: any) => Promise<any> | void;
  handleSearch?: (args?: any) => Promise<any> | void;
  debounceTime?: number;
  validations?: RegisterOptions;
  size?: SelectSize;
  label?: string;
  hint?: string;
};

const ViewMultiSelect = ({
  name,
  options,
  defaultValue = [],
  onChange,
  handleSearch,
  debounceTime,
  validations,
  size = 'md',
  label,
  hint,
}: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={validations}
      defaultValue={defaultValue}
      render={({ field }) => (
        <MultiSelect
          {...field}
          data-testid="multi-select"
          hint={{ label: hint || '' }}
          size={size}
          label={label}
          options={options}
          value={field.value}
          onChange={(value, meta) => {
            // This is for the original field on Change behavior. A callback with
            // the IDs of values selected.
            if (field.onChange) field.onChange(value);

            // A callback with the actual options selected returned as a list
            // of objects.
            if (onChange) onChange(meta.selectedMultiOptions);
          }}
          searchable
          searchFn={debounce(handleSearch, debounceTime)}
          footer={{
            handleClear: () => field.onChange([]),
          }}
          error={errors[name]?.message}
        />
      )}
    />
  );
};

export default ViewMultiSelect;
