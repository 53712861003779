import { Button, Toaster as toaster } from '@kandji-inc/bumblebee';
import debounce from 'lodash/debounce';
import React, { useState, useCallback } from 'react';
import { Setting } from 'src/features/library-items/template';
import './category-table.css';
import { i18n } from 'i18n';
import DeleteModal from './delete-modal';
import Table from './table';

const defaultDeleting = {
  is: false,
  data: {},
  onDelete: null,
  conflicts: [],
  fetching: false,
};
const CategoryTable = (props) => {
  const {
    model,
    onAdd,
    onEdit,
    onEditRecommended,
    onEditAll,
    update,
    getConflicts,
    onDelete,
    patch,
  } = props;
  const [isDeleting, setIsDeleting] = useState({ ...defaultDeleting });

  const reorder = useCallback(
    debounce(
      (model) => patch(model).catch(() => toaster(i18n.error.generic())),
      150,
    ),
    [],
  );

  return (
    <>
      <Setting.Card>
        <Setting.Header>
          <h3 className="b-h3">Categories</h3>
        </Setting.Header>
        <Setting.SubHeader>
          <p className="b-txt">
            Create and organize categories for Self Service below. The
            Recommended and All sections will always display in Self Service.
          </p>
        </Setting.SubHeader>
        <Table
          data={model}
          onEdit={onEdit}
          onEditRecommended={onEditRecommended}
          onEditAll={onEditAll}
          isDeleting={isDeleting}
          onReorder={(categories) => {
            update((prev) => {
              reorder({ ...prev, categories });
              return { ...prev, categories };
            });
          }}
          onDelete={(data) => {
            setIsDeleting((prev) => ({ ...prev, data, fetching: true }));
            return getConflicts(data.id)
              .then((conflicts) =>
                conflicts.length
                  ? setIsDeleting({
                      is: true,
                      data,
                      conflicts,
                      fetching: false,
                    })
                  : onDelete(data.id).then(() =>
                      setIsDeleting({ ...defaultDeleting }),
                    ),
              )
              .catch(() => setIsDeleting({ ...defaultDeleting }));
          }}
        />
        <div className="k-self-service-category__footer">
          <Button kind="link" icon="circle-plus" onClick={onAdd}>
            Add category
          </Button>
        </div>
      </Setting.Card>
      {isDeleting.is && (
        <DeleteModal
          isDeleting={isDeleting}
          model={model}
          onClose={() => setIsDeleting({ ...defaultDeleting })}
          onDelete={(reassignTo) =>
            onDelete(isDeleting.data.id, reassignTo).then(() =>
              setIsDeleting({ ...defaultDeleting }),
            )
          }
        />
      )}
    </>
  );
};

export default CategoryTable;
