import React from 'react';

import {
  Box,
  Button,
  Card,
  Flex,
  Grid,
  Heading,
  Icon,
  Text,
} from '@kandji-inc/nectar-ui';

import { i18n } from 'i18n';
import { getRatingLocks, getSecurityRatingDescription } from '../../helpers';

type TemplateDetailsProps = {
  template: any;
};

const TemplateDetails = (props: TemplateDetailsProps) => {
  const { template } = props;

  const { name, description, params } = template;

  const itemsCount = Object.keys(params)?.length;

  return (
    <Flex gap="lg" flow="column">
      <Flex gap="md" alignItems="center">
        <Box
          css={{
            padding: '$2',
            borderRadius: '50%',
            backgroundColor: 'var(--colors-blue10)',
          }}
        >
          <Icon name="kandji-blueprint" color="var(--colors-blue60)" />
        </Box>
        <Heading size="4" css={{ fontWeight: '$medium' }}>
          {name}
        </Heading>
      </Flex>
      <Flex flow="column" gap="xs">
        <Text variant="description">
          {getSecurityRatingDescription(itemsCount)}
        </Text>
        <Flex gap="sm" alignItems="center">
          {getRatingLocks(itemsCount, 'lg')}
          <Text size={1} variant="description">
            {i18n.counts.items(itemsCount)}
          </Text>
        </Flex>
      </Flex>
      <Text variant="description">{description}</Text>
    </Flex>
  );
};

export default TemplateDetails;
