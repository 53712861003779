import { getSubDomain } from 'app/api/domains';
import { getAuthToken, hasAuthToken } from 'app/auth';
import axiosBase from 'axios';
import { i18n } from 'i18n';
import { getAuth0Client } from 'src/auth0';
import * as constants from '../../constants';

const ping = async (url) => {
  try {
    const response = await axiosBase.get(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return (await response.status) === 200;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const checkSubDomainExists = (location) => {
  const subDomain = getSubDomain(location.hostname);
  if (constants.REACT_APP_DEBUG) {
    return Promise.resolve();
  }
  if (subDomain) {
    return ping(
      `${window.location.protocol}//${subDomain}.${constants.REACT_APP_BACKEND_BASE_DOMAIN}/app/v1/ping`,
    );
  }
  return Promise.reject(new Error(i18n.error.generic()));
};

export const nextUniqueID = (() => {
  let counter = 0;
  return () => counter++;
})();

/* istanbul ignore next - legacy code, moved */
export const getAuthHeader = async () => {
  // kandji admin headers
  if (hasAuthToken()) {
    const token = getAuthToken();
    if (token.startsWith('cat-')) {
      return {
        Authorization: `CompanyAccessToken ${token.substring(4)}`,
      };
    }
    return {
      Authorization: `Token ${token}`,
    };
  }
  // auth0 headers
  try {
    const accessToken = await getAuth0Client().getTokenSilently();
    return {
      Authorization: `BearerAuth0 ${accessToken}`,
    };
  } catch (e) {
    return {};
  }
};
