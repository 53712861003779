/* istanbul ignore file */
import { t } from '@kandji-inc/nectar-i18n';

/** Translations for common validations */
export const validation = {
  required: () =>
    t('Required', { _context: 'error message for a required field' }),
  maxLength: (max: number) =>
    t('Must be no more than {max} characters', {
      max,
      _context: 'error message when a field exceeds the maximum length',
    }),
};
