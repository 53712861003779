/* istanbul ignore file */
import { t } from '@kandji-inc/nectar-i18n';

/** Translations for common buttons */
export const button = {
  Apply: () => t('Apply', { _context: 'a button to apply changes' }),
  Back: () => t('Back', { _context: 'a button to go back a step or page' }),
  Cancel: () => t('Cancel', { _context: 'a button to cancel an action' }),
  Close: () => t('Close', { _context: 'a button to close a modal or dialog' }),
  Confirm: () => t('Confirm', { _context: 'a button to confirm an action' }),
  Delete: () => t('Delete', { _context: 'a button to delete an item' }),
  Duplicate: () =>
    t('Duplicate', { _context: 'a button to duplicate an item' }),
  Save: () => t('Save', { _context: 'a button to save changes' }),
};
