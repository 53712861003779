import {
  Box,
  Button,
  Chip,
  Dialog,
  Flex,
  ScrollArea,
  Text,
  TextField,
  Toaster_UNSTABLE as Toaster,
  Tooltip,
  useToast_UNSTABLE as useToast,
} from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import React, { useContext, useState } from 'react';
import { UserRoles } from 'src/app/common/constants';
import useAccount from 'src/contexts/account';
import { InterfaceContext } from 'src/contexts/interface';
import { useTags } from 'src/features/tags';
import TagActions from 'src/features/tags/TagActions';
import TagRuleFinder from './TagRuleFinder';

const ManageTagsModal = (props: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}) => {
  const { isOpen, setIsOpen } = props;

  const [searchTerm, setSearchTerm] = useState('');

  const { getTags, createTag } = useTags();

  const { data: tagsData, refetch: refetchTags } = getTags(searchTerm);

  const SIDEBAR_DOCKED_OFFSET = 256;
  const SIDEBAR_CLOSE_OFFSET = 78;
  const { sidebarDocked } = useContext(InterfaceContext);
  const { userRole } = useAccount();
  const isAuditor = userRole === UserRoles.auditor;

  const [newTagName, setNewTagName] = useState<string>('');
  const [newTagError, setNewTagError] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAddingTag, setIsAddingTag] = useState(false);
  const { toast } = useToast();

  const displayToast = (title, variant) =>
    toast({
      title,
      variant,
      style: {
        left: /* istanbul ignore next */ sidebarDocked
          ? `${SIDEBAR_DOCKED_OFFSET + 12}px`
          : `${SIDEBAR_CLOSE_OFFSET + 12}px`,
        bottom: '12px',
        position: 'absolute',
      },
    });

  const onClose = /* istanbul ignore next */ () => {
    setIsOpen(false);
    setSearchTerm('');
    setIsAddingTag(false);
  };

  const onCancel = /* istanbul ignore next */ () => {
    onClose();
  };

  const onCreateTag = (tag: string) => {
    setIsSubmitting(true);
    createTag({ name: tag?.trim() })
      .catch(
        /* istanbul ignore next */ (error) => {
          if (error?.response?.data?.name) {
            setNewTagError(error.response.data.name.join(' '));
          } else {
            setNewTagError('Failed saving, please try again.');
          }
          setIsSubmitting(false);
        },
      )
      .then((result) => {
        setIsSubmitting(false);
        /* istanbul ignore else */
        if (result) {
          displayToast(
            `Tag ${result?.data?.name} created successfully`,
            'success',
          );
          refetchTags();
          setNewTagName('');
          setIsAddingTag(false);
        }
      });
  };

  const content = (
    <ScrollArea
      id="tags-scroll"
      css={{ maxHeight: '300px', paddingRight: 'var(--sizes-4)' }}
    >
      <Flex flow="column" gap="md" css={{ height: '300px' }}>
        <Box>
          <TextField
            compact
            icon="magnifying-glass"
            showClearButton={searchTerm.length > 0}
            onClear={/* istanbul ignore next */ () => setSearchTerm('')}
            placeholder={i18n.action.Search(i18n.common.tags())}
            value={searchTerm}
            maxLength={50}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            data-testid="search-tags-input"
          />
        </Box>
        <Flex css={{ width: '180px' }}>
          {!isAddingTag && (
            <Button
              icon={{ name: 'plus' }}
              compact
              variant="subtle"
              onClick={() => setIsAddingTag(true)}
              disabled={isAuditor}
              data-testid="add-tag-button"
            >
              {i18n.action.Add(i18n.common.tag())}
            </Button>
          )}
          {isAddingTag && (
            <form
              onSubmit={(e) => {
                e?.preventDefault();
                onCreateTag(newTagName);
              }}
              method="POST"
              data-testid="add-tag-form"
            >
              <TextField
                compact
                autoFocus
                placeholder="Enter tag"
                disabled={isSubmitting}
                value={newTagName}
                state={
                  /* istanbul ignore next */ newTagError ? 'error' : 'default'
                }
                maxLength={50}
                hint={
                  /* istanbul ignore next */ newTagError
                    ? { label: newTagError, variant: 'error' }
                    : {}
                }
                onChange={(e) => {
                  setNewTagName(e.target.value);
                  // Clear the error when the user starts typing again
                  /* istanbul ignore next */
                  if (newTagError) {
                    setNewTagError('');
                  }
                }}
                onKeyDown={
                  /* istanbul ignore next */ (e) => {
                    if (e.key === ',') {
                      e.preventDefault();
                    }

                    if (newTagName === '' && e.key === ' ') {
                      e.preventDefault();
                    }
                  }
                }
                data-testid="add-tag-input"
              />
            </form>
          )}
        </Flex>

        {tagsData?.results?.length === 0 && searchTerm === '' && (
          <Flex justifyContent="center" alignItems="center" css={{ flex: 1 }}>
            <Text variant="description" size="1">
              No tags created. Add tags to see them here.
            </Text>
          </Flex>
        )}

        {tagsData?.results?.length === 0 && searchTerm !== '' && (
          <Flex justifyContent="center" alignItems="center" css={{ flex: 1 }}>
            <Text variant="description" size="1">
              No tags found.
            </Text>
          </Flex>
        )}

        <Flex flow="column" gap="md" justifyContent="center">
          {tagsData?.results?.map(
            ({
              name,
              id,
              computer_count: computerCount,
              is_used_in_rule: isUsedInRule,
            }) => (
              <Flex flow="row" gap="xs" justifyContent="space-between">
                <Chip
                  label={name}
                  title={name}
                  css={{
                    display: 'inline-block',
                    maxWidth: '300px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                />
                <Flex
                  justifyContent="end"
                  alignItems="center"
                  css={{ gap: '6px' }}
                >
                  <Text variant="description" size="1">
                    {i18n.counts.devices(computerCount)}
                  </Text>
                  <TagRuleFinder id={id} />
                  <TagActions
                    name={name}
                    id={id}
                    computerCount={computerCount}
                    isUsedInRule={isUsedInRule}
                    isUserAuditor={isAuditor}
                    refetchTags={refetchTags}
                  />
                </Flex>
              </Flex>
            ),
          )}
        </Flex>
      </Flex>
    </ScrollArea>
  );

  const footer = (
    <Flex gap="md" justifyContent="end">
      <Button variant="primary" onClick={onCancel}>
        {i18n.button.Close()}
      </Button>
    </Flex>
  );

  return (
    <>
      <Dialog
        isOpen={isOpen}
        closeOnEscape
        closeOnOutsideClick
        onOpenChange={onClose}
        title={i18n.action.Manage(i18n.common.tags())}
        content={content}
        footer={footer}
        css={{
          width: '480px',
        }}
      />
      <Toaster />
    </>
  );
};

export default ManageTagsModal;
