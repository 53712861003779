/* istanbul ignore file */
import { t } from '@kandji-inc/nectar-i18n';

/** Translations for major sections/features of the app */
export const section = {
  Activity: () =>
    t('Activity', {
      _context:
        'a section that shows an activity log of actions made by users or devices',
    }),
  Alerts: () =>
    t('Alerts', {
      _context: 'a section that shows alerts or warnings from devices',
    }),
  Blueprints: () =>
    t('Blueprints', {
      _context: 'a section to configuration settings for a device to execute',
    }),
  Devices: () =>
    t('Devices', {
      _context: 'a section to show all devices enrolled in the application',
    }),
  Enrollment: () =>
    t('Enrollment', {
      _context:
        'a section to manage the enrollment of devices to the application',
    }),
  Integrations: () =>
    t('Integrations', {
      _context: 'a section to manage integrations with other applications',
    }),
  Library: () =>
    t('Library', {
      _context: 'a section to manage items to install on devices',
    }),
  Resources: () =>
    t('Resources', {
      _context: 'a section that provides helpful resources to learn more',
    }),
  Settings: () =>
    t('Settings', {
      _context:
        'a section to manage configurable settings for this application',
    }),
  Threats: () =>
    t('Threats', {
      _context: 'a section that shows security threats on devices',
    }),
  Users: () => t('Users', { _context: 'a section that shows company users' }),
};
