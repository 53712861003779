/* istanbul ignore file */
import { getI18nLocale, render, t, ut } from '@kandji-inc/nectar-i18n';

import { action } from './action';
import { apple } from './apple';
import { button } from './button';
import { common } from './common';
import { counts } from './counts';
import { error } from './error';
import { kandji } from './kandji';
import { property } from './property';
import { section } from './section';
import { validation } from './validation';

export const i18n = {
  getLocale: getI18nLocale, // wrapped to consolidate imports
  render, // wrapped to consolidate imports
  t, // wrapped to consolidate imports
  ut, // wrapped to consolidate imports
  action,
  apple,
  button,
  common,
  counts,
  error,
  kandji,
  property,
  section,
  validation,
};
