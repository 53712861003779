import './style.css';

import { Tabs } from '@kandji-inc/bumblebee';
import React from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import useScrollToTop from 'src/hooks/useScrollToTop';

import { Library } from 'features/library-items/template';

import featureFlags from '../../config/feature-flags';

import InviteUser from '../../features/invite-user';

import { i18n } from 'i18n';
import Access from './tab-access';
import General from './tab-general';
import Integrations from './tab-integrations';
import Licensing from './tab-licensing';
import SelfService from './tab-self-service';

export const settingsSubsections = {
  General: () =>
    i18n.t('General', {
      _context: 'a settings page to manage general settings',
    }),
  AppleIntegrations: () =>
    i18n.t('Apple Integrations', {
      _context: 'a settings page to manage integrations with Apple services',
    }),
  SelfService: () =>
    i18n.t('Self Service', {
      _context: 'a settings page to manage self-service application settings',
    }),
  Access: () =>
    i18n.t('Access', {
      _context:
        'a settings page to manage admin user access to the application',
    }),
  Licensing: () =>
    i18n.t('Licensing', {
      _context:
        'a settings page to view licensing and billingfor the application',
    }),
};

export default () => {
  const showLicensing = featureFlags.getFlag('kit_092022_stripe_billing');
  const { pathname } = useLocation();
  const { path } = useRouteMatch();

  const isAccess = pathname === `${path}/access`;

  const tabs = [
    {
      label: settingsSubsections.General(),
    },
    {
      label: settingsSubsections.AppleIntegrations(),
      route: 'integrations',
    },
    {
      label: settingsSubsections.SelfService(),
      route: 'self-service',
    },
    {
      label: settingsSubsections.Access(),
      route: 'access',
    },
    ...(showLicensing
      ? [
          {
            label: settingsSubsections.Licensing(),
            route: 'licensing',
          },
        ]
      : []),
  ];
  useScrollToTop();

  return (
    <Library style={{ minHeight: '1600px' }} className="k-settings">
      <h1 className="b-h1 b-mb2 b-flex-btw">
        {i18n.section.Settings()}
        {isAccess && <InviteUser />}
      </h1>
      <Tabs className="k-settings-tabs" tabs={tabs}>
        <General tabid={settingsSubsections.General()} />
        <Integrations tabid={settingsSubsections.AppleIntegrations()} />
        <SelfService tabid={settingsSubsections.SelfService()} />
        {showLicensing && <Licensing tabid={settingsSubsections.Licensing()} />}
        <Access tabid={settingsSubsections.Access()} />
      </Tabs>
    </Library>
  );
};
