import { Button } from '@kandji-inc/bumblebee';
import useUniqValue from 'components/common/hooks/use-uniq-value/use-uniq-value';
import { AccountContext, usePermissions } from 'contexts/account';
import { EnvironmentContext } from 'contexts/environment';
import { Whoops404 } from 'pages/404';
/* istanbul ignore file */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import featureFlags from 'src/config/feature-flags';
import NoLibrarySVG from '../../../assets/img/library/noLibraryItems.svg';
import Loader from '../../../theme/components/atoms/Loader';
import { newLibraryItemService } from '../data-service/library-item/new-library-item-service';
import { transformLibraryItems } from './api/transformer';
import { categories, getFilteredItems, libraryRoute } from './common';
import LibraryFilter from './library-filter/library-filter';
import useDebouncedFilter from './library-filter/use-debounced-filter';
import LibraryList from './library-list/library-list';
import './library.css';
import { i18n } from 'i18n';

const defaultFilters = {
  searchTerm: '',
  devices: [],
  category: '',
};

export const libraryItemsOrder = (isThreatEnabled) => [
  categories.IN_HOUSE_APPS,
  categories.APP_STORE,
  categories.ENROLLMENT,
  categories.OPERATING_SYSTEMS,
  categories.MDM_MACOS_RELEASES,
  categories.IOS_RELEASES,
  categories.IPADOS_RELEASES,
  categories.TVOS_RELEASES,
  categories.AUTO_APPS,
  categories.CUSTOM_APPS,
  categories.CUSTOM_SCRIPTS,
  categories.CUSTOM_PRINTERS,
  ...(featureFlags.getFlag('lit-bookmarks-library-item')
    ? [categories.BOOKMARKS]
    : []),
  categories.PROFILES,
  ...(isThreatEnabled ? [categories.SECURITY] : []),
];

const Library = () => {
  const account = useContext(AccountContext);
  const environment = useContext(EnvironmentContext);
  const permissions = usePermissions();
  const isThreatEnabled =
    account?.currentCompany?.feature_configuration?.edr?.enabled;
  const [libraryItems, setLibraryItems] = useState(null);
  const [libraryErr, setLibraryErr] = useState(false);
  const [itemFilters, setItemFilters] = useState({ ...defaultFilters });
  const [loadItemsDep, reloadItems] = useUniqValue();

  const libraryItemsOrdering = useMemo(
    () => libraryItemsOrder(isThreatEnabled),
    [isThreatEnabled],
  );

  const filtered = useDebouncedFilter(
    [itemFilters],
    libraryItems,
    (items) =>
      getFilteredItems(items, itemFilters, libraryItemsOrdering, (i) =>
        i.defaultConfiguration.isHidden({ account, environment }),
      ),
    200,
  );

  useEffect(() => {
    newLibraryItemService
      .v2List()
      .then((listResult) => {
        const { data } = listResult;
        setLibraryItems(
          transformLibraryItems(data, { environment, account }, true),
        );
        newLibraryItemService.v2Counts().then((countResult) => {
          const dataAsObj = Object.fromEntries(data.map((li) => [li.id, li]));
          countResult.data.forEach(([id, counts]) => {
            dataAsObj[id].counts = counts;
          });
          setLibraryItems(
            transformLibraryItems(
              Object.values(dataAsObj),
              {
                environment,
                account,
              },
              false,
            ),
          );
        });
      })
      .catch(() => setLibraryErr(true));
  }, [loadItemsDep]);

  const filteredLibraryList = useMemo(() => {
    if (libraryItems && !libraryItems.filter((d) => d.data.length).length) {
      return (
        <div className="b-flex-vc b-flex-col">
          <ReactSVG src={NoLibrarySVG} className="b-mb3" />
          <h2 className="b-h2 b-mb1">No Library Items</h2>
          <p
            className="b-txt"
            style={{
              maxWidth: '950px',
              textAlign: 'center',
            }}
          >
            Library Items can be configured and assigned to any Blueprint.
            Examples of a Library Item include App Store apps, Auto Apps, custom
            profiles, custom apps, custom scripts, custom printers and other
            configuration profiles.{' '}
            {permissions.canManageBlueprints && 'Get started below.'}
          </p>
          {permissions.canManageBlueprints && (
            <Button
              href={libraryRoute.addLibrary}
              icon="circle-plus"
              className="b-mt1"
              isWide
              kind="link"
              linkComponent={Link}
            >
              Add Library Item
            </Button>
          )}
        </div>
      );
    }

    if (!filtered) {
      return <Loader type={Loader.types.LINE} />;
    }

    return (
      <LibraryList
        reloadItems={reloadItems}
        items={filtered}
        filters={itemFilters}
      />
    );
  }, [filtered, libraryItems]);

  if (libraryErr) {
    return <Whoops404 />;
  }

  if (!libraryItems) {
    return <Loader type={Loader.types.LINE} />;
  }

  return (
    <div className="li-library-items">
      <div className="b-flex-btw b-mb3">
        <h1 className="b-h1">{i18n.section.Library()}</h1>
        {permissions.canManageBlueprints && (
          <Button
            className="pendo-add-library-items"
            icon="circle-plus"
            isWide
            href={libraryRoute.addLibrary}
            linkComponent={Link}
          >
            Add new
          </Button>
        )}
      </div>
      <LibraryFilter
        className="li-mb-40"
        onFilter={(category, devices, searchTerm) =>
          setItemFilters({ category, devices, searchTerm })
        }
        categoryOrder={libraryItemsOrdering}
        counts={libraryItemsOrdering.map((category) => ({
          category,
          count: libraryItems.find((items) => items.category === category)?.data
            .length,
        }))}
        showCounts
      />
      {filteredLibraryList}
    </div>
  );
};

export default Library;
