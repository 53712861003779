import { Button, modal as Modal, TextInput } from '@kandji-inc/bumblebee';
import { i18n } from 'i18n';
import React, { useEffect, useRef, useState } from 'react';
import { IconDisplay, IconSelector } from '../icon-select';

const EditBlueprint = (props) => {
  const { onClose, onEdit, blueprint } = props;
  const animate = useRef(true);
  const MAX_NAME_LEN = 100;
  const [name, setName] = useState(blueprint.name);
  const [description, setDescription] = useState(blueprint.description);
  const [isLoading, setIsLoading] = useState(false);
  const [isSelectingIcon, setIsSelectingIcon] = useState(false);
  const [newSetting, setNewSetting] = useState({
    icon: blueprint.icon,
    color: blueprint.color,
  });

  useEffect(() => {
    animate.current = false;
  }, []);

  if (isSelectingIcon) {
    return (
      <IconSelector
        onClose={onClose}
        blueprint={blueprint}
        icon={newSetting.icon}
        color={newSetting.color}
        onBack={(icon, color) => {
          setNewSetting({ icon, color });
          setIsSelectingIcon(false);
        }}
      />
    );
  }

  return (
    <Modal
      isOpen
      onClose={onClose}
      animationDuration={animate.current ? 150 : 0}
      disableCloseOnOutsideClick
      classes={{
        root: 'bl-blueprints-edit__modal',
      }}
      parts={{
        header: {
          text: i18n.action.Edit(i18n.kandji.Blueprint()),
        },
        content: {
          children: (
            <div className="b-flex-vg">
              <div className="b-flex-vc">
                <IconDisplay
                  onEdit={() => setIsSelectingIcon(true)}
                  icon={newSetting.icon}
                  color={newSetting.color}
                />
              </div>
              <TextInput
                autoFocus
                fieldsGrid
                label={i18n.property.Name(i18n.kandji.Blueprint())}
                placeholder={i18n.property.Name(i18n.kandji.Blueprint())}
                value={name}
                onChange={(e) => {
                  const val = e.target.value;
                  if (val.length <= MAX_NAME_LEN) {
                    setName(val);
                  }
                }}
              />
              <TextInput
                textArea
                fieldsGrid
                label={i18n.property.Description(i18n.kandji.Blueprint())}
                placeholder={i18n.property.Description(i18n.kandji.Blueprint())}
                value={description}
                onChange={(e) => {
                  const val = e.target.value;
                  setDescription(val);
                }}
              />
            </div>
          ),
        },
        footer: {
          children: (
            <div className="b-flex-g">
              <Button disabled={isLoading} kind="outline" onClick={onClose}>
                {i18n.button.Cancel()}
              </Button>
              <Button
                disabled={isLoading || !name}
                isProgress={isLoading}
                icon={isLoading ? 'arrows-rotate' : ''}
                onClick={() => {
                  setIsLoading(true);
                  return onEdit(
                    name,
                    description,
                    newSetting.icon,
                    newSetting.color,
                  )
                    .then(onClose)
                    .catch((e) => {
                      setIsLoading(false);
                      console.error('Failed to save', e);
                    });
                }}
              >
                {i18n.button.Save()}
              </Button>
            </div>
          ),
        },
      }}
    />
  );
};

export default EditBlueprint;
