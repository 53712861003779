import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Box, Dialog, Text } from '@kandji-inc/nectar-ui';

import { i18n } from 'i18n';
import useBlueprint from '../../services/use-blueprint';
import Footer from './footer';
import FromScratch from './from-scratch';
import FromTemplate from './from-template';
import Step1 from './step-1';
import TemplateDetails from './template-details';

export const STEP_ONE = 'STEP_ONE';
export const TEMPLATE_DETAILS = 'TEMPLATE_DETAILS';
export const FROM_SCRATCH = 'FROM_SCRATCH';
export const FROM_TEMPLATE = 'FROM_TEMPLATE';

function CreateModal(props: { onClose: () => void; templates: any }) {
  const { onClose, templates } = props;

  const [currentStep, setCurrentStep] = useState(STEP_ONE);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isCreating, setIsCreating] = useState(false);

  const { create, isLoading } = useBlueprint();

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      blueprintName: '',
      blueprintDescription: '',
    },
  });

  const onCancel = /* istanbul ignore next */ () => {
    onClose();
  };

  const onSelectStartingPoint = (startingPoint: string, template) => {
    if (startingPoint === 'from-scratch') {
      setCurrentStep(FROM_SCRATCH);
    } else {
      setSelectedTemplate(template);
      setCurrentStep(FROM_TEMPLATE);
    }
  };

  const backToStepOne = /* istanbul ignore next */ () => {
    setSelectedTemplate(null);
    setCurrentStep(STEP_ONE);
  };

  const onSelectViewDetails = (template) => {
    setSelectedTemplate(template);
    setCurrentStep(TEMPLATE_DETAILS);
  };

  const goToFromTemplate = /* istanbul ignore next */ () => {
    setCurrentStep(FROM_TEMPLATE);
  };

  const handleCreate = (blueprint) => {
    setIsCreating(true);
    // @ts-expect-error
    create(blueprint).then(({ error }) => {
      setIsCreating(false);
      /* istanbul ignore next */
      if (error) {
        methods.setError('blueprintName', { type: 'unique', message: error });
      }
    });
  };

  const modalContent = {
    [STEP_ONE]: {
      Component: (
        <Step1
          templates={templates}
          onSelectStartingPoint={onSelectStartingPoint}
          onSelectViewDetails={onSelectViewDetails}
        />
      ),
      footer: null,
    },
    [TEMPLATE_DETAILS]: {
      Component: <TemplateDetails template={selectedTemplate} />,
      footer: (
        <Footer
          onBack={backToStepOne}
          onNext={goToFromTemplate}
          onCancel={onCancel}
          nextLabel={i18n.t('Use this template')}
          isSaveDisabled={false}
        />
      ),
    },
    [FROM_SCRATCH]: {
      Component: <FromScratch />,
      footer: (
        <Footer
          onBack={backToStepOne}
          onNext={() =>
            handleCreate({
              name: methods.getValues('blueprintName'),
              description: methods.getValues('blueprintDescription'),
              type: 'flow',
            })
          }
          onCancel={onCancel}
          nextLabel={i18n.action.Create(i18n.kandji.Blueprint())}
          isSaveDisabled={
            !methods.formState?.isValid || isLoading || isCreating
          }
        />
      ),
    },
    [FROM_TEMPLATE]: {
      Component: <FromTemplate template={selectedTemplate} />,
      footer: (
        <Footer
          onBack={backToStepOne}
          onNext={
            /* istanbul ignore next*/ () =>
              handleCreate({
                name: methods.getValues('blueprintName'),
                description: methods.getValues('blueprintDescription'),
                type: 'flow',
                source: {
                  id: selectedTemplate.id,
                  type: 'template',
                },
              })
          }
          onCancel={onCancel}
          nextLabel={i18n.action.Create(i18n.kandji.Blueprint())}
          isSaveDisabled={!methods.formState?.isValid || isCreating}
        />
      ),
    },
  };

  return (
    <Dialog
      title={i18n.action.Create(i18n.kandji.Blueprint())}
      content={
        <FormProvider {...methods}>
          <Box
            css={{
              padding: '0 $3 0 $1',
              height: '485px',
              overflowY: 'scroll',
            }}
          >
            <Text
              variant="description"
              css={{
                marginBottom: '$6',
              }}
            >
              {i18n.t(
                'Start from scratch, or choose a pre-filled template with our recommended Library Items and Parameters based on your security needs.',
              )}
            </Text>
            {modalContent[currentStep].Component}
          </Box>
        </FormProvider>
      }
      footer={modalContent[currentStep].footer}
      isOpen
      onOpenChange={onCancel}
      css={{ width: '860px', zIndex: 2000 }}
    />
  );
}

export default CreateModal;
