import { i18n } from 'i18n';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setModal } from '../_actions/ui';
import history from '../router/history';
import TabsContainer from './alerts/TabsContainer';
import { TableHeader } from './interface/Base';

export class AlertsList extends React.Component {
  constructor(props) {
    super(props);
    if (!props.match.params.tab) {
      history.replace(
        '/alerts/active?status[]=WARNING,ERROR&displayType=LAST_CHECKIN',
      );
    }
  }

  render() {
    const { location, match, history } = this.props;
    const routerProps = () => ({
      location,
      match,
      history,
    });
    return (
      <div>
        <TableHeader title={i18n.section.Alerts()} sticky />
        <TabsContainer {...routerProps()} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isComputersFetched: state.data.fetched.computers,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setModal,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AlertsList);
