import {
  Banner,
  Button,
  modal as Modal,
  Toaster as toaster,
} from '@kandji-inc/bumblebee';
import { bool, func, number, string } from 'prop-types';
import React from 'react';

import SemiBold from 'features/util/components/semi-bold';
import { wAmount } from 'features/util/misc';
import { i18n } from 'i18n';
import useDeleteSlackIntegration from '../../../../slack/hooks/use-delete-slack-integration';
import { CONFIG_SHAPE } from '../../util';

function DeleteIntegrationModal({
  isOpen,
  onClose,
  name,
  id,
  notificationsAmount,
  isLast,
  config,
}) {
  const deleteSlackIntegrationMutation = useDeleteSlackIntegration();

  function onDeleteClick() {
    deleteSlackIntegrationMutation
      .mutateAsync(id)
      .then(() => {
        toaster(
          `${name} ${config.integrationAlias} has been deleted.${
            isLast
              ? ` ${config.title} will no longer display as an active integration.`
              : ''
          }`,
        );

        onClose();
      })
      .catch((e) => {
        console.log(e);
        toaster(i18n.error.generic());
      });
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      withoutCloseButton
      parts={{
        header: { text: `Delete ${config.title} ${config.integrationAlias}` },
        content: {
          children: (
            <>
              <p className="b-mb">Are you sure you want to delete {name}?</p>
              <p className="b-mb">
                Deleting this integration will remove any event notifications
                that have been configured under this {config.integrationAlias}
                {isLast
                  ? `, and remove the integration with ${config.title} from your Kandji instance`
                  : ''}
                . This action cannot be undone.
              </p>
              {!!notificationsAmount && (
                <Banner
                  theme="info"
                  kind="block"
                  // TODO: icon='bell-circle-filled' [INT-284]
                  icon="bell"
                  style={{ padding: 'var(--b-gap)' }}
                >
                  <p>
                    <SemiBold>
                      {wAmount(notificationsAmount, 'event notification')}
                    </SemiBold>{' '}
                    will also be removed
                  </p>
                </Banner>
              )}
            </>
          ),
        },
        footer: {
          children: (
            <>
              <Button kind="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button
                className="b-ml"
                theme="error"
                onClick={onDeleteClick}
                disabled={deleteSlackIntegrationMutation.isLoading}
                isProgress={deleteSlackIntegrationMutation.isLoading}
              >
                Delete
              </Button>
            </>
          ),
        },
      }}
    />
  );
}

DeleteIntegrationModal.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  name: string,
  id: number,
  notificationsAmount: number,
  isLast: bool.isRequired,
  config: CONFIG_SHAPE.isRequired,
};

DeleteIntegrationModal.defaultProps = {
  name: null,
  id: null,
  notificationsAmount: null,
};

export default DeleteIntegrationModal;
