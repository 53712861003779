import type { AxiosResponse } from 'axios';
import { useCallback, useEffect, useReducer } from 'react';

import { i18n } from 'i18n';
import type {
  ThreatPaginatedRequestParams,
  ThreatPaginatedResponse,
} from '../threat.types';

type ThreatParams = [
  deps: [ThreatPaginatedRequestParams],
  apiRequest: (
    requestArgs: ThreatPaginatedRequestParams,
  ) => Promise<AxiosResponse<ThreatPaginatedResponse>>,
  options: { enabled?: boolean; onError?: (errorMsg: string) => void },
];

type ThreatState = {
  data: ThreatPaginatedResponse | null;
  error: string | null;
  isLoading: boolean;
};

type ThreatAction =
  | {
      type: typeof THREAT_ACTIONS.START_REQUEST;
    }
  | {
      type: typeof THREAT_ACTIONS.FINISH_REQUEST;
      payload: ThreatPaginatedResponse;
    }
  | {
      type: typeof THREAT_ACTIONS.CAUGHT_ERROR;
      payload: string;
    };

type UseThreatService = (...params: ThreatParams) => {
  refetch: () => void;
} & ThreatState;

const INITIAL_THREAT_STATE = {
  data: null,
  error: null,
  isLoading: false,
} as const;

const THREAT_ACTIONS = {
  START_REQUEST: 'startRequest',
  FINISH_REQUEST: 'finishRequest',
  CAUGHT_ERROR: 'caughtError',
} as const;

function threatReducer(state: ThreatState, action: ThreatAction) {
  switch (action.type) {
    case THREAT_ACTIONS.START_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case THREAT_ACTIONS.FINISH_REQUEST:
      return {
        ...state,
        data: action.payload,
        error: null,
        isLoading: false,
      };

    case THREAT_ACTIONS.CAUGHT_ERROR:
      return {
        ...state,
        data: null,
        error: action.payload || i18n.error.generic(),
        isLoading: false,
      };

    /* istanbul ignore next -- untestable with TS switch exhaustiveness check */
    default:
      return state;
  }
}

/**
 * Data fetching hook used to subscribe to threat API data based on provided
 * params.
 *
 * @param deps - Array of dependencies to trigger request and serve as
 *   arguments for `apiRequest`.
 * @param apiRequest - Function that returns a promise with threat response.
 *   Should be one of the methods on a stable instance of `ThreatService` or
 *   another stable/memoized function (e.g. via `useCallback` with no or
 *   selective dependencies) that matches the type signature of a
 *   `ThreatService` method.
 * @param options - Options to enable/disabled triggering of the request (e.g.
 *   disabled before redirecting to another route) and perform side effects on
 *   error.
 * @returns The `useReducer` state object with relevant request/response
 *   details: response data, error message, and loading status.
 */
const useThreatService: UseThreatService = (deps, apiRequest, options = {}) => {
  const [requestArgs] = deps;
  const { enabled = true, onError } = options;

  const [threatState, dispatch] = useReducer(
    threatReducer,
    INITIAL_THREAT_STATE,
  );

  const fetchData = useCallback(() => {
    dispatch({ type: THREAT_ACTIONS.START_REQUEST });

    apiRequest(requestArgs)
      .then(({ data }) =>
        dispatch({ type: THREAT_ACTIONS.FINISH_REQUEST, payload: data }),
      )
      .catch((err?: Error) =>
        dispatch({
          type: THREAT_ACTIONS.CAUGHT_ERROR,
          payload: err?.message,
        }),
      );
  }, [requestArgs, apiRequest]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [requestArgs, enabled, apiRequest, fetchData]);

  useEffect(() => {
    if (onError && threatState.error) {
      onError(threatState.error);
    }
  }, [threatState.error, onError]);

  return { ...threatState, refetch: fetchData };
};

export default useThreatService;
