import { useMutation, useQueryClient } from '@tanstack/react-query';
import MsDeviceComplianceService, {
  msDeviceComplianceService,
} from '../services';
import { msDeviceComplianceKeys } from './query-key-factory';

const useAddDevicePlatform = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ formInputs }) => {
      const platformGroupPolicies = {
        ...(formInputs.macOsDevices !== undefined && {
          mac_os_devices_enabled: formInputs.macOsDevices,
          mac_os_group_ids: MsDeviceComplianceService.collectExternalIds(
            formInputs.macOsDevices_groups,
          ),
        }),
        ...(formInputs.iosDevices !== undefined && {
          ios_devices_enabled: formInputs.iosDevices,
          ios_group_ids: MsDeviceComplianceService.collectExternalIds(
            formInputs.iosDevices_groups,
          ),
        }),
      };

      await msDeviceComplianceService.setGroupPolicies(
        id,
        platformGroupPolicies,
      );
    },
    onSuccess: () => {
      // refetch the integrations from maint-backend whenever we delete this integration
      queryClient.invalidateQueries({
        queryKey: msDeviceComplianceKeys.integrationDetails(id),
      });
    },
  });
};

export default useAddDevicePlatform;
