import React, { createContext, useState, useEffect, useContext } from 'react';

import { Toaster as toaster } from '@kandji-inc/bumblebee';

import { InterfaceContext } from 'contexts/interface';

import { i18n } from 'i18n';
import { postFeatureRequest } from './api';
import slug from './slug';

const defaultValues = {
  form: undefined,
  wizard: undefined,
  isWorking: false,
  hasError: false,
  setForm: () => {},
  setWizard: () => {},
  resetWizard: () => {},
  handleSave: () => {},
  update: () => {},
  onHome: () => {},
  onClose: () => {},
  setHasError: () => {},
};

const Context = createContext(defaultValues);

const Provider = ({ children }) => {
  const { setIsOpenModalFeatureRequest } = useContext(InterfaceContext);

  const [form, setForm] = useState({});
  const [wizard, setWizard] = useState({});
  const [isWorking, setIsWorking] = useState(false);
  const [hasError, setHasError] = useState(false);

  const initializeWizard = () =>
    setWizard({
      type: null,
    });

  const resetWizard = () => setWizard({});

  const handleResponse = (response) => {
    if (response?.contact_id && response?.feedback_id) {
      setWizard({
        type: slug.SUCCESS,
      });
    } else {
      setWizard({
        type: slug.FAILURE,
      });
    }
  };

  const handleSave = async () => {
    try {
      setIsWorking(true);
      const response = await postFeatureRequest(form);
      handleResponse(response);
      setForm({});
      setIsWorking(false);
    } catch (error) {
      setIsWorking(false);
      toaster(i18n.error.generic());
    }
  };

  const update = (k, v) => setForm((p) => ({ ...p, [k]: v }));

  const onHome = () => {
    setForm({});
    resetWizard();
  };

  const onClose = () => {
    setIsOpenModalFeatureRequest(false);
    setTimeout(() => {
      setForm({});
      resetWizard();
    }, 1000);
  };

  useEffect(() => {
    initializeWizard();
    return () => resetWizard();
  }, []);

  return (
    <Context.Provider
      value={{
        ...defaultValues,
        form,
        wizard,
        isWorking,
        setForm,
        setWizard,
        resetWizard,
        handleSave,
        update,
        onHome,
        onClose,
        hasError,
        setHasError,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export { Context, Provider };
