import {
  Button,
  TextInput,
  asciiValidator,
  onEmptyBlurValidator,
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
/* istanbul ignore file */
import React, { useContext } from 'react';

import { i18n } from 'i18n';
import { UserRoles } from 'src/app/common/constants';
import { AccountContext } from 'src/contexts/account';
import { copyToClipboard } from 'theme/function';

const SpecifyPassword = (props) => {
  const { setting, update, isDisabled, validationDep } = props;
  const { userRole } = useContext(AccountContext);
  const isAuditor = userRole === UserRoles.auditor;

  const fieldsToValidate = ['password'];
  const { refs, onInvalidate } = useInputsValidators(fieldsToValidate, update);
  useRemoveValidationOnUnmount(fieldsToValidate, update);

  const trigger = ['onBlur', validationDep];

  return (
    <div className="k-rp-mc__passwords">
      <p ref={refs.password} className="b-txt b-mb1">
        {i18n.t('Enter the recovery password')}
      </p>
      <TextInput
        value={setting.password}
        onChange={(e) => update('password', e.target.value)}
        disabled={isDisabled}
        type="password"
        placeholder={i18n.t('Enter password')}
        compact
        className="b-mb2"
        validator={(v) => [
          onEmptyBlurValidator(v, {
            trigger,
            message: i18n.t('Please enter a password.'),
          }),
          asciiValidator(v, {
            trigger,
            message: i18n.t(
              'Invalid character found. Please use low ASCII characters.',
            ),
          }),
          {
            invalid: () => v.length > 250,
            message: i18n.validation.maxLength(250),
            trigger,
          },
        ]}
        onInvalidate={onInvalidate('password')}
      />

      {!isAuditor && (
        <Button
          kind="link"
          icon="copy"
          onClick={() => copyToClipboard(setting.password)}
          isDisabled={isDisabled}
        >
          {i18n.t('Copy password')}
        </Button>
      )}
    </div>
  );
};

export default SpecifyPassword;
