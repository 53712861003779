import { Button } from '@kandji-inc/bumblebee';
import { sendFeatureRequest } from 'app/_actions/app';
import { i18n } from 'i18n';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { setSnackbar as callSetSnackbar } from '../../../_actions/ui';
import Textarea from '../../good/form_styles/Textarea';
import { ModalWrapper } from './ModalWrapper';
import { ButtonsRightWrapper } from './elements';

const FeatureRequestTextareaInput = styled(Textarea.Input)`
  height: 200px;
`;

const FeatureRequestCaption = styled.p`
  font-weight: 500;
`;

class FeatureRequestModal extends ModalWrapper {
  constructor(props) {
    super(props);
    this.state = {
      isUpdating: false,
      toSend: '',
      hasError: false,
    };
  }

  showCloseButton = true;

  isWide = true;

  renderHeader = () => 'Feature Request';

  renderBody() {
    const { hasError } = this.state;
    return (
      <>
        <FeatureRequestCaption>
          What would you like us to add to Kandji, or make improvements to?
        </FeatureRequestCaption>
        <FeatureRequestTextareaInput
          placeholder="Your request here..."
          onChange={(e) =>
            this.setState({
              toSend: e.target.value,
              hasError: false,
            })
          }
          error={hasError}
        />
      </>
    );
  }

  renderFooter() {
    const { isUpdating, toSend } = this.state;
    const { setSnackbar } = this.props;

    return (
      <ButtonsRightWrapper>
        <Button
          type="button"
          disabled={isUpdating}
          onClick={() => {
            if (!toSend) {
              this.setState({ hasError: true });
              return;
            }
            this.setState({ isUpdating: true });
            sendFeatureRequest({ message: toSend })
              .then(() => {
                this.setState({ isUpdating: false });
                setSnackbar('Feature request was sent');
              })
              .catch(() => {
                this.setState({ isUpdating: false });
                setSnackbar(i18n.error.generic());
              })
              .finally(() => this.onHide());
          }}
        >
          {isUpdating ? 'Submitting' : 'Submit'}
        </Button>
      </ButtonsRightWrapper>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSnackbar: callSetSnackbar,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FeatureRequestModal);
