import {
  Button,
  Dialog,
  Flex,
  Text,
  TextArea,
  TextField,
} from '@kandji-inc/nectar-ui';
import React, { useState } from 'react';

import { i18n } from 'i18n';
import { IconDisplay, IconSelector } from '../icon-select';

const DuplicateBlueprint = (props) => {
  const { onClose, onDuplicate, blueprint, isFlowBlueprint } = props;
  const [duplicatedBlueprint, setDuplicatedBlueprint] = useState({
    ...blueprint,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSelectingIcon, setIsSelectingIcon] = useState(false);

  // The main "Duplicate Blueprint" modal should only animate on opening when being
  // opened from the Blueprint index page, not from the Icon selector modal
  const [shouldAnimateOnOpen, setShouldAnimateOnOpen] = useState(true);

  const isDuplicateButtonDisabled =
    duplicatedBlueprint.name === blueprint.name ||
    !duplicatedBlueprint.name ||
    isLoading;

  const content = (
    <Flex flow="column" gap="md">
      <Text css={{ paddingBottom: '$3' }}>
        {i18n.ut(
          'All Parameter settings from <b>{blueprint_name}</b> will be duplicated. Device records will NOT be moved.',
          {
            blueprint_name: blueprint.name,
          },
        )}
      </Text>

      {!isFlowBlueprint && (
        <IconDisplay
          className="b-mb"
          onEdit={() => setIsSelectingIcon(true)}
          icon={duplicatedBlueprint.icon}
          color={duplicatedBlueprint.color}
          size="md"
        />
      )}

      <TextField
        autoFocus
        label={i18n.property.Name(i18n.kandji.Blueprint())}
        placeholder={i18n.property.Name(i18n.kandji.Blueprint())}
        value={duplicatedBlueprint.name}
        onChange={(e) =>
          setDuplicatedBlueprint((prev) => ({
            ...prev,
            name: e.target.value,
          }))
        }
      />
      <TextArea
        label={i18n.property.Description(i18n.kandji.Blueprint())}
        placeholder={i18n.property.Description(i18n.kandji.Blueprint())}
        value={duplicatedBlueprint.description}
        onChange={(e) =>
          setDuplicatedBlueprint((prev) => ({
            ...prev,
            description: e.target.value,
          }))
        }
      />
    </Flex>
  );

  const footer = (
    <Flex gap="md" justifyContent="end" wrap="wrap">
      <Button variant="subtle" disabled={isLoading} onClick={onClose}>
        {i18n.button.Cancel()}
      </Button>
      <Button
        variant="primary"
        disabled={isDuplicateButtonDisabled}
        onClick={() => {
          setIsLoading(true);
          onDuplicate(duplicatedBlueprint)
            .then(onClose)
            .catch((e) => {
              setIsLoading(false);
              console.error('Failed to duplicate', e);
            });
        }}
      >
        {i18n.button.Duplicate()}
      </Button>
    </Flex>
  );

  if (isSelectingIcon) {
    return (
      <IconSelector
        blueprint={blueprint}
        icon={duplicatedBlueprint.icon}
        color={duplicatedBlueprint.color}
        onBack={(icon, color) => {
          // Prevent the main modal from animating on reappear
          // when coming from the Icon selector modal
          setShouldAnimateOnOpen(false);
          setDuplicatedBlueprint((prev) => ({
            ...prev,
            icon,
            color,
          }));
          setIsSelectingIcon(false);
        }}
      />
    );
  }

  return (
    <Dialog
      isOpen
      onOpenChange={onClose}
      animateOnOpen={shouldAnimateOnOpen}
      title={i18n.action.Duplicate(i18n.kandji.Blueprint())}
      content={content}
      footer={footer}
      css={{ zIndex: 2000, width: '560px' }}
    />
  );
};

export default DuplicateBlueprint;
