import { Toaster as toaster } from '@kandji-inc/bumblebee';
import {
  Banner,
  Button,
  Dialog,
  Flex,
  Label,
  Text,
  TextArea,
} from '@kandji-inc/nectar-ui';
import React, { useState } from 'react';

import { i18n } from 'i18n';
import { getComputer } from 'src/app/_actions/computer';
import { lockDevice } from 'src/app/components/computer/api';

const ComputerLock = (props) => {
  const { info, onHide } = props;
  const { computerId, computerName } = info;

  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState();

  const handleOnMessageChange = (e) => setMessage(e.target.value);

  const onLockDevice = () => {
    setIsLoading(true);
    lockDevice(computerId, message)
      .then(() => {
        getComputer(computerId);
        onHide();
        setIsLoading(false);
        toaster(i18n.t('Command initiated'));
      })
      .catch(() => {
        onHide();
        setIsLoading(false);
        toaster(i18n.error.generic());
      });
  };

  const content = (
    <Flex flow="column" gap="lg">
      <Text variant="danger">
        The device{' '}
        <Text
          css={{ fontWeight: '$semibold', display: 'inline-block' }}
        >{`${computerName}`}</Text>{' '}
        will be locked the next time it is connected to the internet.
      </Text>

      <Text>
        The lock device command will lock the Mac with a randomly generated PIN
        the next time it connects to MDM. The PIN will be available on the
        device record.
      </Text>

      <Text>
        Mac computers with Apple silicon running a macOS earlier than 11.5 do
        not support the device lock PIN and will be rebooted to recovery where
        Activation will be required.
      </Text>

      <Banner text="Note: a locked device can not be erased" />

      <Text>
        Lock messages are displayed on devices running macOS 10.14 or higher.
      </Text>

      <TextArea
        aria-label="message"
        label={<Label optional>Message</Label>}
        resize
        onChange={handleOnMessageChange}
      />
    </Flex>
  );

  const footer = (
    <Flex gap="md" justifyContent="end" wrap="wrap">
      <Button variant="subtle" onClick={onHide}>
        Cancel
      </Button>
      <Button variant="primary" loading={isLoading} onClick={onLockDevice}>
        Lock device
      </Button>
    </Flex>
  );

  return (
    <Dialog
      isOpen
      closeOnEscape
      closeOnOutsideClick
      onOpenChange={onHide}
      title="Lock device"
      content={content}
      footer={footer}
      css={{ zIndex: 2000 }}
    />
  );
};

export default ComputerLock;
