import { Button } from '@kandji-inc/bumblebee';
import { getComputersCountsForViews } from 'app/_actions/computer';
import { i18n } from 'i18n';
import get from 'lodash/get';
import merge from 'lodash/merge';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import uuidv4 from 'uuid/v4';
import { setSnackbar as callSetSnackbar } from '../../../_actions/ui';
import { updateUser as callUpdateUser } from '../../../_actions/users';
import AwesomeInput from '../AwesomeInput';
import { ModalWrapper } from './ModalWrapper';

export class ViewSave extends ModalWrapper {
  constructor(props) {
    super(props);
    this.modalName = 'ViewSave';
    this.view = props.info.view;
    this.for = props.info.for;
    this.forDep = props.info.forDep;
    this.ifDep = props.info.ifDep;
    this.updateComputerListState = props.info.updateComputerListState;
    this.getAllViews = props.info.getAllViews;
    this.onSave = this.onSave.bind(this);
  }

  onSave() {
    const { viewName } = this.state;
    const { updateUser, setSnackbar, userId, userSettings } = this.props;
    if (!viewName) {
      return this.viewNameInput.focus();
    }
    const editedViewName = this.trimWhitespaces(viewName);
    if (!editedViewName) {
      return this.viewNameInput.focus();
    }
    const oldViewsOrder = get(userSettings, `views_order.${this.for}`);
    const oldDepViewsOrder = get(userSettings, `views_order.${this.forDep}`);
    const viewId = uuidv4();
    let savedView = {};
    if (this.ifDep) {
      savedView = {
        [this.for]: [...(get(userSettings, `saved_views.${this.for}`) || [])],
        [this.forDep]: [
          ...(get(userSettings, `saved_views.${this.forDep}`) || []),
          { ...this.view, name: editedViewName, id: viewId },
        ],
      };
    } else {
      savedView = {
        [this.for]: [
          ...(get(userSettings, `saved_views.${this.for}`) || []),
          { ...this.view, name: editedViewName, id: viewId },
        ],
        [this.forDep]: [
          ...(get(userSettings, `saved_views.${this.forDep}`) || []),
        ],
      };
    }
    const newView = {
      saved_views: savedView,
      active_views: {
        [this.for]: !this.ifDep ? viewId : null,
        [this.forDep]: this.ifDep ? viewId : null,
      },
      views_order: {
        [this.for]:
          !this.ifDep && oldViewsOrder ? [...oldViewsOrder, viewId] : null,
        [this.forDep]:
          this.ifDep && oldDepViewsOrder ? [...oldDepViewsOrder, viewId] : null,
      },
    };
    const payload = merge(userSettings, newView);

    this.setState({ isLoading: true });
    updateUser({ id: userId, settings: payload }, true, false) // prev last flag for myself updating
      .then(() => {
        this.updateComputerListState({ isViewChanged: false });
        this.setState({ isLoading: false });
        getComputersCountsForViews(this.getAllViews()).then((counts) =>
          this.updateComputerListState({ computersCounts: counts }),
        );
        setSnackbar(i18n.t('A New View Was Saved'));
        this.onHide();
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        setSnackbar(err.message);
      });
    return null;
  }

  renderHeader = () => i18n.t('Save A New View');

  renderBody() {
    const { viewName } = this.state;
    return (
      <>
        <p>{i18n.t('Please, enter name for your new view:')}</p>
        <AwesomeInput
          onChange={this.onChange}
          value={viewName}
          name="viewName"
          placeholder={i18n.t('View Name')}
          ref={(el) => {
            this.viewNameInput = el;
          }}
        />
      </>
    );
  }

  renderFooter() {
    const { isLoading } = this.state;
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Button
          type="button"
          onClick={this.onHide}
          kind="outline"
          style={{ width: '45%' }}
          theme="dark"
        >
          {i18n.button.Cancel()}
        </Button>
        <Button
          type="button"
          disabled={isLoading}
          onClick={this.onSave}
          name="Save View"
          style={{ width: '45%' }}
        >
          {i18n.t('Save View')}
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userId: state.account.user.id,
  userSettings: state.account.user.settings,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUser: callUpdateUser,
      setSnackbar: callSetSnackbar,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ViewSave);
