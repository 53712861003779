import {
  Box,
  Button,
  Flex,
  Heading,
  Paragraph,
  styled,
} from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import * as React from 'react';
import { Loader } from 'src/app/components/interface/Loader';
import type { GlobalFilters } from 'src/features/visibility/prism/hooks';
import EmptyIcon from '../../../assets/bp-filter-empty-state.svg';
import NoDevicesIcon from '../../../assets/no-devices.svg';
import type { PrismCategoryUri } from '../../../types/prism.types';
import { StyledLink } from '../../shared';

const NoResultsImg = styled('img', {
  mb: '$5',
});

export type PrismTableEmptyProps = {
  uri: PrismCategoryUri | undefined;
  globalFilters: GlobalFilters;
  attributeFilters: Record<string, unknown>;
  onOpenEditViewModal: () => void;
  computerCount: number | undefined;
  onNavigateToAddDevice: () => void;
  isLoading: boolean;
};

const PrismTableEmpty = ({
  uri,
  globalFilters,
  attributeFilters,
  onOpenEditViewModal,
  computerCount,
  onNavigateToAddDevice,
  isLoading,
}: PrismTableEmptyProps) => {
  const noResultsCopy = getNoResultsMessage({
    uri,
    globalFilters,
    attributeFilters,
    computerCount,
  });
  return (
    <Flex flow="column" alignItems="center" justifyContent="center">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <NoResultsImg src={noResultsCopy.image} alt="Empty Icon" />
          <Flex flow="column" alignItems="center" css={{ width: '100%' }}>
            <Heading
              as="h4"
              size="4"
              css={{
                color: '$neutral90',
                fontWeight: '$medium',
              }}
            >
              {noResultsCopy.header}
            </Heading>
            <Paragraph
              css={{
                color: '$neutral90',
                whiteSpace: 'normal',
                textAlign: 'center',
              }}
            >
              {noResultsCopy.body}
              {!computerCount && (
                <Box>
                  <StyledLink
                    href="https://support.kandji.io/support/solutions/articles/72000560543-enrolling-devices"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {i18n.t('Learn more...')}
                  </StyledLink>
                </Box>
              )}
            </Paragraph>
            {!computerCount && (
              <Button
                onClick={onNavigateToAddDevice}
                css={{ mt: '24px' }}
                icon={{
                  name: 'circle-plus',
                  position: 'left',
                }}
              >
                {i18n.action.Add(i18n.common.devices())}
              </Button>
            )}
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default PrismTableEmpty;

export function getNoResultsMessage({
  uri,
  globalFilters,
  attributeFilters,
  computerCount,
}: {
  uri: PrismCategoryUri | undefined;
  globalFilters: GlobalFilters;
  attributeFilters: Record<string, any>;
  computerCount: number | undefined;
}): {
  header: string;
  body: string;
  image: string;
} {
  if (!computerCount) {
    return {
      header: i18n.t('No devices to display'),
      body: i18n.t(
        'Add devices to Kandji to view device information in Prism. You can add devices manually in the Add Devices section or through Automated Device Enrollment.',
      ),
      image: NoDevicesIcon,
    };
  }

  const categoryFilters = attributeFilters;
  const categoryFiltersEmpty =
    categoryFilters === undefined ||
    !categoryFilters ||
    Object.keys(categoryFilters).length === 0;

  if (
    categoryFiltersEmpty &&
    globalFilters.blueprints.length === 0 &&
    globalFilters.deviceFamilies.length === 0
  ) {
    return {
      header: i18n.t('No data available'),
      body: i18n.t("There's no data to display for devices in this category."),
      image: EmptyIcon,
    };
  }

  return {
    header: i18n.t(
      'No data is available in this category that matches your applied filters',
    ),
    body: i18n.t(
      `We couldn’t find a match. Edit or remove any applied filters.`,
    ),
    image: EmptyIcon,
  };
}
