import {
  Banner,
  Body,
  Button,
  Checkbox,
  Code,
  Flex,
  Heading,
  Icon,
  type IconNames,
  Separator,
  Switch,
  type ToastVariants,
  Tooltip,
  styled,
  useToast_UNSTABLE as useToast,
} from '@kandji-inc/nectar-ui';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import BlueprintIcon from '../ADEListView/ADEListViewTable/ADECellBlueprintIcon';
import type { AuthBlueprint, AuthConnection } from './ManualEnrollment.types';
import {
  changeBlueprintEnrollmentCode,
  toggleBlueprintEnrollmentCode,
  upsertAuthConfig,
} from './ManualEnrollmentActionCreators';
import { ManualEnrollmentAuthSelect } from './ManualEnrollmentAuthSelect';
import { ManualEnrollmentCancelDialog } from './ManualEnrollmentCancelDialog';
import { ManualEnrollmentLeaveDialog } from './ManualEnrollmentLeaveDialog';
import './ManualEnrollment.styles.css';
import debounce from 'lodash/debounce';
import { InterfaceContext } from 'src/contexts/interface';
import {
  SIDEBAR_CLOSE_OFFSET,
  SIDEBAR_DOCKED_OFFSET,
} from '../ADEListView/ADEListViewTable/ADEExportTable';
import { ManualEnrollmentCodeDialog } from './ManualEnrollmentCodeDialog';
import type { RefetchBlueprints } from './hooks/useGetAuthBlueprints';

const KANDJI_AUTH_STRATEGY = 'email_password';

const BlueprintCardContainer = styled('div', {
  width: '100%',
  border: '1px solid $neutral20',
  borderRadius: '$2',
  padding: '$4',
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
});

const BlueprintName = styled('div', { marginLeft: '$3' });

const EnrollmentCodeContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
  fontSize: '$1',
  color: '$text_secondary',
});

const RequireAuthenticationDescription = styled('div', {
  display: 'flex',
  gap: 6,
  alignItems: 'center',
  fontSize: '$1',
  color: '$text_secondary',
  marginLeft: 'auto',
});

const AuthenticationCard = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: 18,
  background: 'rgba(236, 241, 247, 0.30)',
  borderRadius: '$1',
});

const InsetLine = styled('div', {
  backgroundColor: '$neutral20',
  width: 2,
});

const ActionDescription = styled('div', {
  marginLeft: 'auto',
  fontSize: '$1',
  color: '$text_secondary',
});

const FlexTextSecondary = styled(Flex, {
  color: '$text_secondary',
});

const copiedTooltipStyle = {
  marginBottom: '8px',
  backgroundColor: '$green60',
  '& span svg': {
    display: 'none !important',
  },
};

const CodeCopiedTooltip = () => (
  <Flex alignItems="center" gap="xs">
    <Icon size="xs" name="circle-check" />
    Code copied
  </Flex>
);

function getConnectionIcon(strategy: string): IconNames {
  if (strategy === KANDJI_AUTH_STRATEGY) {
    return 'kandji-logo';
  }

  return strategy as IconNames;
}

interface ManualEnrollmentAuthenticationCardProps {
  blueprint: AuthBlueprint;
  authConnections: AuthConnection[];
  isEditing: boolean;
  isSaving: boolean;
  setBlueprint: React.Dispatch<React.SetStateAction<AuthBlueprint>>;
}

export function ManualEnrollmentAuthenticationCard({
  blueprint,
  authConnections,
  isEditing,
  isSaving,
  setBlueprint,
}: ManualEnrollmentAuthenticationCardProps) {
  const { authConfig } = blueprint;

  function handleSetAssignUser(assignUser: boolean) {
    setBlueprint({
      ...blueprint,
      authConfig: { ...authConfig, assign_user: assignUser },
    });
  }

  function handleSetAuthClient(authClientConnectionId: string) {
    const newBlueprint = { ...blueprint };
    const connection = authConnections.find(
      (connection) => connection.id === authClientConnectionId,
    );

    newBlueprint.authConfig.auth0_client.auth0_configuration.connection_id =
      authClientConnectionId;

    newBlueprint.authConfig.auth0_client.auth0_configuration.strategy =
      connection.strategy;

    newBlueprint.authConfig.auth0_client.auth0_configuration.display_name =
      connection.display_name;

    setBlueprint(newBlueprint);
  }

  return (
    <Flex gap="md">
      <InsetLine css={{ marginLeft: 4 }} />
      <AuthenticationCard>
        <Flex>
          <Flex flow="column" gap="xs">
            <Heading size="5" css={{ color: '$text_secondary' }}>
              Connection
            </Heading>
            <ManualEnrollmentAuthSelect
              disabled={!isEditing || isSaving}
              value={
                blueprint.authConfig.auth0_client.auth0_configuration
                  .connection_id
              }
              options={authConnections.map((connection) => ({
                label: connection.display_name,
                icon: getConnectionIcon(connection.strategy),
                value: connection.id,
              }))}
              onValueChange={handleSetAuthClient}
            />
          </Flex>
          <ActionDescription>
            The single sign-on connector to be used for authentication.
          </ActionDescription>
        </Flex>
        <Separator css={{ margin: '18px 0' }} />
        <Flex>
          <Checkbox
            data-testid="manual-enrollment-assign-user"
            checked={authConfig.assign_user}
            onCheckedChange={handleSetAssignUser}
            disabled={!isEditing || isSaving}
            label={'Assign user to device record'}
          />
          <ActionDescription>
            Attempt to automatically assign the authenticated user to the
            device.
          </ActionDescription>
        </Flex>
      </AuthenticationCard>
    </Flex>
  );
}

interface ManualEnrollmentBlueprintCardProps {
  blueprint: AuthBlueprint;
  authConnections: AuthConnection[];
  refetchBlueprints: RefetchBlueprints;
}

export function ManualEnrollmentBlueprintCard({
  blueprint,
  authConnections,
  refetchBlueprints,
}: ManualEnrollmentBlueprintCardProps) {
  const { sidebarDocked } = React.useContext(InterfaceContext);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [showLeaveDialog, setShowLeaveDialog] = React.useState<boolean>(false);
  const [handleUnblock, setHandleUnblock] = React.useState(() => () => {});
  const [showCancelDialog, setShowCancelDialog] =
    React.useState<boolean>(false);
  const [showCodeDialog, setShowCodeDialog] = React.useState(false);
  const [didCopyCode, setDidCopyCode] = React.useState(false);
  const debouncedSetDidCopyCode = debounce(
    (didCopyCode: boolean) => setDidCopyCode(didCopyCode),
    2000,
  );

  const { toast } = useToast();

  const [newBlueprint, setNewBlueprint] = React.useState<AuthBlueprint>(
    JSON.parse(JSON.stringify(blueprint)),
  );

  const history = useHistory();

  const noChanges = React.useMemo(() => {
    return JSON.stringify(blueprint) === JSON.stringify(newBlueprint);
  }, [blueprint, newBlueprint]);

  const {
    id,
    name,
    color,
    icon,
    enrollment_code: enrollmentCode,
    authConfig,
  } = newBlueprint;

  const { auth0_client, enabled } = authConfig;
  const { auth0_configuration } = auth0_client;
  const { strategy, display_name: displayName } = auth0_configuration;

  function handleCancelEditing() {
    setIsEditing(false);
    setShowCancelDialog(false);
    setNewBlueprint(
      JSON.parse(
        JSON.stringify({
          ...blueprint,
          enrollment_code: newBlueprint.enrollment_code,
        }),
      ),
    );
  }

  function tryCancelEditing() {
    if (!noChanges) {
      setShowCancelDialog(true);
      return;
    }

    handleCancelEditing();
  }

  async function handleSave() {
    setIsSaving(true);

    try {
      await upsertAuthConfig(authConfig, refetchBlueprints);
    } finally {
      setIsSaving(false);
      setIsEditing(false);
    }
  }

  function handleRequireAuth(authRequired: boolean) {
    setNewBlueprint((prev) => ({
      ...prev,
      authConfig: { ...authConfig, enabled: authRequired },
    }));
  }

  const displayToast = (
    title: string,
    variant: ToastVariants,
    content: string,
  ) =>
    toast({
      title,
      variant,
      style: {
        left: /* istanbul ignore next */ sidebarDocked
          ? `${SIDEBAR_DOCKED_OFFSET + 12}px`
          : `${SIDEBAR_CLOSE_OFFSET + 12}px`,
        bottom: '12px',
        position: 'absolute',
      },
      content,
    });

  function handleCopyCodeToClipboard() {
    navigator.clipboard?.writeText(enrollmentCode.code);
    setDidCopyCode(true);
    debouncedSetDidCopyCode(false);
  }

  async function handleChangeCode() {
    setIsSaving(true);
    const code = await changeBlueprintEnrollmentCode(id);
    setNewBlueprint({ ...newBlueprint, enrollment_code: code });
    setIsSaving(false);
    displayToast(
      `New code for ${name} successfully generated.`,
      'success',
      null,
    );
  }

  async function handleToggleCode(isActive: boolean) {
    try {
      setIsSaving(true);
      setNewBlueprint({
        ...newBlueprint,
        enrollment_code: { ...enrollmentCode, is_active: isActive },
      });
      await toggleBlueprintEnrollmentCode(id, isActive);
    } catch {
      setNewBlueprint({
        ...newBlueprint,
        enrollment_code: { ...enrollmentCode, is_active: !isActive },
      });
    } finally {
      setIsSaving(false);
    }
  }

  React.useEffect(
    /*istanbul ignore next*/
    () => {
      const unblock = history.block(({ pathname }) => {
        if (!noChanges && isEditing) {
          setShowLeaveDialog(true);
          setHandleUnblock(() => () => {
            unblock();
            history.push(pathname);
          });

          return false;
        }

        unblock();
        history.push(pathname);
      });

      return () => {
        unblock();
      };
    },
    [isEditing, noChanges],
  );

  return (
    <BlueprintCardContainer
      css={isOpen && { boxShadow: '0px 2px 8px 0px rgba(15, 19, 23, 0.20)' }}
    >
      <ManualEnrollmentLeaveDialog
        isOpen={showLeaveDialog}
        toggleDialog={setShowLeaveDialog}
        handleLeave={handleUnblock}
      />
      <ManualEnrollmentCancelDialog
        isOpen={showCancelDialog}
        handleCancel={handleCancelEditing}
        toggleDialog={setShowCancelDialog}
        blueprint={newBlueprint}
      />
      <ManualEnrollmentCodeDialog
        isOpen={showCodeDialog}
        handleGenerate={handleChangeCode}
        toggleDialog={setShowCodeDialog}
      />
      <Flex flex={1} alignItems={'center'}>
        <Button
          icon={{ name: isOpen ? 'fa-angle-up-small' : 'fa-angle-down-small' }}
          variant="subtle"
          css={{
            background: 'none',
            padding: 0,
            marginRight: 8,
          }}
          disabled={isEditing}
          onClick={() => setIsOpen(!isOpen)}
        />
        <BlueprintIcon iconName={icon} color={color} size={40} iconSize={12} />
        <Flex flow="column" css={{ alignItems: 'baseline', gap: 2 }}>
          <BlueprintName>
            <Heading size={'4'}>{name}</Heading>
          </BlueprintName>
          {enabled && (
            <FlexTextSecondary alignItems="center" gap="xs">
              <Icon name={getConnectionIcon(strategy)} size="xs" />
              Requires {displayName} authentication
            </FlexTextSecondary>
          )}
        </Flex>
        <EnrollmentCodeContainer>
          Enrollment code
          <Code css={{ marginLeft: 10, marginRight: 2 }}>
            {enrollmentCode.code}
          </Code>
          <Tooltip
            content={!didCopyCode ? 'Click to copy' : <CodeCopiedTooltip />}
            side="top"
            onPointerDownOutside={
              /* istanbul ignore next */ (e) => e.preventDefault()
            }
            className={didCopyCode ? 'tooltip-fade' : ''}
            css={didCopyCode ? copiedTooltipStyle : {}}
          >
            <Button
              variant="subtle"
              css={{
                padding: 6,
                marginRight: 20,
              }}
              onClick={handleCopyCodeToClipboard}
              onMouseLeave={
                /* istanbul ignore next */ () => setDidCopyCode(false)
              }
              data-testid="manual-enrollment-copy-code"
            >
              <Icon name="copy" size="sm" />
            </Button>
          </Tooltip>
        </EnrollmentCodeContainer>
        <Tooltip
          sideOffset={2}
          content={enrollmentCode.is_active ? 'Disable code' : 'Enable code'}
        >
          <div>
            <Switch
              checked={enrollmentCode.is_active}
              onCheckedChange={handleToggleCode}
              data-testid="manual-enrollment-toggle-blueprint"
            />
          </div>
        </Tooltip>
      </Flex>
      {isOpen && <Separator />}
      {isOpen && (
        <Flex flow="column" gap="lg">
          <Flex gap="xs">
            <Checkbox
              data-testid="manual-enrollment-require-auth"
              checked={authConfig.enabled}
              disabled={!isEditing || isSaving || authConnections.length < 1}
              onCheckedChange={handleRequireAuth}
              label="Require authentication"
            />
            <RequireAuthenticationDescription>
              Requires a user to authenticate through single sign-on during
              device enrollment.
              <Button variant="link" compact>
                Learn more
              </Button>
            </RequireAuthenticationDescription>
          </Flex>
          {authConnections.length < 1 && (
            <Banner
              theme="warning"
              text={
                <Flex flow="column" gap="xs" alignItems="start">
                  <Heading
                    size="5"
                    style={{
                      color: '#994700',
                      lineHeight: 'unset',
                      fontWeight: '$medium',
                    }}
                  >
                    No single sign-on connections are configured
                  </Heading>
                  <Body css={{ margin: 0 }}>
                    Visit Access Settings and configure an SSO connection to
                    enable this feature.
                  </Body>
                  <Button variant="link" css={{ background: 'none' }}>
                    Learn more
                  </Button>
                </Flex>
              }
            />
          )}
          {authConnections.length > 0 && authConfig.enabled && (
            <ManualEnrollmentAuthenticationCard
              isSaving={isSaving}
              isEditing={isEditing}
              blueprint={newBlueprint}
              setBlueprint={setNewBlueprint}
              authConnections={authConnections}
            />
          )}
          <Flex
            gap="sm"
            alignItems="center"
            css={{ marginTop: 'auto', marginLeft: 'auto' }}
          >
            {!isEditing && (
              <>
                <Button
                  icon={{ name: 'arrow-right-arrow-left', position: 'left' }}
                  variant="subtle"
                  compact
                  onClick={() => setShowCodeDialog(true)}
                  disabled={isSaving}
                >
                  Change code
                </Button>
                <Button
                  variant="primary"
                  compact
                  onClick={/* istanbul ignore next */ () => setIsEditing(true)}
                  disabled={isSaving || authConnections.length < 1}
                >
                  Edit settings
                </Button>
              </>
            )}
            {isEditing && (
              <>
                <Button
                  variant="subtle"
                  compact
                  disabled={isSaving}
                  onClick={tryCancelEditing}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  compact
                  disabled={isSaving || noChanges}
                  onClick={handleSave}
                >
                  Save
                </Button>
              </>
            )}
          </Flex>
        </Flex>
      )}
    </BlueprintCardContainer>
  );
}
