import get from 'lodash/get';
import React, { createContext, useContext, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { UserRoles } from 'src/app/common/constants';

import { formatTime } from 'theme/function';

import { api } from 'app/api/base';
import { URL_AUTH_CONNECTOR } from 'app/api/urls';

import { useDateFormat } from 'app/components/common/helpers';
import { i18n } from 'i18n';
import {
  fetchAccount as callFetchAccount,
  updateUser as callUpdateUser,
} from '../app/_actions/account'; // TODO: remove this import after refactoring the routing to have Public routes inside Main and Switch
import { InterfaceContext } from './interface';

const defaultValues = {
  auth0UserId: undefined,
  isEmailPasswordAuth0: undefined,
  isSocial: undefined,
  isStandardAuth: undefined,
  userId: undefined,
  userEmail: undefined,
  userRole: undefined,
  userSettings: undefined,
  currentCompany: undefined,
  company: undefined,
  listAuthConnector: undefined,
  tenantOverLicenseLimit: false,
  accountReady: false,
  timezone: null,
  disableRelativeDates: false,
  fetchAccount: async () => {},
  updateUser: () => {},
  fetchListAuthConnector: () => {},
  formatTimeWithTZ: () => {},
  setListAuthConnector: () => {},
};

const AccountContext = createContext(defaultValues);

// TODO: move hasAuthToken logic back into this file after refactoring the routing to have Public routes inside Main and Switch

const AccountProviderConnect = ({
  auth0UserId,
  userId,
  userEmail,
  userFirstName,
  userLastName,
  userRole,
  userSettings,
  userType,
  currentCompany,
  company,
  tenantOverLicenseLimit,
  accountReady,
  fetchAccount,
  updateUser,
  children,
  value,
}) => {
  const { setSnackbar } = useContext(InterfaceContext);

  const dispatch = useDispatch();

  const [listAuthConnector, setListAuthConnector] = useState([]);

  const preferredDateFormat = useDateFormat();

  const formatTimeWithTZ = (
    timestamp,
    hideSeconds,
    errorMessage,
    dateOnly,
    passRelativity,
  ) =>
    formatTime(
      timestamp,
      hideSeconds,
      errorMessage,
      dateOnly,
      passRelativity,
      preferredDateFormat,
      get(userSettings, 'timezone'),
      get(userSettings, 'disable_relative_dates') === 'disable',
    );

  const fetchListAuthConnector = async () => {
    try {
      const { data: connData } = await api(URL_AUTH_CONNECTOR).get();
      const { connections } = connData;

      setListAuthConnector(connections);
    } catch (error) {
      dispatch(setSnackbar(i18n.error.generic()));
    }
  };

  // Whether or not the user is logged in via Standard Authentication
  // For all Valid Strategy names, reference: https://auth0.com/docs/api/management/v2#!/Connections/post_connections
  const isEmailPasswordAuth0 = auth0UserId?.startsWith('auth0');
  const isSocial =
    auth0UserId?.startsWith('google-oauth2') ||
    auth0UserId?.startsWith('office365');
  const isStandardAuth = isEmailPasswordAuth0 || isSocial;

  return (
    <AccountContext.Provider
      value={{
        ...defaultValues,
        auth0UserId,
        isEmailPasswordAuth0,
        isSocial,
        isStandardAuth,
        userId,
        userEmail,
        userFirstName,
        userLastName,
        userRole,
        userType,
        userSettings,
        currentCompany,
        company,
        listAuthConnector,
        tenantOverLicenseLimit,
        accountReady,
        fetchAccount,
        updateUser,
        fetchListAuthConnector,
        formatTimeWithTZ,
        setListAuthConnector,
        ...value,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

const mapStateToProps = ({ account, auth0, company }) => ({
  auth0UserId: auth0.userId,
  userId: account.user.id,
  userEmail: account.user.email,
  userFirstName: account.user.first_name,
  userLastName: account.user.last_name,
  userRole: account.user.role,
  userType: account.user.type,
  currentCompany: account.company,
  tenantOverLicenseLimit: account.tenantOverLicenseLimit,
  userSettings: account.user.settings,
  accountReady: account.accountReady,
  company,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchAccount: callFetchAccount,
      updateUser: callUpdateUser,
    },
    dispatch,
  );

const AccountProvider = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountProviderConnect);

const useAccount = () => useContext(AccountContext);

const usePermissions = () => {
  const { userRole } = useAccount();
  const canManageBlueprints = [UserRoles.admin, UserRoles.standard].includes(
    userRole,
  );
  const canManageDevices = [
    UserRoles.admin,
    UserRoles.standard,
    UserRoles.helpdesk,
  ].includes(userRole);
  const canManageSettings = [UserRoles.admin].includes(userRole);
  const canViewPulse = [UserRoles.admin, UserRoles.standard].includes(userRole);
  const canAssignADEUser = [
    UserRoles.admin,
    UserRoles.standard,
    UserRoles.helpdesk,
  ].includes(userRole);

  return {
    canManageBlueprints,
    canManageDevices,
    canManageSettings,
    canViewPulse,
    canAssignADEUser,
  };
};

const withPermissions = (Component) => (props) => (
  <Component {...props} permissions={usePermissions()} />
);

export default useAccount;
export { AccountContext, AccountProvider, usePermissions, withPermissions };
