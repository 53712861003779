import {
  Banner,
  Button,
  Checkbox,
  modal as Modal,
  TextInput,
  Toaster as toaster,
} from '@kandji-inc/bumblebee';
import { bool, func, number, shape, string } from 'prop-types';
import React, { useState } from 'react';

import SemiBold from 'features/util/components/semi-bold';
import Carousel from '../../../../util/components/carousel';
import { newIntegrationsService } from '../../../data-service/new-integrations-service';
import './rotate-token-modal.styles.scss';
import { i18n } from 'i18n';

const CONTENT_WIDTH = 496;
const SLIDE_HEIGHT = [120, 440];

function FirstSlide({ name }) {
  return (
    <div
      style={{ height: SLIDE_HEIGHT[0] }}
      className="rotate-token-modal_first-slide"
    >
      <p className="b-txt">
        Are you sure you want to rotate the token for{' '}
        <SemiBold>{name}</SemiBold>? This action cannot be undone.
      </p>
      <p className="b-txt">
        You will need to update the token access key with your identity provider
        to ensure that this user directory continues to import users. It will
        not affect any existing users assigned to devices.
      </p>
    </div>
  );
}

FirstSlide.propTypes = {
  name: string.isRequired,
};

function SecondSlide({ token, tokenIsCopied, setTokenIsCopied }) {
  return (
    <div style={{ height: SLIDE_HEIGHT[1] }}>
      <p className="b-mb">
        Provide the new token to your identity provider to update the
        integration. If you need to access these details again, the token will
        need to be rotated.
      </p>
      <Banner
        theme="warning"
        className="rotate-token-modal_banner"
        icon="octagon-exclamation"
      >
        <SemiBold>
          Copy the token now and store it in a safe place. You will need to
          provide it to your identity provider to update the integration.
        </SemiBold>
      </Banner>
      <p className="b-txt b-mb1">Token</p>
      <TextInput value={token} type="password" />
      <Button
        className="b-mt1 b-mb3 cmn-button--size-medium"
        icon="copy"
        kind="link"
        onClick={() =>
          navigator.clipboard
            .writeText(token)
            .then(() => toaster(i18n.t('Copied to clipboard.')))
            .catch((e) => {
              console.log(e);
              toaster(i18n.t('Could not copy to clipboard.'));
            })
        }
      >
        Copy token
      </Button>

      <Checkbox
        label="I have copied the token and understand that I will have to rotate the token in order to see these details again."
        onChange={() => setTokenIsCopied((curr) => !curr)}
        checked={tokenIsCopied}
        style={{ alignItems: 'start' }}
      />
    </div>
  );
}

SecondSlide.propTypes = {
  token: string.isRequired,
  tokenIsCopied: bool.isRequired,
  setTokenIsCopied: func.isRequired,
};

function RotateTokenModal({ rotatedIntegration, finishRotating }) {
  const [currentSlideIdx, setCurrentSlideIdx] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [token, setToken] = useState(null);
  const [tokenIsCopied, setTokenIsCopied] = useState(false);

  function closeCompletely() {
    if (!isProcessing) {
      setCurrentSlideIdx(0);
      setTokenIsCopied(false);
      setToken(null);
      finishRotating();
    }
  }

  return (
    <Modal
      isOpen={rotatedIntegration != null}
      withoutCloseButton
      onClose={closeCompletely}
      parts={{
        header: {
          text: currentSlideIdx ? 'Copy SCIM token' : 'Rotate SCIM token',
        },
        content: {
          className: !currentSlideIdx ? 'cmn-modal_content--b-spacing' : null,
          children: (
            <Carousel
              style={{ height: SLIDE_HEIGHT[currentSlideIdx] }}
              className="rotate-token-modal_carousel-wrapper"
              slides={[
                <FirstSlide name={rotatedIntegration?.name || ''} />,
                <SecondSlide
                  setTokenIsCopied={setTokenIsCopied}
                  tokenIsCopied={tokenIsCopied}
                  token={token || ''}
                />,
              ]}
              currentSlideIdx={currentSlideIdx}
              width={CONTENT_WIDTH}
            />
          ),
        },
        footer: {
          children: (
            <>
              {!currentSlideIdx && (
                <Button
                  kind="outline"
                  onClick={closeCompletely}
                  className="b-mr"
                >
                  Cancel
                </Button>
              )}

              <Button
                disabled={isProcessing || (!!currentSlideIdx && !tokenIsCopied)}
                isProgress={isProcessing}
                icon={isProcessing ? 'arrows-rotate' : null}
                onClick={() => {
                  if (currentSlideIdx) {
                    closeCompletely();
                  } else {
                    setIsProcessing(true);
                    newIntegrationsService
                      .rotateScimToken(rotatedIntegration?.id)
                      .then(({ data }) => {
                        setToken(data.token);
                        setCurrentSlideIdx((curr) => curr + 1);
                      })
                      .catch((e) => {
                        console.log(e);
                        toaster(i18n.error.generic());
                      })
                      .finally(() => setIsProcessing(false));
                  }
                }}
              >
                {currentSlideIdx ? 'Done' : 'Rotate token'}
              </Button>
            </>
          ),
        },
      }}
    />
  );
}

RotateTokenModal.propTypes = {
  rotatedIntegration: shape({
    id: number.isRequired,
    name: string.isRequired,
  }),
  finishRotating: func.isRequired,
};

RotateTokenModal.defaultProps = {
  rotatedIntegration: null,
};

export default RotateTokenModal;
