/* istanbul ignore file */
import { t } from '@kandji-inc/nectar-i18n';

/** Plural string translations for different entity counts */
export const counts = {
  items: (count: number) =>
    t('{count, plural, one {# item} other {# items}}', { count }),
  devices: (count: number) =>
    t('{count, plural, one {# device} other {# devices}}', { count }),
};
