import { Icon } from '@kandji-inc/bumblebee';
import camelCase from 'lodash/camelCase';
import PropTypes from 'prop-types';
/* istanbul ignore file */
import React, { memo, useContext } from 'react';
import { useLocation } from 'react-router';
import { UncontrolledTooltip } from 'reactstrap';

import { links, tabsNames } from 'app/common/constants';
import { usePermissions } from 'contexts/account';
import { InterfaceContext } from 'contexts/interface';
import featureFlags from 'src/config/feature-flags';
import { noop } from 'theme/function';
import SidebarItemLink from './SidebarItemLink';

import { i18n } from 'i18n';
import CircleBook from './icons/CircleBook';

const SidebarItemLinkWithOnClick = memo(
  ({ link, onClick, id, tabName, sidebarOpened, icon }) => (
    <a href={link} onClick={onClick} className="nav-link cursor-pointer">
      <div className="item" id={id}>
        <div className="d-flex flex-dir-row align-items-center position-relative">
          <Icon name={icon} />
        </div>
        {sidebarOpened && (
          <div className="kandji-sidebar__item--opened">{tabName}</div>
        )}
        {!sidebarOpened && (
          <UncontrolledTooltip
            placement="right"
            delay={{ show: 400, hide: 0 }}
            className="cover-all-helper tooltip-right kandji-sidebar__tooltip"
            target={id}
          >
            {tabName}
          </UncontrolledTooltip>
        )}
      </div>
    </a>
  ),
);

SidebarItemLinkWithOnClick.propTypes = {
  link: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  id: PropTypes.string.isRequired,
  tabName: PropTypes.string.isRequired,
  sidebarOpened: PropTypes.bool.isRequired,
  icon: PropTypes.string.isRequired,
};
SidebarItemLinkWithOnClick.defaultProps = {
  onClick: noop,
};

const FooterItems = () => {
  const { pathname } = useLocation();
  const { sidebarOpened, setIsOpenModalFeatureRequest } =
    useContext(InterfaceContext);
  const permissions = usePermissions();
  const isEnrollmentPageEnabled = featureFlags.getFlag(
    'core_022824_enrollment-page',
  );
  const isUpdatesPageEnabled = featureFlags.getFlag(
    'bzbe-07112024-updates-page',
  );

  return (
    <>
      <SidebarItemLink
        active={pathname.startsWith(links['add-devices'])}
        sidebarOpened={sidebarOpened}
        link={links['add-devices']}
        name={
          isEnrollmentPageEnabled
            ? i18n.section.Enrollment()
            : tabsNames.addDevices
        }
        icon="circle-plus"
        withScrollBar
      />
      {permissions.canManageSettings && (
        <SidebarItemLink
          active={pathname.startsWith(links.integrations.root)}
          sidebarOpened={sidebarOpened}
          link={links.integrations.root}
          name={i18n.section.Integrations()}
          icon="share-nodes"
          withScrollBar
          // TODO: red badge when there's consistent logic for it
        />
      )}
      {permissions.canManageSettings && (
        <SidebarItemLink
          active={pathname.startsWith(links['my-company'])}
          sidebarOpened={sidebarOpened}
          link={links['my-company']}
          name={i18n.section.Settings()}
          icon="gear"
          withScrollBar
        />
      )}
      {isUpdatesPageEnabled ? (
        <SidebarItemLink
          active={pathname.startsWith(links.resources)}
          sidebarOpened={sidebarOpened}
          link={links.resources}
          name={i18n.section.Resources()}
          customEl={<CircleBook />}
          withScrollBar
        />
      ) : (
        <>
          <SidebarItemLinkWithOnClick
            link="https://support.kandji.io/"
            onClick={(e) => {
              e.preventDefault();
              window.open('https://support.kandji.io/');
            }}
            id={camelCase('sidebarItemHelpButton')}
            tabName={tabsNames.help}
            sidebarOpened={sidebarOpened}
            icon="circle-question"
          />
          <SidebarItemLinkWithOnClick
            link={links.featureRequest}
            onClick={(e) => {
              e.preventDefault();
              setIsOpenModalFeatureRequest(true);
            }}
            id={camelCase('sidebarItemFeatureRequest')}
            tabName={tabsNames.featureRequest}
            sidebarOpened={sidebarOpened}
            icon="message-dots"
          />
        </>
      )}
    </>
  );
};

export default FooterItems;
