import { Button, Chip, Toaster as toaster } from '@kandji-inc/bumblebee';
import { Box } from '@kandji-inc/nectar-ui';
import Tippy from '@tippyjs/react';
import type React from 'react';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import Table from 'src/features/edr/vulnerability/old/components/table';

import { Setting } from 'features/library-items/template';
import useSelfService from 'features/self-service-new/api/use-self-service';
import type { BookmarkItem, ManageBookmarksProps } from '../../bookmarks.types';
import { Drawer, defaultBookmarkIcon, starIcon } from '../../components';
import './ManageBookmarks.css';
import { i18n } from 'i18n';

const ManageBookmarks = ({
  settings,
  update,
  isDisabled,
  drawerStatus,
}: ManageBookmarksProps) => {
  const setDrawerStatus = drawerStatus[1];
  const [selectedBookmark, setSelectedBookmark] = useState(null);
  const isBookmarksTableVisible = !!settings.bookmarks.length;
  const bookmarksListRef = useRef();
  const [ssErr, ssConfig] = useSelfService();

  const categoryOptions = useMemo(() => {
    const hasCategoriesGuard = (
      conf: any,
    ): conf is {
      categories: Array<{
        description: string;
        icon: string;
        iconImg: string;
        id: string;
        name: string;
      }>;
    } =>
      /* istanbul ignore next */ conf?.categories &&
      Array.isArray(conf.categories);

    const unassignedItem = { label: 'Unassigned', value: null };
    /* istanbul ignore next */
    if (hasCategoriesGuard(ssConfig)) {
      return [
        unassignedItem,
        ...ssConfig.categories.map((category) => ({
          label: category.name,
          value: category.id,
        })),
      ];
    }

    return [unassignedItem];
  }, [ssConfig]);

  useEffect(() => {
    /* istanbul ignore next */
    if (ssErr) {
      toaster(i18n.error.generic());
      setDrawerStatus('closed');
    }
  }, [ssErr, setDrawerStatus]);

  const handleDeleteBookmark = (bookmark: BookmarkItem) => {
    const newBookmarks = settings.bookmarks.filter(
      (b) => b.url !== bookmark.url,
    );
    update('bookmarks', newBookmarks);
  };

  return (
    <div ref={bookmarksListRef}>
      <Setting.Card>
        <Setting.Header>
          <h3 className="b-h3">Bookmarks</h3>

          <Chip kind="active" text="Available in Self Service" />
        </Setting.Header>

        <Setting.SubHeader>
          <p className="b-txt">
            Add and manage bookmarks for Self Service. Bookmarks will appear in
            alphanumeric order.{' '}
            <a
              href="https://support.kandji.io/support/solutions/articles/72000594625-self-service-bookmarks"
              rel="noopener noreferrer"
              target="_blank"
              className="b-alink"
            >
              Learn more...
            </a>
          </p>
        </Setting.SubHeader>

        <Setting.Rows
          style={{
            paddingBottom: 24,
          }}
        >
          {isBookmarksTableVisible && (
            <Table dataKey="id" data={[...settings.bookmarks]} hideColHeaders>
              <Table.Col
                colName="title"
                component={({ children: title, rowData }) => {
                  const { iconDataUrl, isRecommended, url, icon_url } = rowData;
                  return (
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '40px 16px auto',
                        alignItems: 'center',
                        gap: '8px',
                      }}
                    >
                      <img
                        src={icon_url || iconDataUrl || defaultBookmarkIcon}
                        alt={`Bookmark icon - ${url}`}
                        height={40}
                        width={40}
                        style={{
                          border: '0.5px solid var(--b-primary-marengo-light)',
                          borderRadius: 10,
                          padding: 6,
                        }}
                      />

                      {isRecommended ? (
                        <img
                          className="k-ss2-preview-app-star"
                          src={starIcon}
                          alt="Recommended"
                        />
                      ) : (
                        <div className="k-ss2-preview-app-star" />
                      )}

                      <strong className="b-txt-bold">{title as string}</strong>
                    </div>
                  );
                }}
              />

              <Table.Col
                colName="url"
                component={({ children: url }) => (
                  <Tippy
                    content={
                      <Box
                        css={{
                          color: '$neutral100',
                          padding: '$2',
                          maxWidth: '400px',
                          overflow: 'hidden',
                          wordBreak: 'break-word',
                          fontSize: '$1',
                        }}
                      >
                        {url as string}
                      </Box>
                    }
                    theme="vuln"
                    interactive
                    appendTo={document.body}
                  >
                    <div className="k-manage-bookmarks-truncate">
                      {url as string}
                    </div>
                  </Tippy>
                )}
              />

              <Table.Col
                colName="category"
                component={({ children: category }) =>
                  categoryOptions.find((c) => c.value === category)
                    ?.label as unknown as React.ReactElement
                }
              />

              <Table.ActionsMenuCol
                zIndex={0}
                appendTo={
                  /* istanbul ignore next */ () => bookmarksListRef?.current
                }
                isDisabled={isDisabled}
                menuOptions={[
                  {
                    name: 'Edit',
                    icon: 'pencil',
                    onClick: (bookmark) => {
                      setSelectedBookmark(bookmark);
                      setDrawerStatus('open');
                    },
                  },
                  {
                    name: 'Delete',
                    icon: 'trash',
                    theme: 'error',
                    onClick: handleDeleteBookmark,
                  },
                ]}
              />
            </Table>
          )}

          <Setting.Row
            style={{
              boxShadow: isBookmarksTableVisible
                ? 'inset 0 1px 0 var(--b-primary-marengo-ultralight)'
                : 'none',
              paddingTop: isBookmarksTableVisible ? 8 : 0,
            }}
          >
            <Setting.Controls>
              <Button
                kind="link"
                icon="circle-plus"
                onClick={() => {
                  setSelectedBookmark(null);
                  setDrawerStatus('open');
                }}
                disabled={isDisabled}
              >
                Add Bookmark
              </Button>
            </Setting.Controls>
          </Setting.Row>
        </Setting.Rows>
      </Setting.Card>

      <Drawer
        drawerStatus={drawerStatus}
        settings={settings}
        update={update}
        selectedBookmark={selectedBookmark}
        categoryOptions={categoryOptions}
      />
    </div>
  );
};

export default memo(ManageBookmarks);
