import { Toaster as toaster } from '@kandji-inc/bumblebee';
import { links } from 'app/common/constants';
import React from 'react';
import handleDownload from '../../../adcs/modals/welcome/FooterButtons/utils/handleDownload';
import { advancedService } from '../../../adcs/services/advanced';
import { connectorService } from '../../../adcs/services/connector/connector-service';

import type { AdcsInputs, AdcsState } from '../../types/adcs.types';
// Types
import type { CallBackFunc, CallbackArgs } from '../../types/integrator.types';

import { i18n } from 'i18n';
import authIcon from '../../../adcs/assets/authenticated-icon.svg';
// Icons
import certIcon from '../../../adcs/assets/cert-icon.svg';
import multiDownloadIcon from '../../../adcs/assets/download-multi.svg';
import kandjiDownloadIcon from '../../../adcs/assets/kandji-download.svg';
import serverIcon from '../../../adcs/assets/server-icon.svg';

type AdcsCallBack = CallBackFunc<AdcsInputs, AdcsState>;

const handleDeleteIntegration: AdcsCallBack = async ({ history, setState }) => {
  setState({ footerStatus: 'pending' });

  return advancedService
    .deleteIntegration()
    .then(() => {
      toaster(
        i18n.t(
          'Your integration with Active Directory Certificate Services has been deleted.',
        ),
      );
    })
    .catch((err: Error) => {
      toaster(err.message);
    })
    .finally(() => {
      history.push(links.integrations.marketplace);
    });
};

const createIntegrationAndConnector = async () => {
  await advancedService.createADCSIntegration();
  await connectorService.createConnector();
};

export const handleCancel: AdcsCallBack = (args) => {
  const { state, history } = args;

  if (state.downloaded) {
    handleDeleteIntegration(args);
  } else {
    history.push(links.integrations.marketplace);
  }
};

const downloadAndCreate = async (
  arg: CallbackArgs<AdcsInputs, AdcsState>,
  skipCreation = true,
) => {
  const { setState } = arg;

  const getDownloadLink = async () =>
    await connectorService.getInstallerDownloadLink();

  const setDownloadProgress = (progress: number) => {
    setState({ downloadProgress: progress });
  };

  const download = await handleDownload(getDownloadLink, setDownloadProgress);

  if (download) {
    if (skipCreation) {
      createIntegrationAndConnector();
    }
    setState({ footerStatus: 'success', downloaded: true });
  } else {
    setState({ footerStatus: 'failed' });
  }

  return download;
};

export const handleNextDownload: AdcsCallBack = (args) => {
  const { state, nextPage } = args;

  if (state.footerStatus === 'success') {
    nextPage();
  } else {
    downloadAndCreate(args);
  }
};

export const handleNextTextDownload = ({ state }: { state: AdcsState }) =>
  state.footerStatus === 'success' ? 'Next' : 'Download connector';

export const handleNextClose: AdcsCallBack = ({ history }) => {
  history.push(`${links.integrations.root}/adcs`);
};

// Ticket
export const welcomeTickets = [
  {
    text: 'You will download our AD CS connector and then install it on a Windows server client. Supports Windows 12 and above.',
    title: 'Install the AD CS connector',
    link: 'https://support.kandji.io/support/solutions/articles/72000568715-active-directory-certificate-services-ad-cs-integration-ad-cs-connector-installation',
    icon: multiDownloadIcon,
  },
  {
    text: 'Create Certificate or Wi-Fi profile Library Items to issue certificates through your certificate authority for device security and compliance.',
    title: 'AD CS security and compliance',
    link: 'https://support.kandji.io/support/solutions/articles/72000569068-active-directory-certificate-services-ad-cs-integration-overview',
    icon: certIcon,
  },
];

export const downloadTickets = [
  {
    text: 'Supports: Windows Server 2016 and above',
    title: 'Kandji AD CS connector',
    link: 'https://support.kandji.io/support/solutions/articles/72000568715-active-directory-certificate-services-ad-cs-integration-ad-cs-connector-installation',
    icon: kandjiDownloadIcon,
  },
];

/* istanbul ignore next */
const InstallConnectorText = () => (
  <>
    Transfer the <span className="cmn-txt-code">Kandji ADCS.exe</span> file to
    your Windows Server machine and run the file.
  </>
);

export const сonnectionTickets = [
  {
    text: <InstallConnectorText />,
    title: 'Install the AD CS connector',
    icon: serverIcon,
  },
  {
    text: 'Once installed, open the AD CS Connector app to authenticate with Kandji and complete setup of the connection.',
    title: 'Authenticate with Kandji',
    link: 'https://support.kandji.io/support/solutions/articles/72000568715-active-directory-certificate-services-ad-cs-integration-ad-cs-connector-installation ',
    icon: authIcon,
  },
];

// Footer StatusOptions
export const downloadStatusOptions = {
  pending: {
    title: 'Pending',
    statusText: 'In progress',
  },
  success: {
    title: 'Kandji AD CS Connector was successfully downloaded.',
    statusText: 'Completed',
  },
  failed: {
    title: 'Download failed. Please try again.',
    statusText: 'Failed',
  },
};

// Overview Page Helper
export const overviewHandleClose: AdcsCallBack = ({
  history,
  state,
  setIsDialogOpen,
}) => {
  setIsDialogOpen(false);

  if (state.downloaded) {
    // need to find out more sophisticated approach to refresh the page if new connector downloaded
    history.push(links.integrations.root);
    setTimeout(() => {
      history.push(`${links.integrations.root}/adcs`);
    }, 0);
  }
};

export const overviewHandleRedownload: AdcsCallBack = async (args) => {
  const { nextPage } = args;

  await downloadAndCreate(args, false);
  nextPage();
};
